import React from 'react'
import { Dialog } from 'primereact/dialog';

const DialogBox = ({visible,width,state,Componenet,toast,team,header}) => {
  return (
    <>
       <Dialog 
         header={`${header}`} 
         visible={visible}
         style={{ width: `${width}` }}
         onHide={() => state(false)}
        >
            <Componenet toast={toast} leader={team.TeamLeader} team={team} />
        </Dialog>
    </>
  )
}

export default DialogBox