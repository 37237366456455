import React, { useEffect, useState } from "react";
// import Header from "./Header.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddTournament from "./AddTournament.js";
import EditTournament from "./EditTournament.js";
import TournamentLogo from "../Assets/TournamentLogo.png";
import givePermissions from "../permissions.js";
import { showError, showSuccess, showWarning } from "./toasts.js";
import TournamentInstructionModal from "./Instruction_Modals/TournamentInstructionModal.js";
import { Button } from 'primereact/button';
import * as Realm from "realm-web";
import { FileUpload } from 'primereact/fileupload';
import AgeGroupTab from "./AgeGroupTab.js";
const { REACT_APP_URL } = process.env;

const app = new Realm.App({ id: "application-0-llvit" })

const Tournaments = ({
  AgeTabsOpen,
  toast,
  Tournament_visible,
  setTournament_Visible,
  tournamentInstructionModalOpen,
  setTournamentInstructionModalOpen,
  ageGroupChanged,
  selectedTournament_,
  ageTournament,

}) => {
  const [selectedTournament, setSelectedTournament] = useState(
    JSON.parse(sessionStorage.getItem("tournament"))
  );
   
  const [Image, SetImage] = useState(null);
  const [permissions, setPermissions] = useState(false);
  const [editTournament, setEditTournament] = useState(false);
  const [tournamentEventOccured, setTournamentEventOccured] = useState(false);
  
  let navigate = useNavigate();
  

  const navigateToTeams = () => {
    if (selectedTournament.individual) {
      navigate("/showPlayers");
    } else if (!selectedTournament.individual) {
      navigate("/showTeams");
    }
  };

  const customImageUpload = async (e) => {
    
    console.log("clicked")
    const file= e.files[0];
    SetImage(file);
    if (Image) {
      try {
        const formData = new FormData();
        formData.append("image", Image);
        console.log(formData.get("image"));

        let response = await axios.post(
          `${REACT_APP_URL}/tournaments/logoUpload`,
          formData,
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );
        console.log(response.data);
        if(response.data.msg==="Image Uploded Successfully"){

         
          let data={
            id:selectedTournament._id,
            url:response.data.url
          }
           const res2= await axios.post( `${REACT_APP_URL}/tournaments/updateLogo`,
           data,
           {
             headers: {
               Authorization: sessionStorage.getItem("accessToken"),
             },
           }) 

           if(res2.data==='SuccessFull'){
              showSuccess(res2.data,toast);
           }
           else{
            showError(res2.data,toast);
           }
        }
        else{
          console.log(response.data,toast);
        }
      } catch (err) {
        console.log("Error in Uploading image:", err);
      }
    }
  };

  const GotoAgeTournamet=async()=>{
      const id= JSON.parse(sessionStorage.getItem('tournament')).ageGroups[0].Id;
      console.log(id);
      const ageTournament1= await axios.get( `${REACT_APP_URL}/tournaments/singelTournament/${id}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("accessToken"),
        },
      }) 
      if(ageTournament1){
          AgeTabsOpen();
          console.log(ageTournament1.data);
          setSelectedTournament(ageTournament1.data);
        // sessionStorage.setItem('tournament',JSON.parse(ageTournament1.data));
      }
  }

  

  const Tournament = () => {
    const [groups, setGroups] = useState([]);
    const [internalTournamentsLength, setInternalTournamentsLength] =
      useState(0);
    // const [selectedGroup, setSelectedGroup] = useState(null);

    const fetchAllGroups = async () => {
      let response = await axios.get(
        `${REACT_APP_URL}/all/allGroups/${selectedTournament?._id}`
      );
      if (selectedTournament?.individual) {
        response.data.forEach((group) => {
          if(!group?.singleElimination) {
            group.players?.forEach((player) => {
              if(player !== "bye") {
                  if (player?.MatchesPlayed === 0) {
                      player.winLossRatio = 0;
                    } else if (player.gamesLost === 0) {
                        player.winLossRatio = 0;
                      } else {
                          let winLossRatio = player?.gamesWon / player?.gamesLost;
                          player.winLossRatio = winLossRatio;
                        }
                      }
            });
        }
        });
      } else if (!selectedTournament?.individual) {
        response.data.forEach((group) => {
          if(!group?.singleElimination) {
          group.teams?.forEach((team, index) => {
            if(team !== "bye" && team !== null) {
              if (team?.MatchesPlayed === 0) {
                team.winLossRatio = 0;
              }
              else if(team?.gamesLost === 0) {
                team.winLossRatio = team?.gamesWon;
              }
               else {
                let winLossRatio = team?.gamesWon / team?.gamesLost;
                team.winLossRatio = winLossRatio.toFixed(4);
              }
              team.netPoints = team?.matchesWon - team?.matchesLost
            }
            else if(team === "bye") {
              // console.log(index)
              group.teams[index] = {
                TeamName: "bye",
                MatchesPlayed: 0,
                gamesWon: 0,
                gamesLost: 0,
                winLossRatio: 0
              };
            }
          });
          }
        });
      }
      setGroups(response.data);
    };

    const InternalTournamentExists = async () => {
      let response = await axios.get(
        `${REACT_APP_URL}/tournaments/getInternalTournaments/${selectedTournament?._id}`
      );
      setInternalTournamentsLength(response.data.length);
    };

    const scheduleRoundMatches = async () => {
      if (
        !selectedTournament?.format ||
        selectedTournament?.format === "Round Robin"
      ) {
        await axios.post(
          `${REACT_APP_URL}/algos/Rr`,
          {
            tournament_id: selectedTournament._id,
            type: selectedTournament.individual ? "player" : "team",
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );
        showSuccess("", toast);
      } else if (selectedTournament?.format === "Single Elimination") {
        let teams = [];
        if (selectedTournament.individual) {
          const teams_response = await axios.get(
            `${REACT_APP_URL}/players/getPlayerFromSingleEliminationGroup/${selectedTournament._id}`,
            {
              headers: {
                Authorization: sessionStorage.getItem("accessToken"),
              },
            }
          );

          teams = teams_response.data.slice();
        } else if (!selectedTournament.individual) {
          const teams_response = await axios.get(
            `${REACT_APP_URL}/teams/teamsForSingleElimination/${selectedTournament._id}`
          );

          teams = teams_response.data.slice();
        }

        const response = await axios.post(
          `${REACT_APP_URL}/singleElimination/roundOneMatchDetails`,
          {
            tournament_id: selectedTournament._id,
            individual: selectedTournament.individual,
            array_of_teams: teams,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        if (response.data.message === "Phase Initiated") {
          showSuccess("Matches Scheduled", toast);
        } else if (response.data.message === "Tournament Already Exists") {
          showWarning(response.data.message, toast);
        } else {
          showError(response.data.message, toast);
        }
      }
    };

    const Standings = () => {
      const [reorderedGroup, setReorderedGroup] = useState(null);

      const headerStyle = {
        fontFamily: "'Inter', sans-serif",
        backgroundColor: "#FF8734",
        color: "#fff",
        borderTop: "1px solid black",
        borderBottom: "1px solid black"
      };
      const bodyStyle = { fontFamily: "'Inter', sans-serif", color: "#black", borderBottom: "1px solid black" };

      const [arrayOfPlayersOrTeams, setArrayOfPlayersOrTeams] = useState([]);

      const addToArrayOfPlayersOrTeams = (e) => {
        setArrayOfPlayersOrTeams((previousData) => {
          return [...previousData, e.value.rowData];
        });
      };

      const startKnockoutPhase = async () => {
        // const array = arrayOfPlayersOrTeams.sort((team1, team2) => {
        //   return team2.winLossRatio - team1.winLossRatio;
        // })
        const array = arrayOfPlayersOrTeams.slice();

        const response = await axios.post(
          `${REACT_APP_URL}/singleElimination/roundOneMatchDetails`,
          {
            tournament_id: selectedTournament?._id,
            individual: selectedTournament?.individual,
            array_of_teams: array,
            reArrange: true
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        if (response.data.message === "Phase Initiated") {
          showSuccess(response.data.message, toast);
          setArrayOfPlayersOrTeams([]);
        } else if (response.data.message === "Tournament Already Exists") {
          showWarning(response.data.message, toast);
        } else {
          showError(response.data.message, toast);
        }

      };

      const restartKnockoutPhase = async(teams, group_number) => {
        const response = await axios.post(
          `${REACT_APP_URL}/singleElimination/roundOneMatchDetails`,
          {
            tournament_id: selectedTournament?._id,
            individual: selectedTournament?.individual,
            group_number: group_number,
            array_of_teams: teams,
            reArrange: false,
            restartPhase: true,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        if (response.data.message === "Phase Initiated") {
          showSuccess("Phase Reinitiated", toast);
        } else if (response.data.message === "Tournament Already Exists") {
          showWarning(response.data.message, toast);
        } else {
          showError(response.data.message, toast);
        }
      }

      const reorderGroup = async (e, group_number) => {
        await axios.post(`${REACT_APP_URL}/teams/reorderGroup`, {
          tournament_id: selectedTournament?._id,
          group_number: group_number,
          teams: e.value
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })
      }

      return (
        <>
          {!selectedTournament.knockouts && (
            <div className="flex justify-content-center align-items-center mt-6">
              <button
                className="btn-knockout border-round-sm ml-6"
                onClick={startKnockoutPhase}
                >
                Start Knockout Phase
              </button>
              </div>
          )}
          {selectedTournament.knockouts && (
            <div className="flex justify-content-center align-items-center mt-6">
              <button
                className="btn-knockout border-round-sm ml-6"
                onClick={() => restartKnockoutPhase(groups[groups.length - 1].teams, groups[groups.length - 1].group_number)}
                >
                Restart Knockout Phase
              </button>
            </div>
          )}
          {selectedTournament.individual && (
            <div className="standings-grid px-4 py-1 mx-5 mb-8">
              {groups
                ?.sort((group1, group2) => {
                  return (
                    parseInt(
                      group1.group_number[group1.group_number.length - 1]
                    ) -
                    parseInt(
                      group2.group_number[group2.group_number.length - 1]
                    )
                  );
                })
                .map((group) => {
                  return (
                    <div key={group.group_number}>
                      <p className="groups-header text-white p-2">
                        {group.group_number}
                      </p>
                      {!group?.singleElimination && (
                      <DataTable
                        className="groups-table border-rounded-md"
                        value={group.players}
                        // sortField="winLossRatio"
                        sortOrder={-1}
                        cellSelection
                        selectionMode="single"
                        selection={arrayOfPlayersOrTeams}
                        onSelectionChange={addToArrayOfPlayersOrTeams}
                        reorderableRows
                        onRowReorder={(e) => reorderGroup(e, group.group_number)}
                      >
                        <Column rowReorder 
                        headerStyle={{ borderTopLeftRadius: "10px",borderLeft: "1px solid black", borderBottom: "1px solid black", backgroundColor: "#FF8734", borderTop: "1px solid black" }}
                          bodyStyle={{...bodyStyle, borderLeft: "1px solid black"}}
                           />
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field={"name" || "TeamName"}
                          header="Player"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="MatchesPlayed"
                          header="Played"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOfwins"
                          header="TW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOflosses"
                          header="TL"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesWon"
                          header="GW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesLost"
                          header="GL"
                        ></Column>
                        <Column
                          className="hidden md:block"
                          headerStyle={{...headerStyle, borderRight: "1px solid black"}}
                          bodyStyle={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}
                          field="winLossRatio"
                          header="Win/Loss"
                          ></Column>
                      </DataTable>
                      )}
                      {group?.singleElimination && (
                        <DataTable
                        className="groups-table border-rounded-md"
                        value={group.players}
                        cellSelection
                        selectionMode="single"
                        selection={arrayOfPlayersOrTeams}
                        onSelectionChange={addToArrayOfPlayersOrTeams}
                        reorderableRows
                        onRowReorder={(e) => reorderGroup(e, group.group_number)}
                      >
                        <Column rowReorder 
                        headerStyle={{ borderTopLeftRadius: "10px",borderLeft: "1px solid black", borderBottom: "1px solid black", backgroundColor: "#FF8734", borderTop: "1px solid black" }}
                          bodyStyle={{...bodyStyle, borderLeft: "1px solid black"}}
                           />
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field={"name" || "TeamName"}
                          header="Player"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="MatchesPlayed"
                          header="Played"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOfwins"
                          header="TW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOflosses"
                          header="TL"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesWon"
                          header="GW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesLost"
                          header="GL"
                        ></Column>
                      </DataTable>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
          {!selectedTournament.individual && (
            <div className="standings-grid w-full px-4 py-1 mx-5 mb-8">
              {groups
                ?.sort((group1, group2) => {
                  return (
                    parseInt(
                      group1.group_number[group1.group_number.length - 1]
                    ) -
                    parseInt(
                      group2.group_number[group2.group_number.length - 1]
                    )
                  );
                })
                .map((group) => {
                  return (
                    <div key={group.group_number}>
                      <p className="groups-header text-white p-2">
                        {group.group_number}
                      </p>
                      {!group?.singleElimination && (
                      <DataTable
                        className="groups-table"
                        value={group.teams}
                        sortField="winLossRatio"
                        sortOrder={-1}
                        cellSelection
                        selectionMode="single"
                        selection={arrayOfPlayersOrTeams}
                        onSelectionChange={addToArrayOfPlayersOrTeams}
                        reorderableRows
                        onRowReorder={(e) => reorderGroup(e, group.group_number)}
                      >
                        <Column rowReorder 
                        headerStyle={{ borderTopLeftRadius: "10px",borderLeft: "1px solid black", borderBottom: "1px solid black", backgroundColor: "#FF8734", borderTop: "1px solid black" }}
                          bodyStyle={{...bodyStyle, borderLeft: "1px solid black"}}
                           />
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="TeamName"
                          header="Team"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="MatchesPlayed"
                          header="Played"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOfwins"
                          header="TW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOflosses"
                          header="TL"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesWon"
                          header="GW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesLost"
                          header="GL"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="matchesWon"
                          header="MW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="matchesLost"
                          header="ML"
                        ></Column>
                        <Column
                          className="hidden md:block"
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="winLossRatio"
                          header="Win/Loss"
                          ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="winPoints"
                          header="Points"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="netPoints"
                          header="Net Points"
                        ></Column>
                      </DataTable>
                      )}
                      {group?.singleElimination && (
                        <DataTable
                        className="groups-table"
                        value={group.teams}
                        cellSelection
                        selectionMode="single"
                        selection={arrayOfPlayersOrTeams}
                        onSelectionChange={addToArrayOfPlayersOrTeams}
                        reorderableRows
                        onRowReorder={(e) => reorderGroup(e, group.group_number)}
                      >
                        <Column rowReorder 
                        headerStyle={{ borderTopLeftRadius: "10px",borderLeft: "1px solid black", borderBottom: "1px solid black", backgroundColor: "#FF8734", borderTop: "1px solid black" }}
                          bodyStyle={{...bodyStyle, borderLeft: "1px solid black"}}
                           />
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="TeamName"
                          header="Team"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="MatchesPlayed"
                          header="Played"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOfwins"
                          header="TW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="noOflosses"
                          header="TL"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesWon"
                          header="GW"
                        ></Column>
                        <Column
                          headerStyle={headerStyle}
                          bodyStyle={bodyStyle}
                          field="gamesLost"
                          header="GL"
                        ></Column>
                          </DataTable>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </>
      );
    };

    useEffect(() => {
      fetchAllGroups();
      InternalTournamentExists();
    }, []);
    return (
      <>
        <div className="card flex justify-content-center"></div>
        {internalTournamentsLength > 0 ? (
          <div
            style={{
              fontFamily: "'Inter', sans-serif",
              color: "#757575"
            }}
          >
            <Standings />
          </div>
        ) : (
          <div
            className="flex justify-content-center align-items-center mt-6"
          >
            {permissions && (
            <button className="btn-tournament hidden md:block" onClick={scheduleRoundMatches}>
              Schedule Matches
            </button>
            )}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const tournamentLogin = async () => {
      await app.logIn(Realm.Credentials.anonymous());
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const tournamentCollection = mongodb.db("tournament_manager_database").collection("Tournaments");

      for await (const change of tournamentCollection.watch()) {
        setTournamentEventOccured(!tournamentEventOccured);
      }
    }

    tournamentLogin();

  },[tournamentEventOccured])

  useEffect(() => {
    givePermissions(setPermissions);
  }, [sessionStorage.getItem("Auth")]);

  useEffect(() => {
    setSelectedTournament(JSON.parse(sessionStorage.getItem("tournament")));
  },[sessionStorage.getItem("tournament"), ageGroupChanged])

  return (
    <>
      <div className="container overflow-auto">
        <div className="tournament" style={{ marginTop: "4rem" }}>
          {selectedTournament === null ? (
            <div style={{ color: "#757575" }} className="h-screen relative">
              <div
                style={{ marginTop: "25rem" }}
                className="flex justify-content-center align-items-center"
              >
                <p className="text-white hidden md:block">
                  Please Select A Tournament OR Create a new one
                </p>
              </div>
            </div>
          ) : (
            <>
              <div
                className="hidden lg:flex flex-column lg:justify-content-center lg:align-items-center"
                style={{ marginTop: "6rem" }}
              >
                <div
                  className="tournament-details-card flex justify-content-center align-items-center gap-8 p-6 text-700 border-bottom-1 border-600 relative mx-5"
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  {selectedTournament?.logo !== "" && selectedTournament?.logo !== null  && (
                    <>
                      <img
                        className="w-2"
                        src={selectedTournament?.logo}
                        alt="Tournament Logo"
                      />
                      {permissions && (
                        <div className="flex flex-column gap-4">
                           <FileUpload
                          style={{ fontFamily: "'Inter', sans-serif" }}
                          mode="basic"
                          name="demo[]"
                          url="/api/upload"
                          accept="image/*"
                          customUpload
                          uploadHandler={customImageUpload}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {(selectedTournament?.logo === "" || selectedTournament?.logo === null) && (
                    
                    <div className="flex flex-column gap-5 justify-content-center align-items-center">
                      <img
                        className="w-4"
                        src={TournamentLogo}
                        alt="Tournament Logo"

                      />
                      {permissions && (
                        <div className="flex flex-column gap-4">
                          <FileUpload
                          style={{ fontFamily: "'Inter', sans-serif" }}
                          mode="basic"
                          name="demo[]"
                          url="/api/upload"
                          accept="image/*"
                          customUpload
                          uploadHandler={customImageUpload}
                          />
                        </div>
                      )}
                  
                    </div>
                  )}
                  <div className="flex flex-column">
                    <p className="text-md text-white font-normal">
                      {selectedTournament?.name}
                    </p>
                    <p className="text-md text-white font-normal">
                      {selectedTournament?.format}
                    </p>
                  </div>
                  <div className="flex flex-column">
                    <p className="text-md text-white font-normal">
                      {selectedTournament?.individual === true
                        ? "Individual Event"
                        : "Team Event"}
                    </p>
                    <p className="text-md text-white font-normal">
                      {selectedTournament?.venue}
                    </p>
                  </div>
                  {selectedTournament?.winner && (
                    <div className="flex flex-column">
                      <p className="text-md text-white font-normal">
                        Winner -{" "}
                        {selectedTournament?.winner?.name ||
                          selectedTournament?.winner?.TeamName}
                      </p>
                      <p className="text-md text-white font-normal">
                        Tournament Has Ended
                      </p>
                    </div>
                  )}
                  <div className="flex flex-column gap-2">
                     <Button style={{ fontFamily: "'Inter', sans-serif" }} label="Edit" onClick={() => setEditTournament(true)} />
                     {
                      selectedTournament?.ageGroups &&
                       <Button style={{ fontFamily: "'Inter', sans-serif" }} label="Age Groups" onClick={GotoAgeTournamet} />
                     }
                  </div>
                </div>
              </div>
              <div className="flex flex-column justify-content-center align-items-center gap-4 border-bottom-1 border-300 mx-4 lg:hidden">
                {selectedTournament?.logo !== "" && (
                  <>
                    <img
                      className="w-2"
                      src={TournamentLogo}
                      alt="Tournament Logo"
                    />
                  </>
                )}
                {selectedTournament?.logo === "" && (
                  <>
                    <img
                      className="w-2"
                      src={TournamentLogo}
                      alt="Tournament Logo"
                    />
                  </>
                )}
                <div className="flex justify-align-start gap-6">
                  <p className="text-md text-white font-normal">
                    {selectedTournament?.name}
                  </p>
                  <p className="text-md text-white font-normal">
                    {selectedTournament?.type}
                  </p>
                </div>
                <div className="flex justify-align-start gap-6">
                  <p className="text-md text-white font-normal">
                    {selectedTournament?.individual === true
                      ? "Individual Event"
                      : "Team Event"}
                  </p>
                  <p className="text-md text-white font-normal">
                    {selectedTournament?.venue}
                  </p>
                </div>
                {selectedTournament?.winner && (
                  <div className="flex justify-align-start gap-6">
                    <p className="text-md text-white font-normal">
                      Winner -{" "}
                      {selectedTournament?.winner?.name ||
                        selectedTournament?.winner?.TeamName}
                    </p>
                    <p className="text-md text-white font-normal">
                      Tournament Has Ended
                    </p>
                  </div>
                )}
              </div>
             
              {selectedTournament?.groups?.length === 0 ? (
                <div className=" flex flex-row justify-content-center pt-5">
                  {permissions && (
                      <button
                      className="btn-tournament"
                      onClick={navigateToTeams}
                    >
                      {selectedTournament.individual
                        ? "Select Players to Proceed"
                        : "Select Teams To Proceed"}
                    </button>
                  )}
                </div>
              ) : (
                <div className="flex flex-column justify-content-center align-items-center">
                  <Tournament />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        style={{ width: "80vw" }}
        visible={Tournament_visible}
        onHide={() => {
          setTournament_Visible(false);
        }}
      >
        <AddTournament/>
      </Dialog>

      <Dialog
        style={{ width: "80vw" }}
        visible={editTournament}
        onHide={() => {
          setEditTournament(false);
        }}
      >
       <EditTournament data={selectedTournament}/>
      </Dialog>
      
      <Dialog
        style={{ width: "50vw" }}
        visible={tournamentInstructionModalOpen}
        onHide={() => setTournamentInstructionModalOpen(false)}
      >
        <TournamentInstructionModal
          setTournamentInstructionModalOpen={setTournamentInstructionModalOpen}
          setTournament_Visible={setTournament_Visible}
        />
      </Dialog>
    </>
  );
};

export default Tournaments;
