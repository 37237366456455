import React, { useEffect, useState } from 'react';
import axios from "axios";
import * as Realm from "realm-web";
import { showError, showSuccess } from './toasts';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Check, Edit } from 'lucide-react';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import AddPlayersToTeam from './AddPlayersToTeam';
import { Avatar } from '@mui/material';
const { REACT_APP_URL } = process.env;

const app = new Realm.App({ id: "application-0-llvit" })

const Team = ({ team_, setTeam_, teams, selectedTournament, groupNumber, toast, permissions, customBase64Uploader, TeamLogo }) => {
    const [team, setTeam] = useState(team_);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [players, setPlayers] = useState([]);
    const [selectedCell, setSelectedCell] = useState(null);
    const [event, setEvent] = useState(false);
    const [visible, setVisible] = useState(false);

    const [teamName, setTeamName] = useState(team_.TeamName);
    const [club, setClub] = useState(team_.club || "No Club");

    const getUnselectedPlayers = async () => {
      const response = await axios.get(
        `${REACT_APP_URL}/players/getUnselectedPlayers/${selectedTournament._id}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );
      let array = [];
      let array_ = [];
      teams.forEach((team) => {
        team.Members.forEach((player) => {
          array.push(player.name);
        })
      })
      response.data.forEach((player) => {
        if(!array.includes(player.name)) {
          array_.push(player);
        }
      })
      setPlayers(array_);
    };

    const getTeam = async () => {
      const response = await axios.get(`${REACT_APP_URL}/teams/teamForApp/${team_?.TeamName}/${selectedTournament?._id}`)
      setTeam(response.data);
    }

    const assignCaptainOrRemovePlayer = async (e) => {
      let cellIndex = e.value.cellIndex;
      let rowData = e.value.rowData;

      if(cellIndex === 1) {
        const response = await axios.post(`${REACT_APP_URL}/teams/assignTeamCaptain`, {
          team_id: team._id,
          player_name: rowData.name,
          player_id: rowData.id,
          tournament_id: selectedTournament?._id
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })

        if(response.data.message === "Leader assigned") {
          showSuccess(response.data.message, toast);
        }
        else {
          showError(response.data.message, toast);
        }
      }
      else if(cellIndex === 2) {
        const response = await axios.post(`${REACT_APP_URL}/teams/removePlayerFromTeam`, {
          team_id: team._id,
          player_name: rowData.name,
          player_id: rowData._id,
          tournament_id: selectedTournament?._id
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })

        if(response.data.message === "Player removed") {
          showSuccess(response.data.message, toast);
        }
        else {
          showError(response.data.message, toast);
        }
      }
    }

    const editTeam = async () => {
      const response = await axios.post(`${REACT_APP_URL}/teams/editTeam`, {
        teamName: teamName,
        club: club === "No Club" ? "" : club,
        tournament_id: selectedTournament?._id,
        team_id: team._id
      },{
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(response.data.message !== "Team Edited") {
        showError(response.data.message, toast);
      }
    }

    const handleLogoChange = async (e) => {
      const data = new FormData();
      data.append("logo", e.target.files[0]);
      const response = await axios.post(`${REACT_APP_URL}/teams/uploadTeamLogoAndGetUrl`, data, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })
      
      const url = response.data;
      const responseTwo = await axios.post(`${REACT_APP_URL}/teams/uploadTeamLogo`, {
        team_id: team_?._id,
        team_logo: url
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(responseTwo.data.message === "Logo uploaded") {
        showSuccess(responseTwo.data.message, toast);
      }
      else {
        showError(responseTwo.data.message, toast);
      }
    }

    useEffect(() => {
        const teamLogin = async () => {
            await app.logIn(Realm.Credentials.anonymous());
            const mongodb = app.currentUser.mongoClient("mongodb-atlas");
            const teamCollection = mongodb.db("tournament_manager_database").collection("Groups");

            for await (const change of teamCollection.watch()) {
                let group = change.fullDocument;
                group.teams.forEach((team1) => {
                  if(team1.name === team.name) {
                    setTeam(team1)
                    setTeam_(team1)
                    setEvent(!event)
                  }
                })
            }
        }

        teamLogin();
    },[event])

    useEffect(() => {
      getUnselectedPlayers();
      getTeam();
      // console.log(team);
    }, [event]);

    return (
      <>
        <div
          style={{ fontFamily: "'Inter', sans-serif" }}
          className="flex justify-content-start align-items-center gap-6 relative"
        >
          {permissions && (
            <>
              <div className="absolute top-0 right-0 flex justify-content-center align-items-center gap-3">
                <button
                  style={{ backgroundColor: "white" }}
                  className="btn-header"
                  onClick={() => setVisible(true)}
                >
                  Add Player
                </button>
              </div>
              <div className="absolute bottom-0 right-0">
                <button
                  style={{ backgroundColor: "white" }}
                  className="btn-header"
                >
                  Delete
                </button>
              </div>
            </>
          )}
            {team.logo === "" && permissions && (
          <div style={{ width: "30%" }} className="border-1 border-dashed border-gray-600 border-round-md p-3">
                <input
                  type="file"
                  multiple={false}
                  style={{ fontFamily: "'Inter', sans-serif" }}
                  className="outline-none border-none"
                  onChange={handleLogoChange}
                />
          </div>
            )}
          {team.logo === "" && !permissions && (
            <img className="w-2" src={TeamLogo} alt="Team Logo" />
          )}
          {team.logo !== "" && (
            <Avatar src={team?.logo} />
          )}
          <div className="flex flex-column">
            <div className="flex justify-content-start align-items-center gap-1">
            <input style={{ fontFamily: "'Inter', sans-serif", fontSize: "1rem" }} className="border-none" value={teamName} onChange={(e) => setTeamName(e.target.value)} disabled={!permissions} />
            {permissions && (
              <Edit className="cursor-pointer" color="gray" size={18} onClick={editTeam} />
            )}
            </div>
            <div>
              <hr />
            </div>
            <div className="flex flex-column">
              <input style={{ fontFamily: "'Inter', sans-serif", fontSize: "1rem" }} className="border-none mt-2 mb-2" value={club} onChange={(e) => setClub(e.target.value)} disabled={!permissions} />
              <p>Tournament - {selectedTournament?.name}</p>
              <p>Leader - {team?.TeamLeader?.name || "Not decided yet"}</p>
              <div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex flex-column gap-2">
          <p className="text-lg font-semibold">Members</p>
          <DataTable value={team.Members} cellSelection selectionMode="single" selection={selectedCell} onSelectionChange={assignCaptainOrRemovePlayer}>
            <Column
              headerStyle={{
                fontFamily: "'Inter', sans-serif",
                backgroundColor: "#FF8734",
                color: "#fff",
              }}
              bodyStyle={{
                fontFamily: "'Inter', sans-serif",
              }}
              field="name"
              header="Name"
            ></Column>
            <Column
              headerStyle={{
                fontFamily: "'Inter', sans-serif",
                backgroundColor: "#FF8734",
                color: "#fff",
              }}
              bodyStyle={{
                fontFamily: "'Inter', sans-serif",
              }}
              header="Assign Leader"
              body={<Check className="cursor-pointer" />}
            ></Column>
            <Column
              headerStyle={{
                fontFamily: "'Inter', sans-serif",
                backgroundColor: "#FF8734",
                color: "#fff",
              }}
              bodyStyle={{
                fontFamily: "'Inter', sans-serif",
              }}
              header="Remove Player"
              body={<Check className="cursor-pointer" />}
            ></Column>
          </DataTable>
        </div>
        <Dialog style={{ width: "50vw" }} visible={visible} onHide={() => setVisible(false)}>
          <AddPlayersToTeam tournament={selectedTournament} team={team_} />
        </Dialog>
      </>
    );
}

export default Team
