import React from 'react'
import trofy from '../Assets/Login/trofy.png'
import {useState,useRef} from 'react'
import { Link, Navigate } from 'react-router-dom';
import '../App.css'
import { useNavigate } from 'react-router-dom'
import axios from'axios';
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { showError,showWarning } from './toasts';
import { Calendar } from 'primereact/calendar';
const { REACT_APP_URL } = process.env;

const Register = ({toast}) => {

  const navigate = useNavigate();
  const[email, setEmail] = useState('');
  const[name, setName] = useState('');
  const[password, setPassword] = useState('');
  const[confirmp, setConfirmp] = useState('');
  const[age, setAge] = useState('');
  const[contact, setContact] = useState('');
  const [gender, setGender] = useState('');
  const options = ['Player', 'Organiser'];
  const [profile,setProfile]=useState('');
  const [role, setRole] = useState(options[0]);
  const [date, setDate] = useState(null);
  const [error,setError]=useState({emailErr:false,contactErr:false,passwordErr:false});

  const customBase64Uploader = async (event) => {
 
    const file = event.files[0];
    const formData=new FormData();
    formData.append('image',file);

    const res= await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
      headers: {
          Authorization: sessionStorage.getItem("accessToken")
      }
     });
      
     console.log(res.data);
     if(res.data.msg==="Image Uploded Successfilly"){
      setProfile(res.data.url);
     }
     else{
      showWarning(res.data,toast)
     }

};

  const header = <div className="font-bold mb-3">Pick a password</div>;
  const footer = (
    <>
        <Divider />
        <p className="mt-2">Suggestions</p>
        <ul className="pl-2 ml-2 mt-0 line-height-3">
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 8 characters</li>
        </ul>
    </>
  );

 


  const register=async()=>
  {
    if(name!=='' && contact!=='' && gender!=='' && email!=='' && date !=='' && password!==''){
      if(email.toLocaleLowerCase()!==email && !email.includes('@')){
         setError({emailErr:true});
      }
      else if(contact.length!==10){
        setError({contactErr:true})
      }
      else if(password!==confirmp){
        setError({passwordErr:true})
      }
      else{
       
       
          try{
              let url=`${REACT_APP_URL}/auth`
              const reply=await axios.post(url+`/${role==='Organiser'?`registerO`:`registerP`}`,
              {
                name:name,
                phone:contact,
                dateOfBirth: date,
                email:email,
                image:profile,
                gender:gender,
                password:password}
                );
              
               if(reply.data==='Registration done successfully!')
               {
                 navigate('/')
               }
               else{
                 showWarning(reply.data,toast)
               }
          }catch(err){
            showError('Insertion Error',toast)
          }
        
      }
    }
    else{
      showWarning('Form is incomlete!',toast)
    }
   
     
  }
  return (
    <>
    <div className="container">
      <div className='flex flex-column gap-2 align-items-center'>
        <div className='login-title'>
        <h4 className='text-primary text-2xl font-normal'>Matchpointandgames.com</h4>
        </div>
          <div className='px-6 py-2 flex flex-column gap-5'>
            <div className='flex justify-content-center'><h4 className="text-primary text-xl font-light zoomin animation-duration-1000">Register Here</h4></div>
            <div className='flex gap-7'>  
                <div className='flex flex-column gap-6'>
                        <div className='flex flex-column gap-2  zoomin animation-duration-1000'>
                            <InputText style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className='w-15rem border-none border-bottom-1 border-alpha-40 border-noround' />
                        </div>

                        <div className='flex flex-column gap-2 zoomin animation-duration-1000'>
                            <Password style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} header={header} footer={footer} className='w-15rem'/>
                            {error.passwordErr && <p style={{color:'red'}}>Password and confirm password do not match</p>}
                        </div>

                        <div className='flex flex-column gap-2 '>
                            <InputText style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className='w-15rem border-none border-bottom-1 border-alpha-40 border-noround' />
                            {error.emailErr && <p style={{color:'red'}}>Invalid Email</p>}
                        </div>
              
                  
                        <div className='flex flex-column gap-2 zoomin animation-duration-1000'>
                            <label className="text-white">Upload Image</label>
                            <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="image/*" customUpload uploadHandler={customBase64Uploader} />
                        </div>

                        <div className="flex flex-wrap gap-3 flex-column zoomin animation-duration-1000">
                          <label className="text-white">Gender</label>
                            <div className='flex gap-2'>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="gender1" name="gender" value="male" onChange={(e) => setGender(e.value)} checked={gender === "male"} />
                                    <label htmlFor="gender1" className="ml-2 text-white">Male</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="gender2" name="gender" value="female" onChange={(e) => setGender(e.value)} checked={gender === "female"} />
                                    <label htmlFor="gender2" className="ml-2 text-white">Female</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="gender3" name="gender" value="other" onChange={(e) => setGender(e.value)} checked={gender === "other"} />
                                    <label htmlFor="gender3" className="ml-2 text-white">Other</label>
                                </div>
                            </div>
          
                        </div>
                </div>
                <div className='flex flex-column gap-6'>

                        <div className='flex flex-column gap-2 zoomin animation-duration-1000'>
                            <Calendar placeholder="Date Of Birth" value={date} onChange={(e) => setDate(e.value)} />
                        </div>
                    
                        <div className='flex flex-column gap-2 zoomin animation-duration-1000'>
                            <Password style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Confirm Password" value={confirmp} onChange={(e) => setConfirmp(e.target.value)} feedback={false} />
                        </div>

                        <div className='flex flex-column gap-2 '>
                            <InputText style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Phone No" value={contact} onChange={(e) => setContact(e.target.value)} className='w-15rem border-none border-bottom-1 border-alpha-40 border-noround' />
                            {error.contactErr && <p style={{color:'red'}}>Invalid Contact no.</p>}
                        </div>

                        <div className='flex flex-column gap-2 zoomin animation-duration-1000'>
                            <label className="text-white">Selct Role</label>
                            <SelectButton value={role} onChange={(e) => setRole(e.value)} options={options} />
                        </div>
            <div className='flex justify-content-center my-4 zoomin animation-duration-1000'><Button label='Register' onClick={register}/></div>
          </div>
                </div>
            </div>
      </div>
      </div>
    </>
  )
}

export default Register