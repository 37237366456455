import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useState } from 'react';
import { showError, showSuccess } from './toasts';
const { REACT_APP_URL } = process.env;

const AddRatings = ({ tournament_id, toast }) => {
    const [ratingsArray, setRatingsArray] = useState([""]);

    const addSport = () => {
        let array = ratingsArray.slice();
        array.push("");
        setRatingsArray(array);
    }

    const addSports = async () => {
        const response = await axios.post(`${REACT_APP_URL}/players/addSportsInPlayers`, {
            tournament_id: tournament_id,
            sports: ratingsArray
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })

        if(response.data.message === "Sports Added") {
            showSuccess(response.data.message, toast);
        }
        else {
            showError(response.data.message, toast);
        }
    }

    return (
        <div className="flex flex-column justify-content-center gap-5 w-full">
            <Toast />
            {ratingsArray.map((rating, index) => {
                return (
                    <div key={index} className="flex justify-content-between align-items-center">
                        <InputText placeholder="Name" value={rating} onChange={(e) => {
                            let array = ratingsArray.slice();
                            array[index] = e.target.value;
                            setRatingsArray(array);
                        }} />
                        <button className="btn-knockout border-round-sm" onClick={addSport}>Add Sport</button>
                    </div>
                )
            })}
            <button className="btn-knockout border-round-sm" onClick={addSports}>Add Sports</button>
        </div>
    )
}

export default AddRatings
