import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AutoCompleteComponent from '../AutoCompleteComponent';
import { Button } from 'primereact/button';
import { showSuccess, showError } from '../toasts';
const { REACT_APP_URL } = process.env;

const AddPlayerToTeamWithoutMessaging = ({ leader, team,toast }) => {
    const [tournaments, setTournaments] = useState([]);
    const [players, setPlayers] = useState([]);
    const [tournament, setTournament] = useState(null);
    const [player, setPlayer] = useState(null);
    const [loadingOne, setLoadingOne] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingThree, setLoadingThree] = useState(false);

    const handleTournamentChange = async (e) => {
        setLoadingTwo(true)
        setTournament(e.value);
        const response = await axios.get(`${REACT_APP_URL}/players/playersFromTournaments/${e.value._id}`, {
            headers: {
                Authorization : sessionStorage.getItem("accessToken")
            }
        })
        
        let array = [];
        response.data.forEach((player) => {
            if(player.name !== leader.name) {
                array.push(player);
            }
        })

        setPlayers(array);
        setLoadingTwo(false);
    }

    const handlePlayerChange = (e) => {
        setPlayer(e.value);
    }

    const getTournaments = async () => {
        setLoadingOne(true);
        const response = await axios.get(`${REACT_APP_URL}/tournaments/allTournaments`, {
            headers: {
                Authorization : sessionStorage.getItem("accessToken")
            }
        })
        let array = [];
        response.data.tournaments.forEach((tournament) => {
            if(!tournament.individual) {
                array.push(tournament);
            }
        })
        setTournaments(array);
        setLoadingOne(false);
    }

    const handleAddPlayerToTeam = async () => {
        setLoadingThree(true);
        const response = await axios.post(`${REACT_APP_URL}/teams/addPlayerToTeam`, {
            player_name: player.name,
            player_id: player.id,
            team_name: team.TeamName,
            tournament_id: tournament._id
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })

        if(response.data.message === "Player Added") {
            showSuccess(response.data.message, toast);
        }
        else {
            showError(response.data.message, toast);
        }
        setLoadingThree(false);
    }

    useEffect(() => {
        getTournaments();
    },[])

    return (
    <>
        <div className="card flex justify-content-center">
            
        </div>
        <div className="flex flex-column justify-content-center align-items-center gap-4">
            {loadingOne && (
                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
            )}
            {!loadingOne && (
                <AutoCompleteComponent suggestions={tournaments} value={tournament} handleOnChange={handleTournamentChange} field="name" placeholder="Search for a tournament..." width="100%" />
            )}
            
            {loadingTwo && (
                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
            )}
            {!loadingTwo && (
                <AutoCompleteComponent suggestions={players} value={player} handleOnChange={handlePlayerChange} field="name" placeholder="Search for a player..." width="100%" />
            )}
            {loadingThree && (
                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
            )}
            {!loadingThree && (
                <Button label="Add Player" onClick={handleAddPlayerToTeam} style={{ width: "100%" }} />
            )}
        </div>
    </>
    )
}

export default AddPlayerToTeamWithoutMessaging
