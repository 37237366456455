import React, { useState, useRef } from 'react';
import { ConfirmDialog} from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios';
const { REACT_APP_URL } = process.env;

export default function ConfirmButton({requestId,teamId,playerName,playerId,leaderName,teamName}){
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
   
    const accept = async() => {
        try{
            const payload={
                request_id:requestId,
                team_id:teamId,
                player_name:playerName,
                player_id:playerId
            };
        
            const response= await axios.post(`${REACT_APP_URL}/requests/acceptRequest`,payload);
        
            if(response.data==="success"){
                toast.current.show({ severity: 'info', summary: 'Confirmed', detail: `You have accepted the request from ${leaderName} to join team ${teamName}` , life: 3000 });
            }
            else if(response.data==="database error"){
              
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Backend error', life: 3000 });
            }

        }
        catch(err){
            console.log(err);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'frontend error', life: 3000 });
        }

      
    }

    const reject = async() => {
        try{
            const response=await axios.post(`${REACT_APP_URL}/requests/rejectRequest`,{requestId:requestId});
            console.log(response.data)
            if(response.data==="success"){
                toast.current.show({ severity: 'warn', summary: 'Rejected', detail: `You have rejected the request from ${leaderName} to join ${teamName}`,life: 3000 });
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Backend error', life: 3000 });
            }
        }catch(err){
            console.log(err);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'frontend error', life: 3000 });
        }
      
    }

 
        
    return (
        <>
       
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
            <div className="card flex justify-content-center">
                <Button onClick={() =>setVisible(true)} icon="pi pi-check" label="Confirm" />
            </div>
        </>
    )
}
        