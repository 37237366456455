import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
// import { AutoComplete } from "primereact/autocomplete";
// import { InputText } from "primereact/inputtext";
import { SpeedDial } from "primereact/speeddial";
import { Trophy, X, Shirt, PersonStanding, Dribbble, BarChart3, LayoutDashboard, Info } from "lucide-react";
// import Navbar from "./Navbar";
import { useLocation, useNavigate } from "react-router-dom";
// import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AddPlayerToTournament from "./AddPlayerToTournament";
import AddTeamToTournament from "./AddTeamToTournament";
// import AutoCompleteComponent from "./AutoCompleteComponent";
import { Tooltip } from "primereact/tooltip";
// import { UserCircle2, Menu } from 'lucide-react';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import AgeGroupTab from "./AgeGroupTab.js";
import { Link, Outlet } from "react-router-dom";
import { showSuccess,showWarning,showError } from './toasts';
const { REACT_APP_URL } = process.env;
const LinkTagStyle = { textDecoration: "none", color: "#FF8734" };
const Header = ({
  toast,
  setVisibleAddTeam,
  setVisibleAddPlayer,
  setTournament_Visible,
  update_players,
  update_teams,
  setTournamentInstructionModalOpen,
  setOpenAddPlayersToTournament,
  setOpenAddTeamsToTournament,
  openAddPlayersToTournament,
  openAddTeamsToTournament,
  setTeamInstructionModalOpen,
  setPlayerInstructionModalOpen,
  setMatchInstructionModalOpen,
  ageGroupChanged,
  setAgeGroupChanged,
  ageTournament,
  setAgeTournament,
  eventOccured,
  openTabs
}) => {
  const [selectedTournament, setSelectedTournament] = useState(
    JSON.parse(sessionStorage.getItem("tournament"))
  );
  const [anotherSelectedTournament, setAnotherSelectedTournament] = useState(JSON.parse(sessionStorage.getItem("tournament")))
  const [tournaments, setTournaments] = useState([]);
  const [showNavbar, setShowNavbar] = useState(false);
  const [tournamentSearch, setTournamentSearch] = useState("");
  const [tournamentSearchArray, setTournamentSearchArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addType, setAddType] = useState("");
  const [completed, setCompleted] = useState([]);
  const [completedIndividual, setCompletedIndividual] = useState([]);
  const [Grouptournament,SetGroupTournament]=useState([]);
  const [ageGroup,setAgeGroup]=useState([]);
  const [OpenAddSponsers,setOpenAddSponsers]=useState(false);
  const [payLoad,setPayload]=useState({
    name:"",
    tournament:"",
    url:""
  });
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    sessionStorage.setItem("Logout", "true");
    navigate("/");
  };
  
  const UploadSponserLogo= async(e)=>
  {
    const formData= new FormData();
    formData.append('image',e.files[0]);
    let response=await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
     headers: {
         Authorization: sessionStorage.getItem("accessToken")
     }
    });

    if(response.data.msg==="Image Uploded Successfully"){
       showSuccess("Image Uploaded",toast);
       setPayload(prev=>({...prev,url:response.data.url}));
    }
    else{
       showWarning(response.data.msg,toast);
    }

  }
  const UploadSponser=async ()=>
  {
     setPayload(prev=>({...prev,tournament:selectedTournament._id}));
     let reply= await axios.post(`${REACT_APP_URL}/tournaments/UploadSponser`,payLoad, {
      headers: {
        Authorization: sessionStorage.getItem("accessToken")
      }
     });
     console.log(reply);
     if(reply.data==='Sponsor Uploaded'){
       showSuccess("Sponsor Added Successfully",toast);
       setPayload(prev=>({...prev,url:""}));
       setOpenAddSponsers(false);
     }else{
       showError(reply.data,toast);
     }
  }

  const getInfo = async () => {
    if(selectedTournament !== null) {
        let newCompleted = completed;
        !selectedTournament?.individual ? newCompleted[0] = true : completedIndividual[0] = true
        !selectedTournament?.individual ? setCompleted(newCompleted) : setCompletedIndividual(newCompleted);
    
        const responseOne = await axios.get(`${REACT_APP_URL}/players/getPlayers/${selectedTournament?._id}`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
            if(responseOne.data.length > 0) {
                let newCompleted = completed;
                !selectedTournament?.individual ? newCompleted[1] = true : completedIndividual[1] = true
                !selectedTournament?.individual ? setCompleted(newCompleted) : setCompletedIndividual(newCompleted);
        }
        else {
            let newCompleted = completed;
            !selectedTournament?.individual ? newCompleted[1] = true : completedIndividual[1] = false
            !selectedTournament?.individual ? setCompleted(newCompleted) : setCompletedIndividual(newCompleted);
        }

        const responseTwo = await axios.get(`${REACT_APP_URL}/teams/allTeams/${selectedTournament?._id}`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
            if(responseTwo.data.length > 0) {
                let newCompleted = completed;
                newCompleted[2] = true;
                setCompleted(newCompleted)
        }
        else {
            let newCompleted = completed;
                newCompleted[2] = false;
                setCompleted(newCompleted)
        }

        const responseThree = await axios.get(`${REACT_APP_URL}/tournaments/getInternalTournaments/${selectedTournament?._id}`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
            if(responseThree.data.length > 0) {
                let newCompleted = completed;
                !selectedTournament?.individual ? newCompleted[3] = true : completedIndividual[2] = true
                !selectedTournament?.individual ? setCompleted(newCompleted) : setCompletedIndividual(newCompleted);
        }
        else {
            let newCompleted = completed;
            !selectedTournament?.individual ? newCompleted[3] = true : completedIndividual[2] = true
                !selectedTournament?.individual ? setCompleted(newCompleted) : setCompletedIndividual(newCompleted);
        }
        }
}

  const getAddType = () => {
    if(location.pathname === "/showTournaments") {
      setAddType("Create a Tournament")
    }
    else if(location.pathname === "/showTeams") {
      setAddType("Create a Team");
    }
    else if(location.pathname === "/showPlayers") {
      setAddType("Create a Player");
    }
  }

  const openInstructionsModal = () => {
    if(location.pathname === "/showTournaments") {
      setTournamentInstructionModalOpen(true);
    }
    else if(location.pathname === "/showTeams") {
      setTeamInstructionModalOpen(true);
    }
    else if(location.pathname === "/showPlayers") {
      setPlayerInstructionModalOpen(true);
    }
    else if(location.pathname === "/showMatches") {
      setMatchInstructionModalOpen(true);
    }
  }

  const NavItem = ({ Component }) => {
    return (
      <div className="flex justify-content-start align-items-center gap-3">
        {Component}
      </div>
    );
  };




  const getTournaments = async () => {
      setLoading(true);
      const response = await axios.get(
        `${REACT_APP_URL}/tournaments/allTournaments`,
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
        );
    setTournaments(response.data.tournaments);
    setTournamentSearchArray(response.data.tournaments);
    sessionStorage.setItem("Auth", response.data.Auth);
    sessionStorage.setItem(
      "Tournaments",
      JSON.stringify(response.data.tournaments)
      );
      setLoading(false);
  };

  const handleTournamentChange = async (e) => {
      setSelectedTournament(e.value);
      sessionStorage.setItem("tournament", JSON.stringify(e.value));
      setAgeGroup(e.value.ageGroups);
  };

  const add = () => {
    if (location.pathname === "/showTournaments") {
      setTournament_Visible(true);
    } else if (location.pathname === "/showPlayers") {
      setVisibleAddPlayer(true);
    } else if (location.pathname === "/showTeams") {
      setVisibleAddTeam(true);
    }
  };

  const OpenNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const organizerOptions = [
    {
      label: location.pathname === "/showMatches" ? "" : addType,
      icon: "pi pi-plus",
      command: () => add(),
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
    },
    {
      label: "Logout",
      icon: "pi pi-power-off",
      command: () => logout(),
    },
    {
      label: "Add Player To Tournament",
      icon: "pi pi-users",
      command: () => setOpenAddPlayersToTournament(true),
    },
    {
      label: "Add Team To Tournament",
      icon: "pi pi-users",
      command: () => setOpenAddTeamsToTournament(true),
    },
    {
      label:"Add Tournament Sponsors",
      command:()=>setOpenAddSponsers(true),
    }
  ];

  const organizerOptionsIndividualTournament = [
    {
      label: addType,
      icon: "pi pi-plus",
      command: () => add(),
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
    },
    {
      label: "Logout",
      icon: "pi pi-power-off",
      command: () => logout(),
    },
    {
      label: "Add Player To Tournament",
      icon: "pi pi-users",
      command: () => setOpenAddPlayersToTournament(true),
    },
    {
      label: "Add Team To Tournament",
      icon: "pi pi-users",
      command: () => setOpenAddTeamsToTournament(true),
    },
    {
      label:"Add Tournament Sponsors",
      command:()=>setOpenAddSponsers(true),
    }
  ];

  const playerOptions = [
    {
      label: "Logout",
      icon: "pi pi-power-off",
      command: () => logout(),
    },
  ];

  const getAgeGroupTournaments=async()=>{
    if(ageGroup?.length>0){
      try{
        const reply= await axios.post(`${REACT_APP_URL}/tournaments/AgeGroupTournaments`,ageGroup);
        if(await reply.data.msg==="Successfull"){
             SetGroupTournament(reply.data.ans);
             const respose= await axios.post();
        }
        else{
          throw(reply.data);
        }
      }catch(err){
         console.log(err);
      }
    }
  }

  useEffect(() => {
    getTournaments();

  }, []);

  useEffect(() => {
    getInfo();
    getAgeGroupTournaments();
  },[selectedTournament, completed, completedIndividual])

  useEffect(() => {
    getAddType();
  }, [location.pathname])

  return (
    <>
      {sessionStorage.getItem("Auth") === "organizer" && (
        <>
          <Tooltip
            target=".speeddial-bottom-right .p-speeddial-action"
            position="left"
          />
          <SpeedDial
            model={
              selectedTournament?.individual
                ? organizerOptionsIndividualTournament
                : organizerOptions
            }
            direction="up"
            className="absolute speeddial-bottom-right md:hidden"
            style={{ bottom: "2%", right: "2%" }}
          />
        </>
      )}
      {sessionStorage.getItem("Auth") !== "organizer" && (
        <>
          <Tooltip
            target=".speeddial-bottom-right .p-speeddial-action"
            position="left"
          />
          <SpeedDial
            model={playerOptions}
            direction="up"
            className="absolute speeddial-bottom-right md:hidden"
            style={{ bottom: "2%", right: "2%" }}
          />
        </>
      )}
  
        <div className="tournament-header">
          <div className="flex justify-content-between align-items-center p-2 ml-3">
          <div className="flex justify-content-start align-items-center gap-8">
          <NavItem
            Component={
              <Link className="navLink" style={LinkTagStyle} to="/showTournaments">
                Tournaments
              </Link>
            }
          />
          <NavItem
            Component={
              <Link className="navLink" style={LinkTagStyle} to="/showPlayers">
                Players
              </Link>
            }
          />
          <NavItem
            Component={
              <Link className="navLink" style={LinkTagStyle} to="/showTeams">
                Teams
              </Link>
            }
          />
          <NavItem
            Component={
              <Link className="navLink" style={LinkTagStyle} to="/showMatches">
                Matches
              </Link>
            }
          />
          <NavItem
            Component={<Link className="navLink" style={LinkTagStyle} to="/showDashboard">Dashboard</Link>}
          />
           </div>
          {loading && (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem", color: "#FF8734" }}
            ></i>
          )}
          {!loading && (
            <div className="flex justify-content-end align-items-center">
              <Dropdown
                panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                style={{ backgroundColor: "#383838", border: "none" }}
                value={selectedTournament}
                onChange={handleTournamentChange}
                options={
                  JSON.parse(sessionStorage.getItem("Tournaments")) || tournaments
                }
                optionLabel="name"
                placeholder="Select a Tournament"
                />
            </div>
          )}
          </div>
          {
            
            selectedTournament?.ageGroups?.length>0 && openTabs&&
            <div className="flex flex-row justify-content-center align-items-center">
              <AgeGroupTab tournament={Grouptournament} setSelectedTournament={setSelectedTournament} ageGroupChanged={ageGroupChanged} setAgeGroupChanged={setAgeGroupChanged} setAgeTournament={setAgeTournament}/>
            </div>
          }
         
        </div>
       
      {sessionStorage.getItem("Auth") === "organizer" && (
        <>
          {selectedTournament?.individual && (
            <div className="hidden tournament-options md:p-1 md:flex md:justify-content-center md:align-items-center md:gap-8">
              {!selectedTournament && (
                <>
                        <Info color="#fff" className="cursor-pointer" onClick={openInstructionsModal} />
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={organizerOptions[0].command}
                        >{organizerOptions[0].label}</p>
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={organizerOptions[2].command}
                        >{organizerOptions[2].label}</p>
                      </>
              )}
              {selectedTournament && (
                <>
                  <Info color="#fff" className="cursor-pointer" onClick={openInstructionsModal} />
                  {organizerOptionsIndividualTournament.map((option, index) => {
                    return (
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={option.command}
                        >{option.label}</p>
                    );
                  })}
                </>
              )}
            </div>
          )}
          {!selectedTournament?.individual && (
            <div className="hidden tournament-options md:p-1 md:flex md:justify-content-center md:align-items-center md:gap-8">
              {!selectedTournament && (
                <>
                        <Info color="#fff" className="cursor-pointer" onClick={openInstructionsModal} />
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={organizerOptions[0].command}
                        >{organizerOptions[0].label}</p>
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={organizerOptions[2].command}
                        >{organizerOptions[2].label}
                        </p>
              </>
              )}
              {selectedTournament && (
                <>
                  <Info color="#fff" className="cursor-pointer" onClick={openInstructionsModal} />
                  {organizerOptions.map((option, index) => {
                    return (
                      <>
                        <p
                          className="cursor-pointer text-md text-white"
                          onClick={option.command}
                        >{option.label}</p>  
                      </>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </>
      )}
      {/* {showNavbar && <Navbar setShowNavbar={setShowNavbar} />} */}
      {/* Modals */}
      <Dialog
        style={{ width: "50vw" }}
        visible={openAddPlayersToTournament}
        onHide={() => setOpenAddPlayersToTournament(false)}
      >
        <AddPlayerToTournament
          tournament={selectedTournament}
          setOpenAddPlayersToTournament={setOpenAddPlayersToTournament}
          update_players={update_players}
        />
      </Dialog>
      <Dialog
        style={{ width: "50vw" }}
        visible={openAddTeamsToTournament}
        onHide={() => setOpenAddTeamsToTournament(false)}
      >
        <AddTeamToTournament  update_teams={update_teams} />
      </Dialog>

      <Dialog
        style={{ width: "30vw" }}
        visible={OpenAddSponsers}
        onHide={()=>setOpenAddSponsers(false)}
        header="Add Sponsors"
      >
        <div className="flex flex-column gap-3 justify-content-start align-items-start p-5">
          <InputText onChange={e=>setPayload(prev=>({...prev,name:e.target.value}))}/>
          <FileUpload name="demo[]" url={'/api/upload'} customUpload uploadHandler={e=>UploadSponserLogo(e)} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
          <Button label="Submit" onClick={UploadSponser}/>
        </div>

      </Dialog>
      <Outlet />
    </>
  );
};

export default Header;
