import React, { useEffect } from 'react'
import trofy from '../Assets/Login/trofy.png'
import {useState,useRef} from 'react'
import { Toast } from 'primereact/toast';
import { Link, useLocation } from 'react-router-dom';
import { LogIn } from 'lucide-react';
import '../App.css'
import tennis from "../Assets/tennis.png";
import user from "../Assets/user.png";
import backgroundImage from "../Assets/LoginBackground.png";
import { useNavigate } from 'react-router-dom'
import axios from'axios';
const { REACT_APP_URL } = process.env;

const Login = ({ setSelectedTournament }) => {
  const navigate = useNavigate();
  const [email,setEmail]=useState('');
  const [password,setPassword]=useState('');
  const [optionSelected, setOptionSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [err1,setErr1]=useState(false);
  const toast = useRef(null);

  const showSuccess = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail:message, life: 3000});
  }

  const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Login Error', life: 3000});
  }

  const showWarn = (text) => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:text, life: 3000});
}

  const login = async() => {
    if(email==='' || password==='')showWarn('Enter the username and password!');
    else {
      const user={
        'mail':email,
        'password':password
      }
      try{
        setLoading(true);
          if(optionSelected === "player") {
            const res=await axios.post(`${REACT_APP_URL}/auth/playerLogin`,user);
            if(res.data.message==='Login successfull'){
                sessionStorage.setItem("accessToken", res.data.token);
                if(res.data.leader) {
                  sessionStorage.setItem("leader", "true");
                }
                localStorage.setItem("Tournaments", "[]");
                sessionStorage.setItem("Logout", "false");
                navigate("/showTournaments");
            }
            }
          else if(optionSelected === "organizer") {
            const res = await axios.post(`${REACT_APP_URL}/auth/organizerLogin`, user);
            if(res.data.message === "Login successfull") {
              sessionStorage.setItem("accessToken", res.data.token);
              localStorage.setItem("Tournaments", "[]");
              sessionStorage.setItem("Logout", "false")
                navigate("/showTournaments");
            }
          } 
          setLoading(false);
        }
        catch(err){
          showError()
        }
    }  
}

  useEffect(() => {
    if(sessionStorage.getItem("Logout") === "true") {
      localStorage.clear();
      sessionStorage.clear();
      setSelectedTournament(null);
    }
    else if(sessionStorage.getItem("Logout") === "false") {
      navigate("/showTournaments");
    }
  },[])

  return (
 
    <div className="login-container flex flex-column gap-5">
        <div className="flex justify-content-center">
          <Toast ref={toast} />
        </div>
        <div className='login-title mb-5 md:mb-8 lg:mb-8'>
          <h4 className='text-primary text-2xl font-normal zoomin animation-duration-500'>Matchpointandgames.com</h4>
        </div>
        <div className='login-container-child md:mt-8' id='login_grid'>
            {optionSelected === "" && (
              <div className="flex justify-content-center w-full gap-5 flex-wrap">
                  <div className="options-grid-child flex flex-column align-items-center py-4 w-14rem md:w-20rem lg:w-25rem  xl:w-25rem">
                    <p className="btn-knockout text-white text-xl text-center border-round-md zoomin animation-duration-500" onClick={() => setOptionSelected("player")}>Player</p>
                  </div>
                  <div className="options-grid-child flex flex-column align-items-center py-4 w-14rem md:w-20rem lg:w-25rem  xl:w-25rem">
                    <p className="btn-knockout text-white text-xl text-center border-round-md zoomin animation-duration-500" onClick={() => setOptionSelected("organizer")}>Organizer</p>
                  </div>
              </div>
        )}
            {optionSelected !== "" && (
            <div className='w-max flex flex-column gap-6 border-round-2xl align-items-center pb-5 px-5 zoomin animation-duration-500 mt-7' id='login__form'>
                <input type='email'
                    className='w-20rem border-top-none border-right-none border-left-none h-2rem px-2 text-lg text-white font-light'
                    placeholder="Registered Email"
                    onChange={(e)=>{
                      setEmail(e.target.value)
                    }} 
                    style={{fontFamily: "'Inter', sans-serif", background: "none", borderBottom: "1px solid #cacaca"}}
                    required
                />
                
                <input type='password'
                    className='w-20rem border-top-none border-right-none border-left-none h-2rem px-2 text-lg text-white font-light'
                    placeholder='Password'
                    onChange={(e)=>{
                      setPassword(e.target.value)
                    }}
                    style={{fontFamily: "'Inter', sans-serif", background: "none", borderBottom: "1px solid #cacaca"}}
                    required
                />
              
                <div className='flex justify-content-between align-items-center gap-8'>
                    <Link to={'/Register'} className='no-underline font-light' style={{color:'#FF8734'}}> Register here</Link>
                    <Link to={'/ForgotPassword'} className='no-underline font-light' style={{color:'#FF8734'}}> Forgot password?</Link>
                </div>
                {loading && (
                  <i className="pi pi-spin pi-spinner mt-5" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
                )}
                {!loading && (
                  <button type='submit' 
                    className='border-none text-white text-lg border-round-sm cursor-pointer py-2 px-4 mt-5 hover:bg-orange-500' 
                    style={{backgroundColor:'#FF8734'}}
                    onClick={login}>
                      Login
                  </button>
                )}
            </div>
            )}
        </div>
    </div>

  )
}

export default Login