import React from 'react'

const HowToDelete = () => {
  return (
    <>
        <div style={{ width: "100%", backgroundColor: "#2b2b2b", height: "100vh" }} className="flex flex-column justify-content-start align-items-center pt-4 px-4 gap-5">
            <h2 className="text-primary">
                Matchpointandgames
            </h2>
            <h3 className="text-white">If you like your account to be deleted, drop us an email at - mukund@mjfabricology.com. Your account (username and password) will be deleted within 3 days. Please try to login in the app to verify. If you are able to login, please contact us at the same email address</h3>
        </div>
    </>
  )
}

export default HowToDelete
