import { useRef, useState } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
const { REACT_APP_URL } = process.env;

const AddTeam = ({tournaments, leader}) => {
    const [selectedTournament, setSelectedTournament] = useState(null);
    const toast = useRef(null);
    const [nameError, setNameError] = useState(false);
    const [errorLogo, setErrorLogo] = useState(false);
    const [errorLeader, setErrorLeader] = useState(false);
    const [name, setName] = useState("");
    const [club, setClub] = useState("");
    const [logo, setLogo] = useState("");

    const showSuccess = (detail) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: detail,
          life: 3000,
        });
    };
    const showWarn = (text) => {
      toast.current.show({severity:'warn', summary: 'Warning', detail:text, life: 3000});
  }

    const addTeam = async () => {
      if(name === "") setNameError(true);
      if(name !== "") {
      let response = await axios.post(
        `${REACT_APP_URL}/teams/createTeamByLeader`,
        {
          TeamName: name,
          tournament_id: selectedTournament._id,
          leader: leader,
          logo: logo,
          club: club,
        }
      );

      setErrorLogo(false);
      setErrorLeader(false);
      showSuccess(response.data.message);
      }
    };

    const customBase64Uploader = async (event) => {
     
      const file = event.files[0];
      const formData=new FormData();
      formData.append('image',file);
      let response=await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
       });
       console.log(response);
       if(response.data.msg==="Image Uploded Successfully"){
        setLogo(response.data.url);
       }
       else{
        showWarn(response.data)
       }

    };

    return (
        <>
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
        </div>
      <div style={{ fontFamily: "'Inter', sans-serif" }}>
        <div className="grid">
          <div className="col flex flex-column gap-3">
            <label>Name</label>
            <div>
              <InputText
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <p className="error-message">Name should not be empty</p>}
            </div>
          </div>
          <div className="col flex flex-column gap-3">
            <label>Club</label>
            <InputText
              style={{ fontFamily: "'Inter', sans-serif" }}
              placeholder="Club"
              value={club}
              onChange={(e) => setClub(e.target.value)}
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column gap-3">
            <label>Logo</label>
            <div>
              <FileUpload
                style={{ fontFamily: "'Inter', sans-serif" }}
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                customUpload
                uploadHandler={customBase64Uploader}
              />
              {errorLogo && <p className="error-message">No file chosen</p>}
            </div>
          </div>
          <div className="col flex flex-column gap-3">
          <label>Tournament</label>
            <div>
              <Dropdown
                panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                style={{ borderRadius: "4px" }}
                value={selectedTournament}
                onChange={(e) => setSelectedTournament(e.target.value)}
                options={tournaments}
                optionLabel="name"
                placeholder="Select a Tournament"
                required
              />
              {errorLeader && <p className="error-message">No Tournament Chosen</p>}
            </div>
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column">
            <Button
              style={{ fontFamily: "'Inter', sans-serif" }}
              label="Add Team"
              onClick={addTeam}
            />
          </div>
        </div>
      </div>
      </>
    );
};

export default AddTeam;