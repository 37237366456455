import { Shirt } from 'lucide-react';
import React from 'react'

const TeamInstructionModal = ({ setVisibleAdd, setTeamInstructionModalOpen, selectedTournament, setOpenAddTeamsToTournament }) => {
    const openCreateTeamModal = () => {
        setTeamInstructionModalOpen(false);
        setVisibleAdd(true);
    }

    const closeTeamInstructionsModal = () => {
        setTeamInstructionModalOpen(false);
    }

  return (
    <div className="flex flex-column justify-content-center gap-3 px-5">
        <div className="flex justify-content-center align-items-center gap-3">
            <Shirt />
            <p className="text-lg">Welcome to the Teams Page</p>
            {selectedTournament !== null && (
                <p className="text-lg"> | | &nbsp; You just selected a Tournament</p>
            )}
        </div>
        <div className="flex justify-content-between align-items-center mt-2">
            <p className="text-lg cursor-pointer" onClick={openCreateTeamModal}>Create a Team</p>
            {selectedTournament !== null && (
                <p className="text-lg cursor-pointer" onClick={() => setOpenAddTeamsToTournament(true)}>Add Existing Teams to Tournament</p>
            )}
            <p className="text-lg cursor-pointer" onClick={closeTeamInstructionsModal}>Explore</p>
        </div>
    </div>
  )
}

export default TeamInstructionModal
