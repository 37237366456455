import React from 'react';
import { useState } from 'react';
import backgroundImage from "../Assets/LoginBackground3.png";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import axios from 'axios';
import { SelectButton } from 'primereact/selectbutton';
import { Password } from 'primereact/password';
import { showSuccess,showWarning,showError } from './toasts';
const { REACT_APP_URL } = process.env;

const ForgotPassword = ({toast}) => {
const [Mail, setMail] = useState('');
const options = ['Player', 'Organizer'];
const [Role, setRole] = useState(options[0]);
const [flag,setFlag]=useState(false);
const [NewPass, setNewPass] = useState('');
const [NewPass2, setNewPass2] = useState('');


  const HandleVerify=async()=>
  {
     if(Mail!=='' && verifyMail(Mail)){
        const response= await axios.post(`${REACT_APP_URL}/Auth/ForgotPass/verify`,{Mail,Role});
        if(response.data==='Verified'){
            showSuccess('Email verified',toast);
            setFlag(true);
        }
        else{
            showWarning('Wrogn Email',toast)
        }
     }
     else{
       showWarning('Invalid Email',toast)
     }
  }
  
  const verifyMail=(mail)=>{
    const temp=mail.toLowerCase();
    if(temp===mail && mail.includes('@')){return true;}
    return false;
  }

  const verfyPassword=(Pass1,Pass2)=>{
    if(Pass1===Pass2 && Pass1.length>7){return true;}
    return false;
  }

  const HnadleSubmit= async()=>
  {
  

    if(verfyPassword(NewPass,NewPass2)){
        const response_2= await axios.post(`${REACT_APP_URL}/Auth/forgotPass/NewPass`,{NewPass,Role,Mail});
     
        if(response_2.data==="Success"){
            showSuccess("Password Change SuccessFully!",toast);
        }
        else{
            showError("Failed to change the password",toast);
        }

    }
    else {
       showWarning('new Password is week or password nd confirm password dont match',toast);
    }


  }
  return (
    <div style={{backgroundImage:`url(${backgroundImage})`,backgroundSize:'cover',height:'100vh'}} className='flex align-items-center flex-column gap-7 '>
            <h2 style={{color:'#FF8734'}} className='mt-7'>Forgot Password</h2>
            
             <div className='flex flex-column gap-4'>
                <label htmlFor="" style={{color:'#FF8734'}}>Select Role</label>
                    <SelectButton value={Role} onChange={(e) => setRole(e.value)} options={options} />
                <label htmlFor="" style={{color:'#FF8734'}}>Enter the Login ID (email_id)</label>
                <div className='flex gap-2'>
                    <InputText value={Mail} onChange={(e) => setMail(e.target.value)} />
                    <Button label='Verify' onClick={HandleVerify}/>
                </div>
            </div>

            {
               flag &&
                <div className='flex flex-column gap-4 border-round p-5' style={{border:'1px solid #FF8734'}}>
                    <div className='flex flex-column gap-4'>
                        <label style={{color:'#FF8734'}}>Enter the new Password</label>
                        <Password value={NewPass} onChange={(e) => setNewPass(e.target.value)} toggleMask />
                    </div>
                    <div className='flex flex-column gap-4'>
                        <label style={{color:'#FF8734'}}>Confirm Password</label>
                        <Password value={NewPass2} onChange={(e) => setNewPass2(e.target.value)} toggleMask />
                    </div>

                    <Button label='Submit' onClick={HnadleSubmit}/>
                </div>
            }
    </div>
  )
}

export default ForgotPassword