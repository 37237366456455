import axios from 'axios';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
const { REACT_APP_URL } = process.env;

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  browseFile: {
    width: '20%',
    backgroundColor: "#FF8734",
    color: "#fff",
    border: "none",
    fontFamily: "'Inter', sans-serif"
  },
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  },
  remove: {
    border: "none",
    padding: '0 20px',
    color: "#fff",
    backgroundColor: "red",
    fontFamily: "'Inter', sans-serif"
  },
  progressBarBackgroundColor: {
    backgroundColor: 'green',
  },
};

const InputCsvFile = () => {
    const [loader, setLoader] = useState(false);
    const { CSVReader } = useCSVReader();
    const toast = useRef(null);

    const showSuccess = (detail) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: detail,
          life: 3000,
        });
    };

    const showError = (detail) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: detail,
          life: 3000,
        });
    };

    return (
        <>
        <div className="flex justify-content-center">
            <Toast ref={toast} />
        </div>
        <CSVReader
        onUploadAccepted={async (results) => {
            // console.log(results.data);
            setLoader(true);
            const response = await axios.post(`${REACT_APP_URL}/csv/uploadPlayerPoints`, {
                dataArray: results.data
            }, {
                headers: {
                    Authorization: sessionStorage.getItem("accessToken")
                }
            })

            if(response.data.message === "Data Uploaded Successfully") {
                showSuccess(response.data.message);
                setLoader(false);
            }
            else {
                showError(response.data.message);
                setLoader(false);
            }

            setLoader(false);
        }}
        >
        {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
        }) => (
            <>
            <div style={styles.csvReader}>
                <button type='button' {...getRootProps()} style={styles.browseFile}>
                Browse file
                </button>
                <div style={styles.acceptedFile}>
                {acceptedFile && acceptedFile.name}
                </div>
                <button {...getRemoveFileProps()} style={styles.remove}>
                Remove
                </button>
            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
            {loader && (
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem", color: "blue" }}></i>
            )}
            </>
        )}
        </CSVReader>
        </>
    );
}

export default InputCsvFile
