import React,{useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { ScrollPanel } from "primereact/scrollpanel"

const Messages = () => {
    const [values,setValues]=useState(0);
    const [visible, setVisible] = useState(false);

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
        <Button type="button" label="Notifications" icon="pi pi-bell"  onClick={() => setVisible(true)}>
            <Badge value={values}></Badge>
        </Button>

        <Dialog
            header="Header"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
        >
                <div className="flex flex-column">
                    <ScrollPanel
                        style={{ width: "100%", height: "200px" }}
                        className="custombar1"
                    >
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat.
                        </p>
                      
                    </ScrollPanel>
                </div>
        </Dialog>
    </div>
  )
}

export default Messages