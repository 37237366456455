import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AgeGroupTab = ({ tournament, setSelectedTournament, ageGroupChanged, setAgeGroupChanged, setAgeTournament }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAgeTournament(tournament[newValue])
    sessionStorage.setItem("tournament", JSON.stringify(tournament[newValue]))
    setAgeGroupChanged(!ageGroupChanged)
  };
  

  const Nav = () => {
    const Items = [];
    for (let i = 0; i <tournament?.length; i++) {
      Items.push(
        <Tab
          key={i}
          label={`${tournament[i]?.name}`}
          style={{ color: "White", fontFamily: "'Inter', sans-serif", fontSize: "12px" }}
          {...a11yProps(i)}
        />
      );
    }
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        TabIndicatorProps={{
          sx: {
            backgroundColor: "#FF8734"
          }
        }}
        ScrollButtonComponent={(props) => {
          if (props.direction === "left") {
            return (
              <IconButton {...props}>
                <KeyboardArrowLeftIcon sx={{ color: "white" }} />
              </IconButton>
            );
          } else if (props.direction === "right") {
            return (
              <IconButton {...props}>
                <ChevronRightIcon sx={{ color: "white" }} />
              </IconButton>
            );
          } else {
            return null;
          }
        }}
        centered={true}
        sx={{
          "& .MuiTabs-indicator": {
            color: "orange",
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            color: "white"
          }
        }}
      >
        {Items}
      </Tabs>
    );
  };

  useEffect(() => {
    setAgeTournament(tournament[0]);
    setAgeGroupChanged(!ageGroupChanged);
  },[])

  return (
      <Box>
        <Nav />
      </Box>
  );
};

export default AgeGroupTab;
