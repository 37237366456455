import axios from 'axios';
import { Plus, Trash2 } from 'lucide-react';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { showError, showSuccess } from './toasts';
const { REACT_APP_URL } = process.env;

const MatchTemplate = ({ tournament_id, group_number, toast }) => {
    const [descriptions, setDescriptions] = useState([""]);

    const addDescription = () => {
        setDescriptions([...descriptions, ""]);
    }

    const removeDescription = (descriptionIndex) => {
        let array = descriptions.filter((description, index) => {
            return index !== descriptionIndex;
        })

        setDescriptions(array);
    }

    const createTheTemplate = async () => {
        const response = await axios.post(`${REACT_APP_URL}/singleElimination/addMatchTemplate`, {
            tournament_id: tournament_id,
            group_number: group_number,
            descriptions: descriptions
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })

        if(response.data.message === "Template created successfully") {
            showSuccess(response.data.message, toast);
        }
        else {
            showError(response.data.message, toast);
        }
    }

    return (
        <div className='flex flex-column gap-7'>
            {descriptions.map((description, index) => {
                return (
                    <div key={index} className='flex justify-content-start align-items-center gap-5 w-full'>
                        <Plus className='cursor-pointer' onClick={addDescription} />
                        <Trash2 className='cursor-pointer' onClick={() => removeDescription(index)} />
                        <InputText className='w-full' value={descriptions[index]} onChange={(e) => {
                            let array = descriptions.slice();
                            array[index] = e.target.value;
                            setDescriptions(array);
                        }} placeholder='Description' />
                    </div>
                )
            })}
            <div className='flex justify-content-center align-items-center px-1 bg-primary border-round-sm cursor-pointer' onClick={createTheTemplate}>
                <p className='text-white'>Create The Template</p>
            </div>
        </div>
    )
}

export default MatchTemplate
