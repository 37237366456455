import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { showError, showSuccess, showWarning } from "../toasts";
const { REACT_APP_URL } = process.env;

const AddPlayerForm = ({ toast }) => {
  const [reqBody, setReqBody] = useState({
    player_email: "",
    team_name: "",
    leader_email: "",
  });

  const validateEmail = (email) => {
    if (email.includes("@") && email === email.toLowerCase()) {
      return true;
    } else return false;
  };

  const handleSubmit = async () => {
    if (
      reqBody.player_email === "" ||
      reqBody.leader_email === "" ||
      reqBody.team_name === ""
    ) {
      showWarning("Enter all the fields of the form",toast);
    }
    if (!validateEmail(reqBody.player_email)) {
      showWarning("Invalid player  Email",toast);
    }
    if (!validateEmail(reqBody.leader_email)) {
      showWarning("invalid leader email",toast);
    }

    if (
      validateEmail(reqBody.player_email) &&
      validateEmail(reqBody.leader_email) &&
      reqBody.team_name !== ""
    ) {
      try {
        const res = await axios.post(
          `${REACT_APP_URL}/teams/sendTeamJoinRequestLeader`,
          reqBody
        );
        console.log(res.data);
        if (res.data === "Empty form",toast) {
          showWarning("fill the all fields of the form",toast);
        } else if (res.data === "player not found") {
          showWarning("Player with this email is not registerd",toast);
        } else if (res.data === "Team not found") {
          showWarning("Team doesn't exist",toast);
        } else if (res.data === "leader not found") {
          showWarning("Leader with this email is not registerd",toast);
        } else if (res.data === "Request Sent Successfully") {
          showSuccess("Request Sent Successfully",toast);
        } else if (res.data === "Repeat request") {
          showWarning("Player has not responded to previous request",toast);
        } else if (res.data === "player is in another Team") {
          showWarning("Player is in another team",toast);
        } else {
          showError(res.data);
        }
      } catch (err) {
        showError("Network Error !");
      }
    }
  };
  return (
    <div className="flex flex-column align-items-center gap-3">
      <InputText
        placeholder="player_email"
        value={reqBody.player_email}
        onChange={(e) =>
          setReqBody({ ...reqBody, player_email: e.target.value })
        }
      />
      <InputText
        placeholder="Team Name"
        value={reqBody.team_name}
        onChange={(e) => setReqBody({ ...reqBody, team_name: e.target.value })}
      />
      <InputText
        placeholder="Leader Email"
        value={reqBody.leader_email}
        onChange={(e) =>
          setReqBody({ ...reqBody, leader_email: e.target.value })
        }
      />
      <Button label="Send Request" onClick={handleSubmit} />
    </div>
  );
};

export default AddPlayerForm;
