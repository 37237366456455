import { useState,useRef } from "react";
import React from 'react'
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from "primereact/fileupload";
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import axios from 'axios'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import AddTournamentGroups from "./AddTournamentGroups";
import { Check } from "lucide-react";
const { REACT_APP_URL } = process.env;

const Form=()=>
{
    const options1 = ['Team', 'Individual'];
    const options2=['Round Robin','Single Elimination']
    const options3 = ["League", "Regular"]
    const options4 = ["Doubles", "More than two members"]
    const [tournament,setTournament]=useState({
        name:"",
        venue:"",
        individual:options1[0],
        format:options2[0],
        fromDate:null,
        toDate:null,
    });
 
 
    const [city, setCity] = useState("");
    const [ageGroup,setAgeGroup]=useState(0);
    const [winPoint, setWinPoint] = useState("0");
    const [lossPoint, setLossPoint] = useState("0");
    const [successes, setSuccesses] = useState([]);
    const [showAgeGroup,setShowAgeGroup]=useState(false);
    const [checkDate1,setCheckDate1]=useState(false);
    const [checkDate2,setCheckDate2]=useState(false);
    const [Image,setImage]=useState(null);
    const [Group,setGroup]=useState([]);
    const [league, setLeague] = useState(options3[0]);
    const [doubles, setDoubles] = useState(options4[0]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    let currentIndex = 0;
   
    const showSuccess = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }

    const showError = (text) => {
        toast.current.show({severity:'error', summary: 'Error', detail:text, life: 3000});
    }

    const showWarn = (text) => {
        toast.current.show({severity:'warn', summary: 'Warning', detail:text, life: 3000});
    }
   
    const OpenButtons=(e)=>
    {
        e.preventDefault();
        setShowAgeGroup(true);
    }

    const AddAgeGroup=(id,name)=>
    {
        setGroup(prev=>([...prev,{Id:id,Name:name}]));
    }

    const Buttons=({n})=>
    {
        const itemElements = [];
            for (let i = 0; i < n; i++) {
                itemElements.push(
                  <div className="flex justify-content-center align-items-center">
                        <div className="flex flex-row gap-5 pb-2">
                            <Button label={`Add Age Group ${i + 1}`} onClick={() => {
                                currentIndex = i;
                                setVisible(true);
                                }} />
                        </div>
                        {successes[i] === "success" && (
                            <Check color="green" />
                        )}
                    </div>
                );
            }

            return(
                <div className="flex flex-column justify-content-center align-items-start">{itemElements}</div>
            )

    }

     const handleSubmit= async e=>{
            e.preventDefault()
            if(tournament.toDate===null){setCheckDate2(true)}
            if(tournament.fromDate===null){setCheckDate1(true)}
            let flag=false;

            if(checkDate1 || checkDate2 || tournament.name==='' || tournament.venue==='') flag=true;
            
          
            if(Image!==null){
                try{
                    const formData=new FormData();
                    formData.append('image',Image);
           
                    let response=await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
                     headers: {
                         Authorization: sessionStorage.getItem("accessToken")
                     }
                    });
                    
                    if(response.data.msg==="Image Uploded Successfully")
                    {
                        setTournament((prevTournament) => ({
                            ...prevTournament,
                            Logo:response.data.url,
                        }));
                    }
                    else {
                        showWarn(response.data);
                    }
                  }
                  catch(err){
                   showError('Error in Uploading image:',err);
                  }


            }
            if(flag===false){
                  setLoading(true);
                    let res=await axios.post(`${REACT_APP_URL}/tournaments/add`,{...tournament,city:city,AgeGroups:Group, regular: league === "League" ? false : true, doubles: doubles === "Doubles" ? true : false, winPoint: parseInt(winPoint), lossPoint: parseInt(lossPoint)}, {
                        headers: {
                            Authorization: sessionStorage.getItem("accessToken")
                        }
                    });

                    if(res.data.message==="Tournament Created"){ showSuccess(res.data.message)}
                    else if(res.data==="Tournament with same name already exists"){showWarn(res.data)}
                    else if(res.data.message==="Please Try Again")
                        {
                        showError("backend Error!");
                        }
                 setLoading(false);
            }
            else{
               
                 showWarn("All fields are not filled") 
            }
            
        } 
    return (

        <div className="flex justify-content-center align-items-center h-full">
             <Toast ref={toast} />
             <Dialog header="Add Age Group" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                  <AddTournamentGroups AddGroup={AddAgeGroup} showWarn={showWarn} showError={showError} showSuccess={showSuccess} successes={successes} setSuccesses={setSuccesses} currentIndex={currentIndex}/>
             </Dialog>
                <form className='flex flex-column gap-5'>
                    <div className='flex gap-7'>
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-column gap-3 w-30rem">
                                <label>Tournament name</label>
                                <InputText value={tournament.name} onChange={(e) => setTournament(prevState => ({ ...prevState, name: e.target.value }))} required />
                            </div>
                            <div className="flex">
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>Select Tournament Type</label>
                                    <SelectButton value={tournament.individual} onChange={(e) => setTournament(prev=>({...prev,individual:e.value}))} options={options1} />
                                </div>
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>Upload the logo for tournament</label>
                                    <FileUpload
                                    style={{ fontFamily: "'Inter', sans-serif" }}
                                    mode="basic"
                                    name="demo[]"
                                    url="/api/upload"
                                    accept="image/*"
                                    customUpload
                                    uploadHandler={e=>setImage(e.files[0])}
                                    />
                                </div> 
                            </div>

                            <div className="flex flex-column gap-3">
                                <div className="flex gap-5 align-items-end">
                                    <div className="flex flex-column gap-3">
                                        <label>Enter the No of age Groups</label>
                                        <InputText  onChange={(e)=>setAgeGroup(e.target.value)} required />
                                    </div>
                                    <Button label="Create Age Groups" className="h-3rem" onClick={e=>OpenButtons(e)}/>
                                </div>   
                            </div>

                            <div className="flex flex-column gap-3 ">
                                    <label>Select Tournament format</label>
                                    <SelectButton value={tournament.format} onChange={(e) => setTournament(prev=>({...prev,format:e.value}))} options={options2} />
                                </div>
                            
                            
                        </div> 
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-column gap-3">
                                <label>City</label>
                                <InputText value={tournament.city} onChange={(e) => setTournament(prev=>({...prev,city:e.value}))} required />
                            </div>
                            <div className="flex flex-column gap-3 w-30rem">
                                <label>Tournament Venue</label>
                                <InputText value={tournament.venue} onChange={(e) => setTournament(prevState => ({ ...prevState, venue: e.target.value }))} required />
                            </div>

                            <div className="flex gap-5">
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>From</label>
                                    <Calendar value={tournament.fromDate} onChange={(e) => setTournament(prevState => ({ ...prevState, fromDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />
                                  
                                </div>
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>To</label>
                                    <Calendar value={tournament.toDate} onChange={(e) => setTournament(prevState => ({ ...prevState, toDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />
                                </div>
                            </div>
                            <div className="flex justify-content-start align-items-center gap-5">
                                <div className="flex flex-column gap-3 w-15rem">
                                        <label>Select League/Regular</label>
                                        <SelectButton value={league} onChange={(e) => setLeague(e.value)} options={options3} />
                                </div>
                                {tournament.individual === "Team" && (
                                    <div className="flex flex-column gap-3">
                                        <label>Select Number of Members</label>
                                        <SelectButton value={doubles} onChange={(e) => setDoubles(e.value)} options={options4} />
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-content-start align-items-center gap-5">
                            <div className="flex flex-column gap-3">
                                <label>Win Points</label>
                                <InputText value={winPoint} onChange={(e) => setWinPoint(e.target.value)} required />
                            </div>
                            <div className="flex flex-column gap-3">
                                <label>Loss Points</label>
                                <InputText value={lossPoint} onChange={(e) => setLossPoint(e.target.value)} required />
                            </div>
                            </div>
                        </div>
                    </div>

                        {
                            showAgeGroup && (
                                <Buttons n={ageGroup}/>
                            )
                        }
                        {loading && (
                            <i className="pi pi-spin pi-spinner mt-5" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
                        )}
                        {!loading && (
                            <Button label="Submit" onClick={handleSubmit}/>
                        )}
                </form>
        </div>
    
     )
    
}

function AddTournament() {

  return (      
        <Form/>
  )
}

export default AddTournament