import { Trophy } from 'lucide-react'
import React from 'react'

const TournamentInstructionModal = ({ setTournamentInstructionModalOpen, setTournament_Visible }) => {
    const openCreateTournamentModal = () => {
        setTournamentInstructionModalOpen(false);
        setTournament_Visible(true);
    }

    const closeTournamentInstructionsModal = () => {
        setTournamentInstructionModalOpen(false);
    }

  return (
    <div className="flex flex-column justify-content-center gap-3 px-5">
        <div className="flex justify-content-center align-items-center gap-3">
            <Trophy />
            <p className="text-lg">Welcome to the Tournaments Page</p>
        </div>
        <div className="flex justify-content-between align-items-center mt-2">
            <p className="text-lg cursor-pointer" onClick={openCreateTournamentModal}>Create a Tournament</p>
            <p className="text-lg cursor-pointer" onClick={closeTournamentInstructionsModal}>Explore</p>
        </div>
    </div>
  )
}

export default TournamentInstructionModal
