import { Dribbble } from 'lucide-react'
import React from 'react'

const MatchInstructionModal = ({ scheduleRoundMatches, setMatchInstructionModalOpen, internalTournaments }) => {
    const closeMatchInstructionsModal = () => {
        setMatchInstructionModalOpen(false);
    }

  return (
    <div className="flex flex-column justify-content-center gap-3 px-5">
        <div className="flex justify-content-center align-items-center gap-3">
            <Dribbble />
            <p className="text-lg">Welcome to the Matches Page</p>
        </div>
        <div className="flex justify-content-between align-items-center mt-2">
            {internalTournaments.length === 0 && (
                <p className="text-lg cursor-pointer" onClick={scheduleRoundMatches}>Schedule Matches</p>
            )}
            <p className="text-lg cursor-pointer" onClick={closeMatchInstructionsModal}>Explore</p>
        </div>
    </div>
  )
}

export default MatchInstructionModal
