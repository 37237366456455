import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import AutoCompleteComponent from './AutoCompleteComponent';
import { Button } from 'primereact/button';
import { showSuccess, showWarning, showError } from "./toasts.js";
import { Toast } from 'primereact/toast';
const { REACT_APP_URL } = process.env;

const AddPlayersToRounds = ({ roundNum, teamOne, teamTwo, teamOneRoundPlayers, setTeamOneRoundPlayers, teamTwoRoundPlayers, setTeamTwoRoundPlayers, selectedTournament }) => {
  const [teamOnePlayers, setTeamOnePlayers] = useState([]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([]);

  const toast = useRef(null);

  const getPlayersFromTeams = async () => {
    const teamOneName = teamOne?.TeamName;
    const teamTwoName = teamTwo?.TeamName;
    const tournament_id = selectedTournament?._id;
    const response = await axios.get(`${REACT_APP_URL}/players/playersFromTeam/${teamOneName}/${teamTwoName}/${tournament_id}`, {
      headers: {
        Authorization: sessionStorage.getItem("accessToken")
      }
    });

    setTeamOnePlayers(response.data.teamOnePlayers);
    setTeamTwoPlayers(response.data.teamTwoPlayers)
  }
  
  const addPlayerToTeamOne = (player) => {
    let counter = 0;
    teamOneRoundPlayers.forEach((player_) => {
      if(player_.name === player.name) {
        counter++;
      }
    })

    if(counter === 0) {
      setTeamOneRoundPlayers([...teamOneRoundPlayers, player])
      showSuccess("Player Chosen", toast)
    }
  }

  const addPlayerToTeamTwo = (player) => {
    let counter = 0;
    teamTwoRoundPlayers.forEach((player_) => {
      if(player_.name === player.name) {
        counter++;
      }
    })

    if(counter === 0) {
      setTeamTwoRoundPlayers([...teamTwoRoundPlayers, player])
      showSuccess("Player Chosen", toast)
    }
  }

  useEffect(() => {
    getPlayersFromTeams()
    setTeamOneRoundPlayers([])
    setTeamTwoRoundPlayers([])
  },[])

  return (
    <>
        <div className="card flex justify-content-center">
          <Toast ref={toast} />
        </div>
        <div className="flex flex-column justify-content-center p-3 gap-2">
            <p className="font-semibold">Round {roundNum + 1}</p>
            <div className="flex justify-content-between align-items-center">
             <p className="font-semibold">{teamOne.TeamName}</p>
             <p className="font-semibold">{teamTwo.TeamName}</p>
            </div>
            <div className="flex justify-content-between align-items-center">
              <div className="border-bottom-1 w-max">
              {teamOnePlayers?.map((player) => {
                return (
                  <p className="cursor-pointer" key={player.id} onClick={() => addPlayerToTeamOne(player)}>{player.name}</p>
                )
              })}
              </div>
              {
                <div className="border-bottom-1 w-max">
                {teamTwoPlayers?.map((player) => {
                  return (
                    <p className="cursor-pointer" key={player.id} onClick={() => addPlayerToTeamTwo(player)}>{player.name}</p>
                  )
                })}
                </div>
              }
            </div>
        </div>
    </>
  )
}

export default AddPlayersToRounds
