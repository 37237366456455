import React,{useState} from 'react'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import axios from "axios";
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
const { REACT_APP_URL } = process.env;
const AddTournamentGroups = ({AddGroup,showWarn,showError,showSuccess, successes, setSuccesses, currentIndex}) => {
   
    const [err,setErr]=useState(false);
    
    const options1 = ['Team', 'Individual'];
    const options2=['Round Robin','Single Elimination']
    const options3 = ["League", "Regular"]
    const options4 = ["Doubles", "More than two members"]
    
    const [tournament,setTournament]=useState({
        name: "",
        isAgeGroup:true,
        fromDate:null,
        toDate:null,
        winPoint:0,
        lossPoint:0,
        individual: false,
        format: options2[0],
    });

    const [league, setLeague] = useState(options3[0]);
    const [doubles, setDoubles] = useState(options4[0]);

    const HandleSubmit= async(e)=>
    {
        
        e.preventDefault();
        let errorMsg="";
        
        if(err){
           showError(errorMsg);
        }
        else{
            try{
                const reply= await axios.post(`${REACT_APP_URL}/tournaments/AddAgeGroup`,{...tournament, regular: league === "Regular" ? true: false, doubles: doubles === "Doubles" ? true : false});
                if(reply.data.message==="successfull"){
                    let array = successes.slice();
                    array[currentIndex] = ["success"];
                    setSuccesses(array);
                    showSuccess("Age group added successfully")
                    AddGroup(reply.data.insertedId,`${tournament.name}`);
                }
                else{
                    throw(reply.data);
                }
            }catch(err){
                showError(err);
            }
        }
       
        

    }
  return (

    <div className='flex flex-column gap-4 '>
            <div className='flex flex-row gap-4 justify-content-center'>
                <InputText placeholder="Name" value={tournament.name} onChange={(e) => {
                    setTournament((previousValue) => {
                        return {...previousValue, name: e.target.value}
                    })
                }}  />
            </div>
            <div className='flex flex-row gap-4 justify-content-center'>
                <div className='flex flex-column gap-3 w-15rem'>
                    <label>Win Points </label>
                    <InputNumber onChange={e=>setTournament(prev=>({...prev,winPoint:e.value}))}/>
                
                </div>
                <div className='flex flex-column gap-3 w-15rem'>
                    <label>Loose Points</label>
                    <InputNumber onChange={e=>setTournament(prev=>({...prev,lossPoint:e.value}))}/>
                </div>
            </div>
            <div className="flex gap-5 justify-content-center">
                <div className="flex flex-column gap-3 w-15rem">
                    <label>From</label>
                    <Calendar value={tournament.fromDate} onChange={(e) => setTournament(prevState => ({ ...prevState, fromDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />
                </div>
                <div className="flex flex-column gap-3 w-15rem">
                    <label>To</label>
                    <Calendar value={tournament.toDate} onChange={(e) => setTournament(prevState => ({ ...prevState, toDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />                   
                </div>
            </div>
            <div className="flex flex-column align-items-center gap-3">
                                    <label>Select Tournament Type</label>
                                    <SelectButton value={tournament.individual} onChange={(e) => setTournament(prev=>({...prev,individual:e.value}))} options={options1} />
                                </div>
                                <div className="flex flex-column align-items-center gap-3 ">
                                    <label>Select Tournament format</label>
                                    <SelectButton value={tournament.format} onChange={(e) => setTournament(prev=>({...prev,format:e.value}))} options={options2} />
                                </div>
                                <div className="flex flex-column align-items-center gap-3">
                                        <label>Select League/Regular</label>
                                        <SelectButton value={league} onChange={(e) => setLeague(e.value)} options={options3} />
                                </div>
                                {tournament.individual === "Team" && (
                                    <div className="flex flex-column align-items-center gap-3">
                                        <label>Select Number of Members</label>
                                        <SelectButton value={doubles} onChange={(e) => setDoubles(e.value)} options={options4} />
                                    </div>
                                )}
            <Button label='Submit' onClick={e=>HandleSubmit(e)}/>
                            
     </div>

  
  )
}

export default AddTournamentGroups