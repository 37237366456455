import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LOGO from "../../Assets/dashboard/deccanChargers.png"
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import ChangeCaptain from './ChangeCaptain';
import AddPlayerForm from './AddPlayerForm';
import AddPlayerToTeamWithoutMessaging from './AddPlayerToTeamWithoutMessaging';
import RemovePlayer from './RemovePlayer';
import DialogBox from './DialogBox';
const { REACT_APP_URL } = process.env;

const AddPlayerToTeam = ({toast,name,leaderId,showWarn}) => {
        const [team, setTeam] = useState({});
        const [selectedPlayer, setSelectedPlayer] = useState(null);
        const [players, setPlayers] = useState([]);
        const [visible, setVisible] = useState(false);
        const [Edit ,setEdit]=useState(false);
        const [Captain  ,setCaptain]=useState(false);
        const [openAddPlayerToTeamWithoutMessaging, setOpenAddPlayerToTeamWithoutMessaging] = useState(false);
        const [IsLeader,setIsleader]=useState('false');
     
        const getPlayersAndTeam = async () => {
           
            const response = await axios.get(`${REACT_APP_URL}/teams/playersAndTeam/${name}/${leaderId}`);
            console.log(response.data);
            setTeam(response.data.team);
            setPlayers(response.data.players_array);
            let l=sessionStorage.getItem('leader')
            setIsleader(l);
            
        }
       
        
   
        useEffect(() => {
            getPlayersAndTeam();

        },[])

        return (
            <>
                <div className="flex flex-column justify-content-center w-full ">
                   <div className='flex  flex-column align-items-center p-5'>
                    {
                        team && players &&
                        <>
                            <div className='flex flex-column gap-1 align-items-center'>
                                <div style={{height:'20vh',borderRadius:'50%',width:'300px'}} className='bg-bluegray-800 flex justify-content-center align-items-center flex-column ' >
                                    <img src={LOGO} alt='TeamLogo' style={{height:'15vh'}}/>
                                </div>
                                <h2 className='text-center '>{team?.TeamName}</h2>
                            </div>

                            <div style={{width:'420px'}} >
                                <div className='flex justify-content-between align-items-center'>
                                    <h2>Members:</h2>
                                    {
                                        IsLeader &&
                                        <>
                                            <Button label='edit Team' className='h-2rem' onClick={()=>setEdit(true)}/>
                                            <Button label='change captain' className='h-2rem' onClick={()=>setCaptain(true)}/>
                                        </>


                                    }
                                </div>
                                <p className='flex justify-content-between border-bottom-1 px-2'>{team?.TeamLeader?.name}<Badge value='C'></Badge></p>
                                {
                                    team.Members?.map((member) => {
                                        if(member?.name !== team?.TeamLeader?.name) {
                                            return(
                                                <>
                                                    <p className='border-bottom-2 pb-1 px-2 flex justify-content-between align-items-center'key={member?.id}>
                                                        {member?.name}                                                         
                                                     </p>                     
                                                </>
                                            )
                                        }
                                })
                                }

                                    <div className='flex gap-5'>
                                        <Button  label='Send Request'onClick={() => setVisible(true)}/>
                                        <Button  label="Add Player" onClick={() => setOpenAddPlayerToTeamWithoutMessaging(true)}/>
                                    </div>
                                
                                <DialogBox header={'Add plyer'} visible={visible} state={setVisible} width={'20vw'} Componenet={AddPlayerForm} toast={toast} team={team}/>  
                                <DialogBox header={'Add PLayer Directly'} visible={openAddPlayerToTeamWithoutMessaging} state={setOpenAddPlayerToTeamWithoutMessaging} width={"50vw"} leader={team.TeamLeader} team={team} Componenet={AddPlayerToTeamWithoutMessaging} toast={toast}/>  
                                <DialogBox header={'Remover Player'} visible={Edit} state={setEdit} width={"20vw"} leader={team.TeamLeader} team={team} Componenet={RemovePlayer} toast={toast}  />  
                                <DialogBox header={'Change Captain'} visible={Captain} state={setCaptain} width={"20vw"} leader={team.TeamLeader} team={team} Componenet={ChangeCaptain} toast={toast}  />  
            

                    
                           
                            </div>
                        </>
                    }
                       
                   </div>
                </div>
            </>
        )
    }

export default AddPlayerToTeam