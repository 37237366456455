import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { ArrowLeft, Check, Menu } from 'lucide-react';
import { X } from 'lucide-react';
import AddTeam from './AddTeam';
import axios from 'axios';
import { SpeedDial } from 'primereact/speeddial';
import { Dialog } from 'primereact/dialog';
import Requests from './Dashboard/Requests';
import Notifications from './Dashboard/Notifications'
import Navbar from './Navbar';
import JoinTeam from './Dashboard/JoinTeam';
import AddPlayerToTeam from './Dashboard/AddPlayerToTeam';
import InputCsvFile from './Dashboard/InputCsvFile';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { showSuccess,showWarning,showError } from './toasts';
const { REACT_APP_URL } = process.env;

const Dashboard = (toast) => {
    const [user, setUser] = useState({});
    const [name, setName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [leader, setLeader] = useState(false);
    const [leaderId, setLeaderId] = useState(false);
    const [openAddTeamModal, setOpenAddTeamModal] = useState(false);
    const [openJoinTeamModal, setOpenJoinTeamModal] = useState(false);
    const [openAddPlayersModal, setOpenAddPlayersModal] = useState(false);
    const [openCsvModal, setOpenCsvModal] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [notifications, setNotifications]=useState(0);
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    const OpenNavbar = () => {
        setShowNavbar(!showNavbar);
    }

    const displayCsvModal = () => {
        setOpenCsvModal(true);
    }

    const logout = () => {
        sessionStorage.setItem("Logout", "true");
        navigate("/");
    }

 

    const playerOptions = [
        {
            label: "Add",
            icon: "pi pi-plus",
            command: () => setOpenAddTeamModal(true)
        },
        {
            label: "Join Team",
            icon: "pi pi-user-plus",
            command: () => setOpenJoinTeamModal(true)
        },
        {
            label: "Team Details",
            icon: "pi pi-users",
            command: () => setOpenAddPlayersModal(true)
        },
        {
            label: "Logout",
            icon: "pi pi-power-off",
            command: () => logout()
        }
    ]

    const organizerOptions = [
        {
            label: "Logout",
            icon: "pi pi-power-off",
            command: () => logout()
        }
    ]

    const dashboardClassName = "dashboard-input border-none border-300 text-lg text-white font-light text-600";
    const getDahshboardInfo = async () => {
        setLoading(true);
        const response = await axios.get(`${REACT_APP_URL}/dashboard/dashboardInfo`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })
        setUser(response.data);
        setName(response.data.name);
        setPhoneNo(response.data.phone_no);
        setEmail(response.data.email);
        setAge(response.data.age);
        setGender(response.data.gender);
        if(response.data.type === "player") {
            setLeader(response.data.leader);
            setLeaderId(response.data._id);
        }
        setLoading(false);
    }

    const registerAsALeader = async (leaderOption_) => {
        const response = await axios.post(`${REACT_APP_URL}/dashboard/registerAsTeamLeader`, {
            player_email: email,
            leaderOption: leaderOption_
        });

        if(response.data.message === "Registered As A Leader") {
            showSuccess(response.data.message,toast);
        }
        else if(response.data.message === "Please Try Again") {
            showError(response.data.message,toast);
        }
    }


    useEffect(() => {
        getDahshboardInfo();
    },[])

  return (
    <>
    {showNavbar && (<Navbar setShowNavbar={setShowNavbar} />)}
    <div className="card flex justify-content-center">
      
    </div>
    {user.type === "player" && user.leader === true && (
        <>
            <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
            <SpeedDial model={playerOptions} direction="up" className="speeddial-bottom-right absolute bottom-0 right-0 m-4" />
        </>
    )}
    {user.type === "organizer" && (
        <>
            <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
            <SpeedDial model={organizerOptions} direction="up" className="speeddial-bottom-right absolute bottom-0 right-0 m-4" />
        </>
    )}
    <div className="container overflow-auto flex flex-column justify-content-start align-items-center pt-5 pb-5 gap-8">
        <div className='login-title flex justify-content-center align-items-center gap-7'>
            <ArrowLeft color="#fff" className="cursor-pointer" onClick={() => {
                navigate("/showTournaments");
            }} />
            <h4 className='text-white text-lg md:text-primary md:text-2xl font-normal'>Matchpointandgames.com</h4>
            <div className="hidden lg:block">
                <Requests user={email}/>
            </div>
            <div className="hidden lg:block">
                <Notifications user={email}/>
            </div>
        </div>
        {loading && (
            <i className="pi pi-spin pi-spinner mt-5" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
        )}
        {!loading && (
            <>
            <div className="tournament-details-card dashboard-grid gap-6 border-bottom-1 border-300 p-6">
                <Avatar label={user.firstAlphabet} size="large" className="bg-primary" />
                <input className={dashboardClassName} type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                <input className={dashboardClassName} type="text" placeholder="Phone No" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)}  />
                <input className={dashboardClassName} type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                <input className={dashboardClassName} type="text" placeholder="Age" value={age} onChange={(e) => setAge(e.target.value)}  />
                <input className={dashboardClassName} type="text" placeholder="Gender" value={gender} onChange={(e) => setGender(e.target.value)}  />
            </div>
            {user.type === "player" && user.leader === false && (
            <div className="flex justify-content-center align-items-center gap-4">
                <p className="text-lg font-light">Register as a Team Leader</p>
                <Check className="cursor-pointer" color="green" onClick={() => registerAsALeader(true)} />
                <X className="cursor-pointer" color="red" onClick={() => registerAsALeader(false)} />
            </div>
            )}
            </>
        )}
            <div className="flex flex-column justify-content-center align-items-center gap-5">
                <button className="btn-knockout border-round-sm"  onClick={displayCsvModal}>Upload Players CSV</button>
                <button className="btn-knockout border-round-sm"  onClick={displayCsvModal}>Upload Tournament Names CSV</button>
                <button className="btn-knockout border-round-sm"  onClick={displayCsvModal}>Upload Player Points CSV</button>
            </div>
    </div>
    {/* Modals */}
        <Dialog
            style={{ width: "50vw" }}
            visible={openAddTeamModal}
            onHide={() => setOpenAddTeamModal(false)}
        >
            <AddTeam tournaments={user.tournaments} leader={{ name: name, id: leaderId }} toast={toast} />
        </Dialog>
        <Dialog
            style={{ width: "50vw" }}
            visible={openJoinTeamModal}
            onHide={() => setOpenJoinTeamModal(false)}
        >
            <JoinTeam name={name} leaderId={leaderId} toast={toast} />
        </Dialog>
        <Dialog
            style={{ width: "30vw" }}
            visible={openAddPlayersModal}
            onHide={() => setOpenAddPlayersModal(false)}
        >
            <AddPlayerToTeam  name={name} leaderId={leaderId} toast={toast}/>
        </Dialog>
        <Dialog
            style={{ width: "30vw" }}
            visible={openCsvModal}
            onHide={() => setOpenCsvModal(false)}
        >
            <InputCsvFile />
        </Dialog>
    </>
  )
}

export default Dashboard
