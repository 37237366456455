import { PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import PDFRegularKnockout from './PDFRegularKnockout'
import { useLocation } from 'react-router-dom'

const PDFRegularViewer = () => {
    const location = useLocation();

    return (
        <PDFViewer width="100%" height={800}>
            <PDFRegularKnockout internalTournament={location.state.internalTournament} tournament={location.state.tournament} />
        </PDFViewer>
    )
}

export default PDFRegularViewer
