import React, { useEffect, useRef, useState } from "react";
// import Header from "./Header";
// import Footer from "./Footer";
// import { DataView } from "primereact/dataview";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
// import { Divider } from "primereact/divider";
import { Column } from "primereact/column";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import TeamLogo from "../Assets/TeamLogo.png";
import givePermissions from "../permissions";
import {showSuccess,showError,showWarning} from './toasts.js';
import TeamInstructionModal from "./Instruction_Modals/TeamInstructionModal";
import Team from "./Team";
import * as Realm from "realm-web";
import AddPlayersToTeam from "./AddPlayersToTeam.js";
import { Edit, Search, X } from "lucide-react";
import TournamentLogo from "../Assets/TournamentLogo.png";
import { Avatar } from "@mui/material";
import { orange } from "@mui/material/colors";
import RefreshIcon from '@mui/icons-material/Refresh';
const { REACT_APP_URL } = process.env;

const app = new Realm.App({ id: "application-0-llvit" })

const ShowTeams = ({ toast, eventOccured, visibleAdd, setVisibleAdd, teamInstructionModalOpen, setTeamInstructionModalOpen, setOpenAddTeamsToTournament, ageGroupChanged, selectedTournament_, ageTournament }) => {
  const [teams, setTeams] = useState([]);
  const [searchTeams, setSearchTeams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [seededTeams, setSeededTeams] = useState([]);
  const [nonSeededTeams, setNonSeededTeams] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(selectedTournament_);
  const [selectedDrawOption, setSelectedDrawOption] = useState(null);
  const [noOfGroups, setNoOfGroups] = useState(0);
  const [visible, setVisible] = useState(false);
  const [permissions, setPermissions] = useState(false);
  
  const [groupNumber, setGroupNumber] = useState("");
  const [team, setTeam] = useState({});
  const [showTeam, setShowTeam] = useState(false);

  const [teamEventOccured, setTeamEventOccured] = useState(false);
  const [tournamentEventOccured, setTournamentEventOccured] = useState(false);
  const [openAddPlayersToTeam, setOpenAddPlayersToTeam] = useState(false);

  const update_teams=(team)=>
  {
    setTeams([...teams,team]);
  }

  const drawOptions = ["Random", "Alphabetical", "Order of Registration"];

  const customBase64Uploader = async (event) => {
      // convert file to base64 encoded
      const file = event.files[0];
      const reader = new FileReader();
      let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
      console.log(blob);

      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data = reader.result;
      };
    };
 
  const fetchAllTeams = async () => {
    if (selectedTournament !== null) {
      const tournament = JSON.parse(sessionStorage.getItem("tournament"));
      let response = await axios.get(
        `${REACT_APP_URL}/teams/allTeams/${tournament?._id}`
      );
      response.data.forEach((team) => {
        team["selected"] = false;
        team["seeded"] = false;
      })
      setTeams(response.data);
      setSearchTeams(response.data);
    }
  };

  const proceedToDraw = (teams) => {
    teams.forEach((team) => {
      if(team.selected) {
        seededTeams.push(team);
        team.selected = false;
      }
      else if(!team.selected) {
        nonSeededTeams.push(team);
      }
    })
    if (seededTeams.length + nonSeededTeams.length === teams.length) {
      setVisible(true);
    } else if (seededTeams.length + nonSeededTeams.length !== teams.length) {
      showWarning("Please select all the Teams",toast);
    }
  };

  const confirmDraw = async (seededTeams, nonSeededTeams, noOfGroups) => {
    seededTeams.sort((t1, t2) => {
      return parseInt(t1.seedNumber) - parseInt(t2.seedNumber);
    })
      let response = await axios.post(
        `${REACT_APP_URL}/all/divideTeamsInGroups`,
        {
          tournament_id: selectedTournament._id,
          seeded_teams: seededTeams,
          non_seeded_teams: nonSeededTeams,
          number_of_groups: noOfGroups,
          type_of_draw: selectedDrawOption,
          format: selectedTournament?.format
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      // setSeededTeams([]);
      // setNonSeededTeams([]);
      if(response.data.message === "Draw Successful") {
        showSuccess(response.data.message, toast);
        setVisible(false);
        setSeededTeams([]);
        setNonSeededTeams([]);
      }
      else {
        showError(response.data.message, toast);
      }
      setSelectedTournament(JSON.parse(sessionStorage.getItem("tournament")));
  };



  const AddTeam = () => {
    const [leaders, setLeaders] = useState([]);
    const [selectedLeader, setSelectedLeader] = useState(null);

    const [nameError, setNameError] = useState(false);
    const [errorLogo, setErrorLogo] = useState(false);
    const [errorLeader, setErrorLeader] = useState(false);

    const [name, setName] = useState("");
    const [club, setClub] = useState("");
    const [logo, setLogo] = useState("");
    const [city, setCity] = useState("");

    const fetchAllLeaders = async () => {
      let response = await axios.get(
        `${REACT_APP_URL}/players/fetchAllLeaders`
      );
      response.data.forEach((player) => {
        let counter = 0;
        teams.forEach((team) => {
          if (team.TeamLeader.name !== player.name) counter++;
        });
        if (counter === teams.length) setLeaders([...leaders, player]);
      });
    };

    const addTeam = async () => {
     
      if (name === "") setNameError(true);
      if (name !== "") {
        let response = await axios.post(
          `${REACT_APP_URL}/teams/createTeam`,
          {
            TeamName: name,
            logo: logo,
            club: club,
            city: city
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        setErrorLogo(false);
        setErrorLeader(false);
        if(response.data.message==='Teams Added'){
          showSuccess(response.data.message,toast);
          setVisibleAdd(false);
        }
        else {
          showError(response.data.message, toast);
        }
      }
    };

    useEffect(() => {
      fetchAllLeaders();
    }, []);

    const customBase64Uploader = async (event) => {
     
      const file = event.files[0];
      const formData=new FormData();
      formData.append('image',file);
      let response=await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
       });
       console.log(response);
       if(response.data.msg==="Image Uploded Successfilly"){
        setLogo(response.data.url);
       }
       else{
        showWarning(response.data,toast)
       }

    };


    return (
      <div style={{ fontFamily: "'Inter', sans-serif" }}>
        <div className="grid">
          <div className="col flex flex-column gap-3">
            <label>Name</label>
            <div>
              <InputText
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && (
                <p className="error-message">Name should not be empty</p>
              )}
            </div>
          </div>
          <div className="col flex flex-column gap-3">
            <label>Club</label>
            <InputText
              style={{ fontFamily: "'Inter', sans-serif" }}
              placeholder="Club"
              value={club}
              onChange={(e) => setClub(e.target.value)}
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column gap-3">
            <label>Logo</label>
            <div>
              <FileUpload
                style={{ fontFamily: "'Inter', sans-serif" }}
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                customUpload
                uploadHandler={customBase64Uploader}
              />
              {errorLogo && <p className="error-message">No file chosen</p>}
            </div>
          </div>
          <div className="col flex flex-column gap-3">
            <label>City</label>
            <InputText
              style={{ fontFamily: "'Inter', sans-serif" }}
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column">
            <Button
              style={{ fontFamily: "'Inter', sans-serif" }}
              label="Add Team"
              onClick={addTeam}
            />
          </div>
        </div>
      </div>
    );
  };

  const TeamGridItem = ({ team_ }) => {
    const [seeded, setSeeded] = useState(team_.seeded);
    const op = useRef(null);
    const [teamItem, setTeamItem] = useState(team_);
    const [teamName, setTeamName] = useState(team_.TeamName);
    const [seedNumber, setSeedNumber] = useState("");
    const [logo, setLogo] = useState(null);

    const handleLogoChange = async (e) => {
      const data = new FormData();
      data.append("logo", e.target.files[0]);
      const response = await axios.post(`${REACT_APP_URL}/teams/uploadTeamLogoAndGetUrl`, data, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })
      
      const url = response.data;
      const responseTwo = await axios.post(`${REACT_APP_URL}/teams/uploadTeamLogo`, {
        team_id: team_?._id,
        team_logo: url
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(responseTwo.data.message === "Logo uploaded") {
        showSuccess(responseTwo.data.message, toast);
      }
      else {
        showError(responseTwo.data.message, toast);
      }
    }

    const getCurrentTeamPlayers = async () => {
      const response = await axios.get(`${REACT_APP_URL}/teams/teamForApp/${team_.TeamName}/${selectedTournament?._id}`, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      });

      setTeamItem(response.data);
    }

    const editTeam = async () => {
      const response = await axios.post(`${REACT_APP_URL}/teams/editTeam`, {
        teamName: teamName,
        club: team.club,
        tournament_id: selectedTournament?._id,
        team_id: team._id
      },{
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(response.data.message !== "Team Edited") {
        showError(response.data.message, toast)
      }
    }

    useEffect(() => {
      getCurrentTeamPlayers();
    },[])

    return (
      <div className="border-1 border-gray-300 border-round p-5 bg-white">
        <div className="flex justify-content-between align-items-center">
        <div className="flex justify-content-center align-items-center gap-3">
            <input
            type="file"
            multiple={false}
            style={{ fontFamily: "'Inter', sans-serif" }}
            className="outline-none border-none"
            onChange={handleLogoChange}
            />
          </div>
          {(!teamItem?.logo || teamItem?.logo === "") && (
            <>
          {selectedTournament?.doubles && (
              <Avatar sx={{
                bgcolor: "#FF8734"
              }}>
              {teamItem?.TeamName?.split("&")?.map((item, index) => {
                if(index === 0) {
                  return `${item[0]}`
                }
                else {
                  return `${item[1]}`
                }
              })}
              </Avatar>
            )}
          {!selectedTournament?.doubles && (
            <Avatar sx={{
              bgcolor: "#FF8734"
            }}>
            {teamItem?.TeamName?.split(" ")?.map((item) => {
              return `${item[0]}`
            })}
            </Avatar>
          )}
            </>
            )}
            {(teamItem?.logo && teamItem?.logo !== "") && (
            <Avatar src={teamItem?.logo} sx={{
              width: 56,
              height: 56
            }} />
            )}
        </div>
        <div className="flex justify-content-between align-items-center mt-5">
          <div className="flex justify-content-start align-items-center">
            <input style={{ fontFamily: "'Inter', sans-serif", fontSize: "1rem", }} className="border-none max-w-9rem" value={teamName} onChange={(e) => setTeamName(e.target.value)} disabled={!permissions} />
            {permissions && (
              <Edit className="cursor-pointer" color="gray" size={18} onClick={editTeam} />
            )}
          </div>
          <div>
            {teamItem?.TeamLeader?.name && (<p>{teamItem?.TeamLeader?.name}(C)</p>)}
            {!teamItem?.TeamLeader?.name && (<p>Leader not decided</p>)}
          </div>
        </div>
        <div className="flex justify-content-between align-items-center mt-4">
          <div>
            <button
              className="btn-header"
              style={{ backgroundColor: "white" }}
              onClick={() => { 
                setOpenAddPlayersToTeam(true);
                setTeam(teamItem);
              }}
            >
              Add Members
            </button>
          </div>
            <p className="btn-header"
              style={{ backgroundColor: "white" }} onClick={(e) => op.current.toggle(e)}>Existing Members</p>
            <OverlayPanel ref={op}>
              <DataTable value={teamItem.Members} rows={teamItem?.Members?.length}>
                <Column
                  field="name"
                  header="Name"
                  sortable
                  style={{ minWidth: "12rem" }}
                />
              </DataTable>
            </OverlayPanel>
        </div>
        {permissions && (
          <>
            <div className="flex justify-content-between align-items-center mt-6">
                <div className="input-switch flex justify-content-center align-items-center gap-2">
                  <p>Seeded</p>
                  <div>
                    <InputSwitch
                      checked={seeded}
                      onChange={(e) => {
                        setSeeded(e.value);
                        teamItem.selected = e.value
                        team_.selected = e.value;
                        teams.forEach((team_item) => {
                          if(team_item._id === teamItem._id) {
                            team_item.seeded = e.value;
                          }
                        })
                      }}
                      />
                  </div>
                </div>
                <InputText style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Seed Number" value={seedNumber} onChange={(e) => {
                              setSeedNumber(e.target.value);
                              teamItem["seedNumber"] = e.target.value;
                              team_["seedNumber"] = e.target.value;
                              teams.forEach((team_item) => {
                                if(team_item._id === teamItem._id) {
                                  team_item.seedNumber = e.target.value;
                                }
                              })
                            }} />
            </div>
          </>
        )}
      </div>
    )
  }

  const TeamsGrid = () => {
    return (
      <>
        <div className="teams-grid m-6 gap-6 mb-8">
          {searchTeams.map((team, index) => {
            return (
              <TeamGridItem team_={team} key={index} />
            )
          })}
        </div>
      </>
    )
  }

  const Groups = () => {
    const [groups, setGroups] = useState([]);
    const [selectionColor, setSelectionColor] = useState("red");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [remainingTeams, setRemainingTeams] = useState([]);
    const [removeTeam, setRemoveTeam] = useState(false);
    const [moveTeam, setMoveTeam] = useState(false);
    const [dropBoolean, setDropBoolean] = useState(false);

    const getRemainingTeams = async () => {
      const response = await axios.get(
        `${REACT_APP_URL}/teams/remainingTeams/${selectedTournament?._id}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );
      setRemainingTeams(response.data);
    };

    const addRemainingTeamToGroup = async (group_number) => {
      if (selectedTeam === null) {
        setGroups([...groups, group_number]);
      } else if (selectedTeam !== null) {
        const response = await axios.post(
          `${REACT_APP_URL}/teams/addRemainingTeamToAGroup`,
          {
            group_number: group_number,
            team_name: selectedTeam.TeamName,
            tournament_name: selectedTournament.name,
            tournament_id: selectedTournament._id
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        setGroups([]);
        if(response.data.message === "Team was added to the Group") {
          showSuccess(response.data.message,toast);
        }
        else {
          showError(response.data.message,toast);
        }
      }
    };

    const Group = ({ group }) => {
      const moveTeam = async (e) => {
        e.preventDefault();
        let data = JSON.parse(e.dataTransfer.getData("teamName"))

        const response = await axios.post(`${REACT_APP_URL}/teams/moveTeam`, {
          current_group_number: data.current_group_number,
          specified_group_number: e.target.id,
          team_name: data.team_name,
          tournament_name: selectedTournament.name,
          tournament_id: selectedTournament._id
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })

        if(response.data.message === "The Team has been shifted") {
          showSuccess(response.data.message,toast);
        }
        else {
          showError(response.data.message,toast);
        }
      }

      const displayOrRemoveTeam = async (team, group) => {
        if(!removeTeam) {
          setGroupNumber(group.group_number);
          setTeam(team)
          setShowTeam(true)
        }
        else if(removeTeam) {
          const response = await axios.post(`${REACT_APP_URL}/teams/removeTeam`, {
            group_number: group.group_number,
            team_name: team.TeamName,
            tournament_name: selectedTournament.name,
            tournament_id: selectedTournament._id
          }, {
            headers: {
              Authorization: sessionStorage.getItem("accessToken")
            }
          })

          if(response.data.message === "Team was removed successfully") {
            showSuccess(response.data.message,toast);
          }
          else {
            showError(response.data.message,toast);
          }
        }
      }
      return (
        <>
          <p className="groups-header flex justify-content-between align-items-center">
            <button
              className="outer-btn font-light text-white"
              onClick={() => addRemainingTeamToGroup(group.group_number)}
            >
              {group.group_number}
            </button>
          </p>
          <div key={group.group_number}>
            <table className="" style={{ backgroundColor: "#2b2b2b", width: "40rem" }} onDrop={moveTeam} onDragOver={(e) => {
              e.preventDefault();
            }}>
              <tbody>
              {permissions && (
                <tr style={{ backgroundColor: "#fff" }}><th id={group.group_number} style={{ padding: "15px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>Drop Here</th></tr>
              )}
              <tr style={{ backgroundColor: "#FF8734", color: "#fff" }}><th style={{ padding: "15px" }}>Teams</th></tr>
              {group.teams?.map((team, index) => {
                return (
                  <tr id={JSON.stringify({ current_group_number: group.group_number, team_name: team.TeamName })} style={{ backgroundColor: "#fff" }} className="cursor-pointer" key={index} draggable onDragStart={(e) => {
                    e.dataTransfer.setData("teamName", e.target.id);
                  }} onClick={() => displayOrRemoveTeam(team, group)}>
                    <td style={{ padding: "15px", border: team.seeded ? "7px solid #ffa869" : "none", borderBottomLeftRadius: index === group?.teams?.length - 1 ? "10px": "0px", borderBottomRightRadius: index === group?.teams?.length - 1 ? "10px": "0px" }}>{team.TeamName}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </>
      );
    };

    useEffect(() => {
      getRemainingTeams();
    }, [teamEventOccured, tournamentEventOccured, sessionStorage.getItem("tournament")]);

    return (
      <>
        {selectedTournament?.individual && (
          <div className="flex justify-content-center align-items-center h-screen">
            <p className="text-white text-lg">
              This is an Individuals Tournament
            </p>
          </div>
        )}
        {!selectedTournament?.individual && (
          <>
            {permissions && (
              <div className="hidden teams-top tournament-details-card sm:flex sm:justify-content-center sm:align-items-center sm:gap-4 sm:px-5 sm:text-gray-800">
                <div className="flex justify-content-center align-items-center gap-3">
                  <p className="text-white">Remove Teams</p>
                  <InputSwitch
                    checked={removeTeam}
                    onChange={(e) => setRemoveTeam(e.value)}
                  />
                </div>
                <div className="flex justify-content-center align-items-center gap-3">
                  <p className="text-white">Remaining Teams</p>
                  <Dropdown
                    panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                    style={{ border: "1px solid #cacaca", borderRadius: "5px" }}
                    value={selectedTeam}
                    onChange={(e) => setSelectedTeam(e.target.value)}
                    options={remainingTeams}
                    optionLabel="TeamName"
                    placeholder="Select a Team"
                  />
                </div>
              </div>
            )}
            <div className="groups-grid gap-6 p-4 mb-8">
              {selectedTournament?.groups
                ?.sort((group1, group2) => {
                  return (
                    parseInt(
                      group1.group_number[group1.group_number.length - 1]
                    ) -
                    parseInt(
                      group2.group_number[group2.group_number.length - 1]
                    )
                  );
                })
                .map((group) => {
                  return (
                    <div key={group.group_number}>
                      <Group group={group} />
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {/* Dialogs */}
      </>
    );
  };

  const searchForATeam = () => {
    let filterArray = teams?.filter((team) => {
      return team.TeamName.toLowerCase().includes(searchQuery.toLowerCase());
    })

    setSearching(true);
    setSearchTeams(filterArray);
  }

  const cancelSearch = () => {
    setSearching(false);
    setSearchTeams(teams);
    setSearchQuery("");
  }

  useEffect(() => {
    const teamLogin = async () => {
      await app.logIn(Realm.Credentials.anonymous());
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const teamCollection = mongodb.db("tournament_manager_database").collection("Teams");

      for await (const change of teamCollection.watch()) {
        setTeamEventOccured(!teamEventOccured);
      }
    }

    teamLogin();
  },[teamEventOccured])

  useEffect(() => {
    const tournamentLogin = async () => {
      await app.logIn(Realm.Credentials.anonymous());
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const tournamentCollection = mongodb.db("tournament_manager_database").collection("Tournaments");

      for await (const change of tournamentCollection.watch()) {
        sessionStorage.setItem("tournament", JSON.stringify(change.fullDocument));
        setSelectedTournament(change.fullDocument);
        setTournamentEventOccured(!tournamentEventOccured);
      }
    }

    tournamentLogin()
  },[tournamentEventOccured])

  useEffect(() => {
    givePermissions(setPermissions);
  }, []);

  useEffect(() => {
    fetchAllTeams();
    setSelectedTournament(JSON.parse(sessionStorage.getItem("tournament")));
  },[selectedTournament_, teamEventOccured])

  useEffect(() => {
    if(JSON.parse(sessionStorage.getItem("tournament")).ageGroups || JSON.parse(sessionStorage.getItem("tournament")).isAgeGroup) {
      setSelectedTournament(ageTournament);
      fetchAllTeams();
    }
  },[ageGroupChanged, selectedTournament, teamEventOccured])

  return (
    <div className="container overflow-auto">
      {selectedTournament === null ? (
        <div style={{ color: "#757575" }} className="h-screen relative">
          <div
            style={{ marginTop: "25rem" }}
            className="flex justify-content-center align-items-center"
          >
            <p className="text-white">
              Please Select A Tournament OR Create a new one
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-content-center align-items-center py-5 border-bottom-1 border-600" style={{ margin: "6rem", marginBottom: "2rem" }}>
            <div className="flex justify-content-center align-items-center gap-5">
              <p className="text-lg text-white">Teams</p>
              <div className="flex justify-content-center align-items-center gap-2 px-3 py-1 bg-primary border-round-sm cursor-pointer" onClick={fetchAllTeams}>
                      <p className="text-white">Refresh</p>
                      <RefreshIcon sx={{ color: "white" }} />
                    </div>
            </div>
            </div>
          {selectedTournament?.groups?.length === 0 ? (
            <>
              <div className="flex justify-content-center align-items-center gap-3">
                    <InputText
                      style={{ fontFamily: "'Inter', sans-serif" }}
                      placeholder="Search for a Team..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {!searching && (
                      <Search className="cursor-pointer" color="white" onClick={searchForATeam} />
                    )}
                    {searching && (
                      <X className="cursor-pointer" color="white" onClick={cancelSearch} />
                    )}
                  </div>
              <div className="m-3">
                <div>
                  <TeamsGrid />
                </div>
                {permissions && teams.length !== 0 && (
                  <div className="flex justify-content-center align-items-center m-3 mb-8">
                    <button
                      className="btn-tournament m-3"
                      onClick={() =>
                        proceedToDraw(teams)
                      }
                    >
                      Proceed to Draw
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-column justify-content-center align-items-center">
              <Groups />
            </div>
          )}
          {/* Modals */}
          <Dialog
            style={{ width: "50vw" }}
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <div className="flex flex-column gap-3">
              <div
                style={{ fontFamily: "'Inter', sans-serif" }}
                className="flex flex-column gap-3"
              >
                <label>Number of Groups</label>
                <InputNumber
                  value={noOfGroups}
                  onChange={(e) => setNoOfGroups(e.value)}
                />
              </div>
              <Dropdown
                panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                style={{ border: "1px solid #cacaca", borderRadius: "5px" }}
                value={selectedDrawOption}
                onChange={(e) => setSelectedDrawOption(e.target.value)}
                options={drawOptions}
                placeholder="Select a Method of Draw"
              />
            </div>
            <div className="flex justify-content-end align-items-center mt-5">
              <button
                className="btn-tournament"
                onClick={() =>
                  confirmDraw(seededTeams, nonSeededTeams, noOfGroups)
                }
              >
                Confirm
              </button>
            </div>
          </Dialog>
        </>
      )}
      <Dialog
        style={{ width: "50vw" }}
        visible={visibleAdd}
        onHide={() => setVisibleAdd(false)}
      >
        <AddTeam />
      </Dialog>
      <Dialog
        style={{ width: "50vw" }}
        visible={teamInstructionModalOpen}
        onHide={() => setTeamInstructionModalOpen(false)}
      >
        <TeamInstructionModal setVisibleAdd={setVisibleAdd} setTeamInstructionModalOpen={setTeamInstructionModalOpen} selectedTournament={selectedTournament} setOpenAddTeamsToTournament={setOpenAddTeamsToTournament} />
      </Dialog>
      <Dialog
          style={{ width: "50vw" }}
          visible={showTeam}
          onHide={() => setShowTeam(false)}
        >
          <Team team_={team} setTeam_={setTeam} teams={teams} selectedTournament={selectedTournament} groupNumber={groupNumber} toast={toast} permissions={permissions} customBase64Uploader={customBase64Uploader} TeamLogo={TeamLogo} />
        </Dialog>
      <Dialog visible={openAddPlayersToTeam} onHide={() => setOpenAddPlayersToTeam(false)}style={{ width: "50vw" }}>
        <AddPlayersToTeam tournament={selectedTournament} team={team} />
      </Dialog>
    </div>
  );
};

export default ShowTeams;
