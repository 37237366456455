import React,{useState,useEffect} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { ScrollPanel } from "primereact/scrollpanel"
import axios from 'axios';
import ConfirmButtons from './ConfirmButtons.js';
const { REACT_APP_URL } = process.env;

const Requests = ({user}) => {
    const [values,setValues]=useState(0);
    const [visible, setVisible] = useState(false);
    const [allRequest,setallRequest]=useState([]);
   
   const getCount=async()=>
   {
    try{
            const response=await axios.get(`${REACT_APP_URL}/requests/count/${user}`);
            const response2=await axios.get(`${REACT_APP_URL}/requests/allRequests/${user}`);
         
            setallRequest(response2.data);
            setValues(response?.data);
    }
    catch(err){
        console.log(err);
    }

   }
    
  useEffect(()=>{
    getCount();
  },[user]);

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
        <Button type="button" label="Requests" icon="pi pi-users"  onClick={() => setVisible(true)}>
            <Badge value={values}></Badge>
        </Button>

        <Dialog
            header="Requests"
            visible={visible}
            style={{ width: "35vw" ,height:'50vh' }}
            onHide={() => setVisible(false)}
        >
                <div className="flex flex-column">
                    <ScrollPanel
                        style={{ width: "100%" }}
                        className="custombar1"
                    >
                        <div className="flex flex-column gap-4">
                            {allRequest.map((request, index) => (
                                <div
                                key={index}
                                className="border-1 flex flex-column pb-3 gap-3"
                                >   
                                
                                
                                    <h2 className="text-center">
                                        Request From <span>{request.leader_name}</span>
                                    </h2>
                                    <div className="flex justify-content-evenly">
                                        <p>
                                        Description:<span>{request.description}</span>
                                        </p>
                                        <p>
                                        Team Name : <span>{request.team_name}</span>
                                        </p>
                                    </div>
                                    <div>
                                    
                                        <ConfirmButtons key={index}
                                         leaderName={request.leader_name}
                                         teamId={request.team_id}
                                         teamName={request.team_name}
                                         requestId={request._id.toString()}
                                         playerName={request.player_name}
                                         playerId={request.player_id}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                      
                    </ScrollPanel>
                </div>
        </Dialog>
    </div>
  )
}

export default Requests