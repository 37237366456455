import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const KnockoutsView = ({ internalTournament, tournament, permissions }) => {
    const [roundWiseMatches, setRoundWiseMatches] = useState([]);
    const navigate = useNavigate();

    const getRoundWiseMatches = () => {
        let array = [];
        for(let i=0; i<internalTournament?.current_round; i++) {
            array.push({
                roundNo: i+1,
                matches: []
            })
        }

        internalTournament?.matchDetails?.forEach((match, index) => {
            array[match.roundNo - 1]?.matches?.push(match);
        });

        setRoundWiseMatches(array);
    }

    const RoundMatches = ({ matches }) => {
        return (
            <div className="flex flex-column justify-content-center align-items-start gap-5">
                {matches.map((match, index) => {
                        return (
                            <>
                                <DataTable value={[match.firstTeam, match.secondTeam]}>
                                    {!tournament.individual && (
                                        <Column field="TeamName" headerStyle={{ backgroundColor: "#FF8734" }}></Column>
                                    )}
                                    {tournament.individual && (
                                        <Column field="name" headerStyle={{ backgroundColor: "#FF8734" }}></Column>
                                    )}
                                </DataTable>
                            </>
                        )
                })}
            </div>
        )
    }

    const navigateToPdfViewer = () => {
        if(!tournament.individual && !tournament.regular) {
            navigate("/leaguePdf", { state: { internalTournament: internalTournament, tournament: tournament } })
        }
        else if(tournament.individual || tournament.regular) {
            navigate("/regularPdf", { state: { internalTournament: internalTournament, tournament: tournament } })
        }
    }

    useEffect(() => {
        getRoundWiseMatches();
    },[])

    return (
        <>
            {permissions && (
                <div className="flex justify-content-center align-items-center">
                    <button className="btn-knockout border-round-sm" onClick={navigateToPdfViewer}>Export to PDF</button>
                </div>
                )}
            <div className="ml-5 mb-8 flex justify-content-start align-items-center gap-7">
                {roundWiseMatches.map((round) => {
                    return (
                        <div className="flex justify-content-start align-items-center w-max" key={round.roundNo}>
                            <RoundMatches matches={round.matches} />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default KnockoutsView
