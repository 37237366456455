import { PersonStanding } from 'lucide-react'
import React from 'react'

const PlayerInstructionModal = ({ setPlayerInstructionModalOpen, setVisibleAddPlayer, selectedTournament, setOpenAddPlayersToTournament }) => {
    const openCreatePlayerModal = () => {
        setPlayerInstructionModalOpen(false);
        setVisibleAddPlayer(true);
    }

    const closePlayerInstructionsModal = () => {
        setPlayerInstructionModalOpen(false);
    }

  return (
    <div className="flex flex-column justify-content-center gap-3 px-5">
        <div className="flex justify-content-center align-items-center gap-3">
            <PersonStanding />
            <p className="text-lg">Welcome to the Players Page</p>
            {selectedTournament !== null && (
                <p className="text-lg"> | | &nbsp; You just selected a Tournament</p>
            )}
        </div>
        <div className="flex justify-content-between align-items-center mt-2">
            <p className="text-lg cursor-pointer" onClick={openCreatePlayerModal}>Create a Player</p>
            {selectedTournament !== null && (
                <p className="text-lg cursor-pointer" onClick={() => setOpenAddPlayersToTournament(true)}>Add Existing Players to Tournament</p>
            )}
            <p className="text-lg cursor-pointer" onClick={closePlayerInstructionsModal}>Explore</p>
        </div>
    </div>
  )
}

export default PlayerInstructionModal
