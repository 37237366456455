import React, { useRef } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
// import { Rating } from 'primereact/rating';
import dhoni from "../Assets/dhoni.png";
import userImage from "../Assets/UserImage.png";
import Header from "./Header";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import givePermissions from "../permissions";
import { showError,showSuccess,showWarning } from "./toasts";
import PlayerInstructionModal from "./Instruction_Modals/PlayerInstructionModal";
import { Calendar } from "primereact/calendar";
import * as Realm from "realm-web";
import { Search, X } from "lucide-react";
import { Avatar, Rating } from "@mui/material";
import { orange } from "@mui/material/colors";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddRatings from "./AddRatings";
const { REACT_APP_URL } = process.env;
const app = new Realm.App({ id: "application-0-llvit" })

function ShowPlayer({eventOccured, toast, visibleAdd, setVisibleAddPlayer, playerInstructionModalOpen, setPlayerInstructionModalOpen, setOpenAddPlayersToTournament, selectedTournament_, ageGroupChanged, ageTournament }) {
  const [players, setPlayers] = useState([]);
  const [searchPlayers, setSearchPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [layout, setLayout] = useState("grid");
  const [selectedTournament, setSelectedTournament] = useState(selectedTournament_);
  const [seededPlayers, setSeededPlayers] = useState([]);
  const [nonSeededPlayers, setNonSeededPlayers] = useState([]);
  const [noOfGroups, setNoOfGroups] = useState(0);
  const [visible, setVisible] = useState(false);
  const [ratingsVisible, setRatingsVisible] = useState(false);
  const [permissions, setPermissions] = useState(false);

  const [playerEventOccured, setPlayerEventOccured] = useState(false);
  const [tournamentEventOccured, setTournamentEventOccured] = useState(false);

  const fetchAll = async () => {
    try {
      if (selectedTournament !== null) {
        const tournament_ = JSON.parse(sessionStorage.getItem("tournament"));
        const response = await axios.get(
          `${REACT_APP_URL}/players/getPlayers/${tournament_?._id}`
        ,{
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        });
        response.data.forEach((player) => {
          player["selected"] = false;
          player["seeded"] = false;
        })
        setPlayers(response.data);
        setSearchPlayers(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const proceedToDraw = (players) => {
    players.forEach((player) => {
      if(player.seeded) {
        seededPlayers.push(player);
        player.seeded = false;
      }
      else if(!player.seeded) {
        nonSeededPlayers.push(player);
      }
    })
    if (seededPlayers.length + nonSeededPlayers.length === players.length) {
      setVisible(true);
    } else if (
      seededPlayers.length + nonSeededPlayers.length !==
      players.length
    ) {
      showWarning("Please select all the players",toast);
    }
  };

  const confirmDraw = async (seededPlayers, nonSeededPlayers, noOfGroups) => {
    seededPlayers.sort((p1, p2) => {
      return parseInt(p1.seedNumber) - parseInt(p2.seedNumber);
    })
      const response = await axios.post(
        `${REACT_APP_URL}/all/dividePlayersInGroups`,
        {
          tournament_id: selectedTournament._id,
          tournament_name: selectedTournament.name,
          seeded_players: seededPlayers,
          non_seeded_players: nonSeededPlayers,
          number_of_groups: noOfGroups,
          format: selectedTournament?.format
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Draw Successful") {
        showSuccess(response.data.message,toast);
      } else {
        showError(response.data.message,toast);
      }
  };

  const AddPlayer = () => {
    const [name, setName] = useState("");
    const [date, setDate] = useState(null);
    const [gender, setGender] = useState("");
    const [image, setImage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [leader, setLeader] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const customBase64Uploader = async (event) => {
     
      const file = event.files[0];
      const formData=new FormData();
      formData.append('image',file);
      let response=await axios.post(`${REACT_APP_URL}/tournaments/logoUpload`,formData, {
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
       });
       console.log(response);
       if(response.data.msg==="Image Uploded Successfully"){
        showSuccess(response.data.msg,toast);
        setImage(response.data.url);
       }
       else{
        showWarning(response.data,toast)
       }

    };

    const addPlayer = async () => {
      if (name!=="" && gender!=="") {
        let response = await axios.post(
          `${REACT_APP_URL}/players/createPlayer`,
          {
            name: name,
            dateOfBirth: date,
            gender: gender,
            image: image,
            email: email,
            password: password,
            contact_no: contactNo,
            leader: leader,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );
        if(response.data==="Player Created"){
          showSuccess(response.data.message,toast);
        }
        else if(response.data === "Player already exists") {
          showWarning(response.data, toast);
        }
        else{
          showWarning(response.data,toast);
        }
      }else{
        showWarning('Incomplete Form',toast);
      }
    };

    return (
      <div style={{ fontFamily: "'Inter', sans-serif" }}>
        <div className="grid">
          <div className="col flex flex-column gap-3">
            <label>Name</label>
            <InputText
              style={{ fontFamily: "'Inter', sans-serif" }}
              placeholder="Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col flex flex-column gap-3">
            <label>Date Of Birth</label>
            <Calendar placeholder="Date Of Birth" value={date} onChange={(e) => setDate(e.value)} />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column gap-3">
            <label>Gender</label>
            <div className="flex justify-content-start align-items-center gap-3">
            <div className="flex align-items-center">
                    <RadioButton inputId="gender1" name="gender" value="Male" onChange={(e) => setGender(e.value)} checked={gender === 'Male'} />
                    <label htmlFor="gender1" className="ml-2">Male</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton inputId="gender2" name="gender" value="Female" onChange={(e) => setGender(e.value)} checked={gender === 'Female'} />
                    <label htmlFor="gender2" className="ml-2">Female</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton inputId="gender3" name="gender" value="Other" onChange={(e) => setGender(e.value)} checked={gender === 'Other'} />
                    <label htmlFor="gender3" className="ml-2">Other</label>
                </div>
            </div>
          </div>
          <div className="col flex flex-column gap-3">
            <label>Image</label>
            <div>
            <FileUpload
                style={{ fontFamily: "'Inter', sans-serif" }}
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                customUpload
                uploadHandler={customBase64Uploader}
              />
            </div>
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column gap-3">
            <label>Email</label>
            <div>
              <InputText
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col flex flex-column gap-3">
            <label>Password</label>
            <div>
              <input
                className="team-inputs"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column gap-3">
            <label>Contact No</label>
            <input
              className="team-inputs"
              placeholder="Contact No"
              required
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
          </div>
          <div className="col flex flex-column gap-3">
            <label>Leader</label>
            <div className="flex gap-3 mt-2">
              <Checkbox
                checked={leader}
                onChange={(e) => setLeader(e.checked)}
              />
              <label>Yes</label>
            </div>
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col flex flex-column">
            <Button label="Add Player" onClick={addPlayer} />
          </div>
        </div>
      </div>
    );
  };

  const PlayersGridItem = ({ player }) => {
    const [seeded, setSeeded] = useState(player.seeded);
    const [playerRating, setPlayerRating] = useState(player?.ratings);
    const [seedNumber, setSeedNumber] = useState("");
    const [logo, setLogo] = useState(null);

    const handleLogoChange = async (e) => {
      setLogo(e.target.files[0]);
      const data = new FormData();
      data.append("logo", e.target.files[0]);
      const response = await axios.post(`${REACT_APP_URL}/players/uploadPlayerLogoAndGetUrl`, data, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })
      
      const url = response.data;
      const responseTwo = await axios.post(`${REACT_APP_URL}/players/uploadPlayerLogo`, {
        player_id: player?._id,
        player_logo: url
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(responseTwo.data.message === "Logo uploaded") {
        showSuccess(responseTwo.data.message, toast);
      }
      else {
        showError(responseTwo.data.message, toast);
      }
    }

    const givePlayerRating = async (rating, sport) => {
      const response = await axios.post(`${REACT_APP_URL}/players/givePlayerRating`, {
        tournament_id: selectedTournament?._id,
        player_id: player?._id,
        rating: rating,
        sport: sport
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(response.data.message === "Ratings updated") {
        showSuccess(response.data.message, toast);
      }
      else {
        showError(response.data.message, toast);
      }
    }

    const getPlayerRatings = () => {
      if(player?.rating) {
        player.rating?.forEach((rating) => {
          if(rating.tournament_id === selectedTournament?._id) {
            setPlayerRating(rating?.stars);
          }
        })
      }
    }

    useEffect(() => {
      getPlayerRatings();
    },[])

    return (
      <div className="players-grid-item px-4 border-1 surface-border surface-card border-round flex flex-column justify-content-center align-items-center">
        <div className="flex justify-content-between align-items-center my-5">
          <input
            type="file"
            multiple={false}
            style={{ fontFamily: "'Inter', sans-serif" }}
            className="outline-none border-none"
            onChange={handleLogoChange}
          />
          <div className="flex justify-content-start align-items-center gap-3">
            {(!player.logo || player.logo === "") && (
              <Avatar sx={{ bgcolor: orange[500] }} >{player?.name[0]?.toUpperCase()}</Avatar>
            )}
            {(player.logo && player.logo !== "") && (
              <Avatar src={player?.logo} />
            )}
            <p>{player?.name}</p>
          </div>
        </div>
        <div className="flex justify-content-between align-items-center gap-5">
                        {selectedTournament?.individual && permissions && (
                            <>
                            <div className="input-switch flex justify-content-center align-items-center gap-2">
                              <p>Seeded</p>
                              <div>
                                <InputSwitch
                                  checked={seeded}
                                  onChange={(e) => {
                                    setSeeded(e.value)
                                    player.seeded = e.value
                                    players.forEach((player_) => {
                                      if(player_._id === player._id) {
                                        player_.seeded = e.value;
                                      }
                                    })
                                  }}
                                />
                            </div>
                          </div>
                          <InputText style={{ fontFamily: "'Inter', sans-serif" }} placeholder="Seed Number" value={seedNumber} onChange={(e) => {
                              setSeedNumber(e.target.value);
                              player["seedNumber"] = e.target.value;
                              players.forEach((player_) => {
                                if(player_._id === player._id) {
                                  player_.seedNumber = e.target.value;
                                }
                              })
                            }} />
                          </>
                        )}
                        </div>
              {permissions && (
                <div className="flex flex-column justify-content-center w-full mb-5">
                  {playerRating?.map((rating, index) => {
                    if(rating?.tournament_id === selectedTournament?._id) {
                      return (
                        <div className="flex justify-content-between align-items-center">
                          <p>{rating?.sport}</p>
                          <Rating
                            value={rating.stars}
                            precision={0.5}
                            onChange={(event, newValue) => {
                              let array = playerRating.slice();
                              array[index].stars = newValue;
                              setPlayerRating(array);
                              givePlayerRating(newValue, rating.sport);
                            }}
                          />
                        </div>
                    )
                  }
                  })}
                </div>
                        )}
            </div>
    )
  }

  const PlayersGrid = () => {
    return (
      <>
        <div className="players-grid m-6 gap-6 mb-8">
          {searchPlayers?.map((player, index) => {
            return (
                <PlayersGridItem player={player} key={index} />
              )
            })}
        </div>
      </>
    )
  }

  const Groups = () => {
    const [movePlayer, setMovePlayer] = useState(false);
    const [removePlayer, setRemovePlayer] = useState(false);
    const [remainingPlayers, setRemainingPlayers]= useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const getRemainingPlayers = async () => {
      const response = await axios.get(`${REACT_APP_URL}/players/getRemainingUnselectedPlayers/${selectedTournament._id}`, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      });

      setRemainingPlayers(response.data);
    }

    const addRemainingPlayerToGroup = async (group_number) => {
      if(selectedPlayer === null) {
        setGroups([...groups, group_number]);
      }
      else if(selectedPlayer !== null) {
        const response = await axios.post(`${REACT_APP_URL}/players/addRemainingPlayerToGroup`, {
          player_email: selectedPlayer.email,
          group_number: group_number,
          tournament_id: selectedTournament._id,
          tournament_name: selectedTournament.name
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })

        if(response.data.message === "Player Added") {
          showSuccess(response.data.message,toast);
        }
        else{
          showError(response.data.message,toast);
        }
      }
    }

    const movePlayer_ = async (e) => {
      e.preventDefault();
      let data = JSON.parse(e.dataTransfer.getData("playerName"))

      const response = await axios.post(`${REACT_APP_URL}/players/movePlayerFromOneGroupToAnotherGroup`, {
        player_email: data.player_email,
        group_number_remove: data.group_number_remove,
        group_number_move: e.target.id,
        tournament_id: selectedTournament._id,
        tournament_name: selectedTournament.name
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(response.data.message === "Player Moved") {
        showSuccess(response.data.message,toast);
      }
      else{
        showError(response.data.message,toast);
      }
    }

    const removePlayer_ = async (player, group) => {
      if(removePlayer) {
        const response = await axios.post(`${REACT_APP_URL}/players/removePlayerFromGroup`, {
          player_email: player.email,
          group_number: group.group_number,
          tournament_id: selectedTournament._id,
          tournament_name: selectedTournament.name
        }, {
          headers: {
            Authorization: sessionStorage.getItem("accessToken")
          }
        })

        if(response.data.message === "Player Removed") {
          showSuccess(response.data.message,toast);
        }
        else{
          showError(response.data.message,toast);
        }
      }
    }

    useEffect(() => {
      getRemainingPlayers()
    },[playerEventOccured])

    return (
      <>
        {permissions && (
          <div className="hidden md:flex md:justify-content-center md:align-items-center md:gap-4">
            <div className="flex justify-content-center align-items-center gap-3">
            <p className="text-white">Remove Players</p>
            <InputSwitch
              checked={removePlayer}
              onChange={(e) => setRemovePlayer(e.value)}
              />
            </div>
            <div className="flex justify-content-center align-items-center gap-3">
                <p className="text-white">Remaining Players</p>
                {/* <Dropdown
                  panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                  style={{ border: "1px solid #cacaca", borderRadius: "5px" }}
                  value={selectedPlayer}
                  onChange={(e) => setSelectedPlayer(e.target.value)}
                  options={remainingPlayers}
                  optionLabel="name"
                  placeholder="Select a Player"
                  /> */}
              </div>
          </div>
        )}
        <div className="groups-grid mx-4 mb-8">
          {selectedTournament?.groups
            ?.sort((group1, group2) => {
              return (
                parseInt(group1.group_number[group1.group_number.length - 1]) -
                parseInt(group2.group_number[group2.group_number.length - 1])
              );
            })
            .map((group) => {
              return (
                <div className="groups-grid-item" key={group.group_number}>
                  <p className="groups-header flex justify-content-between align-items-center">
                    <button
                      className="outer-btn font-light text-white"
                      onClick={() =>
                        addRemainingPlayerToGroup(group.group_number)
                      }
                    >
                      {group.group_number}
                    </button>
                  </p>
                  <div key={group.group_number}>
                  <table className="groups-table" style={{ backgroundColor: "#2b2b2b" }} onDrop={movePlayer_} onDragOver={(e) => {
                    e.preventDefault();
                  }}>
                    <tbody>
                    {permissions && (
                      <tr style={{ backgroundColor: "#fff" }}><th id={group.group_number} style={{ padding: "15px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>Drop Here</th></tr>
                    )}
                    <tr style={{ backgroundColor: "#FF8734", color: "#fff" }}><th style={{ padding: "15px" }}>Players</th></tr>
                    {group.players?.map((player, index) => {
                      return (
                        <tr id={JSON.stringify({ group_number_remove: group.group_number, player_email: player.email })} style={{ backgroundColor: "#fff" }} className="cursor-pointer" key={index} draggable onDragStart={(e) => {
                          e.dataTransfer.setData("playerName", e.target.id);
                        }} onClick={() => removePlayer_(player, group)}>
                          <td style={{ padding: "15px", borderBottomLeftRadius: index === group?.players?.length - 1 ? "10px": "0px", borderBottomRightRadius: index === group?.players?.length - 1 ? "10px": "0px" }}>{player.name}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const searchForAPlayer = () => {
    let filterArray = players?.filter((player) => {
      return player.name.toLowerCase().includes(searchQuery.toLowerCase());
    })

    setSearching(true);
    setSearchPlayers(filterArray);
  }

  const cancelSearch = () => {
    setSearching(false);
    setSearchPlayers(players);
    setSearchQuery("");
  }

  useEffect(() => {
    const tournamentLogin = async () => {
      await app.logIn(Realm.Credentials.anonymous());
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const tournamentCollection = mongodb.db("tournament_manager_database").collection("Tournaments");

      for await (const change of tournamentCollection.watch()) {
        sessionStorage.setItem("tournament", JSON.stringify(change.fullDocument));
        setTournamentEventOccured(!tournamentEventOccured);
      }
    }

    tournamentLogin()
  },[tournamentEventOccured])

  useEffect(() => {
    const playerLogin = async () => {
      await app.logIn(Realm.Credentials.anonymous());
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const playerCollection = mongodb.db("tournament_manager_database").collection("Players");

      for await (const change of playerCollection.watch()) {
        setPlayerEventOccured(!playerEventOccured);
      }
    }

    playerLogin();
  },[playerEventOccured])

  useEffect(() => {
    givePermissions(setPermissions);
  },[]);

  useEffect(() => {
    fetchAll();
    setSelectedTournament(JSON.parse(sessionStorage.getItem("tournament")));
  },[selectedTournament_, playerEventOccured, tournamentEventOccured])

  useEffect(() => {
    if(JSON.parse(sessionStorage.getItem("tournament")).ageGroups || JSON.parse(sessionStorage.getItem("tournament")).isAgeGroup) {
      setSelectedTournament(ageTournament);
      fetchAll();
    }
  },[ageGroupChanged, selectedTournament, playerEventOccured])

  return (
    <>
      <div className="container overflow-auto">
        {selectedTournament === null ? (
          <div style={{ color: "#757575" }} className="h-screen relative">
            <div
              style={{ marginTop: "25rem" }}
              className="flex justify-content-center align-items-center"
            >
              <p className="text-white">Please Select A Tournament OR Create a new one</p>
            </div>
          </div>
        ) : (
          <>
                <div className="flex justify-content-center align-items-center py-5 border-bottom-1 border-600" style={{ margin: "6rem", marginBottom: "2rem" }}>
                  <div className="flex justify-content-center align-items-center gap-5">
                    <p className="text-lg text-white">Players</p>
                    <div className="flex justify-content-center align-items-center gap-2 px-3 py-1 bg-primary border-round-sm cursor-pointer" onClick={fetchAll}>
                      <p className="text-white">Refresh</p>
                      <RefreshIcon sx={{ color: "white" }} />
                    </div>
                  </div>
            </div>
            <div className="players-flex-container card flex flex-column">
              {(!selectedTournament?.individual || selectedTournament?.groups?.length === 0) && (
                <>
                  <div className="flex justify-content-center align-items-center gap-3">
                    <InputText
                      style={{ fontFamily: "'Inter', sans-serif" }}
                      placeholder="Search for a Player..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {!searching && (
                      <Search className="cursor-pointer" color="white" onClick={searchForAPlayer} />
                    )}
                    {searching && (
                      <X className="cursor-pointer" color="white" onClick={cancelSearch} />
                    )}
                    <button className="btn-knockout border-round-sm ml-5" onClick={() => setRatingsVisible(true)}>Decide Ratings</button>
                  </div>
                    <PlayersGrid />
                    {selectedTournament?.individual && (
                      <div className="flex justify-content-end align-items-center m-3 mb-8">
                        <button
                          className="btn-tournament"
                          onClick={() =>
                            proceedToDraw(
                              players
                              )
                            }
                            >
                          Proceed to Draw
                        </button>
                      </div>
                    )}
                    </>
              )}
              {selectedTournament?.groups?.length > 0 &&
                (selectedTournament?.individual || selectedTournament?.isAgeGroup) && (
                  <>
                    <div className="main-groups-grid flex flex-column justify-content-center align-items-center gap-3 mt-1">
                      <Groups />
                    </div>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      {/* Modals */}
      <Dialog
        style={{ width: "50vw" }}
        visible={visibleAdd}
        onHide={() => setVisibleAddPlayer(false)}
      >
        <AddPlayer />
      </Dialog>
      <Dialog
        style={{ width: "50vw" }}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="flex flex-column gap-3">
          <div
            style={{ fontFamily: "'Inter', sans-serif" }}
            className="flex flex-column gap-3"
          >
            <label>Number of Groups</label>
            <InputNumber
              value={noOfGroups}
              onChange={(e) => setNoOfGroups(e.value)}
            />
          </div>
        </div>
        <div className="flex justify-content-end align-items-center mt-5">
          <button
            className="btn-tournament"
            onClick={() =>
              confirmDraw(seededPlayers, nonSeededPlayers, noOfGroups)
            }
          >
            Confirm
          </button>
        </div>
      </Dialog>
      <Dialog style={{ width: "50vw" }} visible={playerInstructionModalOpen} onHide={() => setPlayerInstructionModalOpen(false)}>
        <PlayerInstructionModal setPlayerInstructionModalOpen={setPlayerInstructionModalOpen} setVisibleAddPlayer={setVisibleAddPlayer} selectedTournament={selectedTournament} setOpenAddPlayersToTournament={setOpenAddPlayersToTournament} />
      </Dialog>
      <Dialog style={{ width: "50vw" }} visible={ratingsVisible} onHide={() => setRatingsVisible(false)}>
            <AddRatings tournament_id={selectedTournament?._id} toast={toast} />
      </Dialog>
    </>
  );
}

export default ShowPlayer;
