import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Trophy, X } from "lucide-react";
import { Shirt } from "lucide-react";
import { PersonStanding } from "lucide-react";
import { Dribbble } from "lucide-react";
import { BarChart3 } from "lucide-react";
import { LayoutDashboard } from 'lucide-react';

const Navbar = ({ setShowNavbar }) => {
  const LinkTagStyle = { textDecoration: "none", color: "#FF8734" };

  const closeNavbar = () => {
    setTimeout(() => {
      setShowNavbar(false);
    },450);
  };

  const NavItem = ({ Component_1, Component_2 }) => {
    return (
      <div className="flex justify-content-start align-items-center gap-3">
        {Component_1}
        {Component_2}
      </div>
    );
  };

  return (
    <>
      <div className="navbar flex gap-7">
        <X className="pi pi-times cursor-pointer" style={{ color: "#fff" }} onClick={closeNavbar} />
        <NavItem
          Component_1={<Trophy color="#FF8734" />}
          Component_2={
            <Link className="navLink" style={LinkTagStyle} to="/showTournaments">
              Tournaments
            </Link>
          }
        />
        <NavItem
          Component_1={<Shirt color="#FF8734" />}
          Component_2={
            <Link className="navLink" style={LinkTagStyle} to="/showTeams">
              Teams
            </Link>
          }
        />
        <NavItem
          Component_1={<PersonStanding color="#FF8734" />}
          Component_2={
            <Link className="navLink" style={LinkTagStyle} to="/showPlayers">
              Players
            </Link>
          }
        />
        <NavItem
          Component_1={<Dribbble color="#FF8734" />}
          Component_2={
            <Link className="navLink" style={LinkTagStyle} to="/showMatches">
              Matches
            </Link>
          }
        />
        <NavItem
          Component_1={<BarChart3 color="#FF8734" />}
          Component_2={
            <Link className="navLink" style={LinkTagStyle} to="/showAnalytics">
              Analytics
            </Link>
          }
        />
        <NavItem
          Component_1={<LayoutDashboard color="#FF8734" />}
          Component_2={<Link className="navLink" style={LinkTagStyle} to="/showDashboard">Dashboard</Link>}
        />
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;