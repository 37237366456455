import React from 'react'
import { useState } from 'react';
import { Dropdown} from 'primereact/dropdown';
import axios from 'axios';
import { Button } from 'primereact/button';
import { showSuccess,showError,showWarning } from '../toasts';
const { REACT_APP_URL } = process.env;


const ChangeCaptain = ({team,leader,toast}) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const members=team.Members;
  const l_id=leader.id;
  const t_id= team._id;
  const Members= members.filter((member)=>
  {
   return  member.id !==leader.id;
  })

  const SubmitChange=async ()=>
  {
    console.log(selectedPlayer);
    try{
        const response= await axios.post(`${REACT_APP_URL}/dashboard/changeCaptain`,{l_id,selectedPlayer,t_id});
        console.log(response);
        if(response.data==="Success"){showSuccess("Captain chnged successfully!",toast)}
        else showWarning(response.data,toast);

    }catch(err){
       showError('Axios error from frontend',toast);
    }

  }
    
  return (
    <div className='flex flex-column gap-3 '>
            <lable>Select Captain</lable>
            <div className='flex gap-3'>
                <Dropdown value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.value)}
                options={Members} optionLabel="name"
                placeholder="Select captain" 
                className="w-full md:w-14rem"
                />
                <Button label='Submit' onClick={SubmitChange}/>
                
            </div>   
    </div>
  )
}

export default ChangeCaptain