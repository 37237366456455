import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Check, Plus, Trash2, Users2 } from "lucide-react";
import Footer from "./Footer";
import givePermissions from "../permissions";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import AddPlayersToRounds from "./AddPlayersToRounds";
import { showSuccess, showError, showWarning } from "./toasts";
import MatchInstructionModal from "./Instruction_Modals/MatchInstructionModal";
import KnockoutsView from "./KnockoutsView";
import RefreshIcon from '@mui/icons-material/Refresh';
import * as Realm from "realm-web";
import MatchTemplate from "./MatchTemplate";
const { REACT_APP_URL } = process.env;

export default function ShowMatches({
  toast,
  matchInstructionModalOpen,
  setMatchInstructionModalOpen,
  selectedGroup_,
  setSelectedGroup_,
  ageGroupChanged,
}) {
  const [selectedTournament, setSelectedTournament] = useState(
    JSON.parse(sessionStorage.getItem("tournament")) || null
  );
  const [internalTournaments, setInternalTournaments] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [visible, setVisible] = useState(false);
  const [switchView, setSwitchView] = useState(false);
  const [matchEventOccured, setMatchEventOccured] = useState(false);
  const [permissions, setPermissions] = useState();

  const [spinner, setSpinner] = useState(false);

  const [openMatchTemplate, setOpenMatchTemplate] = useState(false);

  const handleOpenMatchTemplate = () => {
    setOpenMatchTemplate(true);
  }

  const handleCloseMatchTemplate = () => {
    setOpenMatchTemplate(false);
  }

  const fetchAllInternalTournaments = async () => {
    if (selectedTournament !== null) {
      let response = await axios.get(
        `${REACT_APP_URL}/tournaments/getInternalTournaments/${
          JSON.parse(sessionStorage.getItem("tournament"))._id
        }`
      );
      setInternalTournaments(response.data);
    }
  };

  const scheduleRoundMatches = async () => {
    if (
      !selectedTournament?.format ||
      selectedTournament?.format === "Round Robin"
    ) {
      await axios.post(
        `${REACT_APP_URL}/algos/Rr`,
        {
          tournament_id: selectedTournament._id,
          type: selectedTournament.individual ? "player" : "team",
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );
      showSuccess("", toast);
    } else if (selectedTournament?.format === "Single Elimination") {
      let teams = [];
      if (selectedTournament.individual) {
        const teams_response = await axios.get(
          `${REACT_APP_URL}/players/getPlayers/${selectedTournament._id}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("accessToken"),
            },
          }
        );

        teams = teams_response.data;
      } else if (!selectedTournament.individual) {
        const teams_response = await axios.get(
          `${REACT_APP_URL}/teams/allTeams/${selectedTournament._id}`
        );

        teams = teams_response.data;
      }

      const response = await axios.post(
        `${REACT_APP_URL}/singleElimination/roundOneMatchDetails`,
        {
          tournament_id: selectedTournament._id,
          individual: selectedTournament.individual,
          array_of_teams: teams,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Phase Initiated") {
        showSuccess("Matches Scheduled", toast);
      } else if (response.data.message === "Tournament Already Exists") {
        showWarning(response.data.message, toast);
      } else {
        showError(response.data.message, toast);
      }
    }
  };

  const scheduleNextMatches = async () => {
    const response = await axios.post(
      `${REACT_APP_URL}/singleElimination/scheduleNextMatches`,
      {
        tournament_id: selectedTournament._id,
        group_number: selectedGroup.group_number,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("accessToken"),
        },
      }
    );

    if (response.data.message === "Matches Scheduled") {
      showSuccess(response.data.message, toast);
    } else {
      showError(response.data.message, toast);
    }
  };

  const declareWinner = async () => {
    if (selectedTournament.individual) {
      let player_id = "";
      let sumOne = 0;
      let sumTwo = 0;
      selectedGroup.matchDetails[
        selectedGroup.matchDetails.length - 1
      ].firstTeam.scores.forEach((score) => {
        sumOne = sumOne + parseInt(score);
      });
      selectedGroup.matchDetails[
        selectedGroup.matchDetails.length - 1
      ].secondTeam.scores.forEach((score) => {
        sumTwo = sumTwo + parseInt(score);
      });
      if (sumOne > sumTwo) {
        player_id =
          selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
            .firstTeam._id;
      } else if (
        selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
          .secondTeam.won
      ) {
        player_id =
          selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
            .secondTeam._id;
      }
      const response = await axios.post(
        `${REACT_APP_URL}/tournaments/decideWinner`,
        {
          tournament_id: selectedTournament._id,
          individual: selectedTournament.individual,
          player_id: player_id,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Winner Declared") {
        showSuccess(response.data.message, toast);
      } else {
        showError(response.data.message, toast);
      }
    } else if (!selectedTournament.individual) {
      let team_name = "";
      let sumOne = 0;
      let sumTwo = 0;
      selectedGroup.matchDetails[
        selectedGroup.matchDetails.length - 1
      ].firstTeam.scores.forEach((score) => {
        sumOne = sumOne + parseInt(score);
      });
      selectedGroup.matchDetails[
        selectedGroup.matchDetails.length - 1
      ].secondTeam.scores.forEach((score) => {
        sumTwo = sumTwo + parseInt(score);
      });
      if (sumOne > sumTwo) {
        team_name =
          selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
            .firstTeam.TeamName;
      } else if (
        selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
          .secondTeam.won
      ) {
        team_name =
          selectedGroup.matchDetails[selectedGroup.matchDetails.length - 1]
            .secondTeam.TeamName;
      }
      const response = await axios.post(
        `${REACT_APP_URL}/tournaments/decideWinner`,
        {
          tournament_id: selectedTournament._id,
          individual: selectedTournament.individual,
          team_name: team_name,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Winner Declared") {
        showSuccess(response.data.message, toast);
      } else {
        showError(response.data.message, toast);
      }
    }
  };

  const startKnockoutPhase = () => {
    setVisible(true);
  };

  const KnockoutPhase = () => {
    const [numberOfTeams, setNumberOfTeams] = useState(0);

    const beginKnockoutPhase = async () => {
      const response = await axios.post(
        `${REACT_APP_URL}/singleElimination/roundOneMatchDetails`,
        {
          tournament_id: selectedTournament._id,
          individual: selectedTournament.individual,
          number_of_teams: numberOfTeams,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Phase Initiated") {
        showSuccess(response.data.message, toast);
      } else if (response.data.message === "Tournament Already Exists") {
        showError(response.data.message, toast);
      } else {
        showError(response.data.message, toast);
      }
    };

    return (
      <>
        <div className="flex flex-column gap-4">
          <label>Number of Teams to Top Out from each Group</label>
          <input
            className="p-3 border-1 border-round-sm border-gray-400"
            type="number"
            value={numberOfTeams}
            onChange={(e) => setNumberOfTeams(e.target.value)}
          />
          <button
            className="btn-knockout border-round-sm"
            onClick={beginKnockoutPhase}
          >
            Start
          </button>
        </div>
      </>
    );
  };

  const Match = ({ match, roundNo, type }) => {
    const [roundNumber, setRoundNumber] = useState(roundNo);
    const [date, setDate] = useState(match?.date);
    const [time, setTime] = useState(match?.time);
    const [venue, setVenue] = useState(match?.venue);
    const [status, setStatus] = useState(match?.status);
    const [teamOneScore, setTeamOneScore] = useState(
      match.firstTeam.scores[match.firstTeam?.scores?.length - 1]
    );
    const [teamTwoScore, setTeamTwoScore] = useState(
      match.secondTeam.scores[match.secondTeam?.scores?.length - 1]
    );
    const [teamOneWon, setTeamOneWon] = useState(match.firstTeam.won || null);
    const [teamTwoWon, setTeamTwoWon] = useState(match.secondTeam.won || null);
    const [teamOneColor, setTeamOneColor] = useState("green");
    const [teamTwoColor, setTeamTwoColor] = useState("green");
    const [addGame, setAddGame] = useState(false);
    const [openAddPlayersToRounds, setOpenAddPlayersToRounds] = useState(false);

    const [roundNum, setRoundNum] = useState(0);
    const [teamOneRoundPlayers, setTeamOneRoundPlayers] = useState([]);
    const [teamTwoRoundPlayers, setTeamTwoRoundPlayers] = useState([]);

    const [teamOneAddedPoints, setTeamOneAddedPoints] = useState(0);
    const [teamTwoAddedPoints, setTeamTwoAddedPoints] = useState(0);

    const [teamOneInitialScores, setTeamOneInitialScores] = useState([]);
    const [teamTwoInitialScores, setTeamTwoInitialScores] = useState([]);
    const [Description, setDescription] = useState(match?.description || []);
    const [MainDiscription,setMainDiscription]=useState(match?.mainDescription || "");
    const [teamOneBonusPoints, setTeamOneBonusPoints] = useState("");
    const [teamTwoBonusPoints, setTeamTwoBonusPoints] = useState("");
    const getInitialScores = () => {
      let array_one = [];
      let array_two = [];

      match?.firstTeam?.scores?.forEach((score, index) => {
        array_one.push(score);
      });

      match?.secondTeam?.scores?.forEach((score, index) => {
        array_two.push(score);
      });

      setTeamOneInitialScores(array_one);
      setTeamTwoInitialScores(array_two);
    };

    const calculateTeamTotalPoints = () => {
      let sum_one = 0;
      match.firstTeam?.scores?.forEach((score) => {
        sum_one = sum_one + parseInt(score);
      });

      let sum_two = 0;
      match.secondTeam?.scores?.forEach((score) => {
        sum_two = sum_two + parseInt(score);
      });

      setTeamOneAddedPoints(sum_one);
      setTeamTwoAddedPoints(sum_two);

      if (sum_one > sum_two) {
        setTeamOneWon(true);
        setTeamTwoWon(false);
      } else if (sum_two > sum_one) {
        setTeamTwoWon(true);
        setTeamOneWon(false);
      }
    };

    const displayAddPlayersToRounds = (roundNum_) => {
      setRoundNum(roundNum_);
      setOpenAddPlayersToRounds(true);
    };

    const updateMatchInformation = async () => {
      console.log({
        group_number: selectedGroup.group_number,
          current_round: selectedGroup.current_round,
          tournament_id: selectedGroup.tournament_id,
          tournament_name: selectedTournament.name,
          match_no: match.matchNo,
          roundNo: roundNumber,
          date: date,
          time: time,
          venue: venue,
          first_team: {
            scores: teamOneScore,
            won: teamOneWon === null ? false : true,
            teamOneInitialScores: teamOneInitialScores,
          },
          second_team: {
            scores: teamTwoScore,
            won: teamTwoWon === null ? false : true,
            teamTwoInitialScores: teamTwoInitialScores,
          },
          addGame: addGame,
          status: status,
          individual: selectedTournament.individual,
          type: type,
          teamOneRoundPlayers: teamOneRoundPlayers,
          teamTwoRoundPlayers: teamTwoRoundPlayers,
          gameNo: roundNum,
          winPoints: selectedTournament.winPoint,
          lossPoints: selectedTournament.lossPoint,
          teamOneAddedPoints: teamOneAddedPoints,
          teamTwoAddedPoints: teamTwoAddedPoints,
          description: Description,
          mainDescription:MainDiscription
      })

      const response = await axios.post(
        `${REACT_APP_URL}/singleElimination/updateTournament`,
        {
          group_number: selectedGroup.group_number,
          current_round: selectedGroup.current_round,
          tournament_id: selectedGroup.tournament_id,
          tournament_name: selectedTournament.name,
          match_no: match.matchNo,
          roundNo: roundNumber,
          date: date,
          time: time,
          venue: venue,
          first_team: {
            scores: teamOneScore,
            won: teamOneWon === null ? false : true,
            teamOneInitialScores: teamOneInitialScores,
          },
          second_team: {
            scores: teamTwoScore,
            won: teamTwoWon === null ? false : true,
            teamTwoInitialScores: teamTwoInitialScores,
          },
          addGame: addGame,
          status: status,
          individual: selectedTournament.individual,
          type: type,
          teamOneRoundPlayers: teamOneRoundPlayers,
          teamTwoRoundPlayers: teamTwoRoundPlayers,
          gameNo: roundNum,
          winPoints: selectedTournament.winPoint,
          lossPoints: selectedTournament.lossPoint,
          teamOneAddedPoints: teamOneAddedPoints,
          teamTwoAddedPoints: teamTwoAddedPoints,
          description: Description,
          mainDescription:MainDiscription
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.message === "Details Updated") {
        showSuccess(response.data.message, toast);
      } else if (response.data.message === "Please Try Again") {
        showError(response.data.message, toast);
      }
    };

    const deletePlayerFromMatch = async (player_id, fromFirstTeam, gameNo) => {
      const response = await axios.post(`${REACT_APP_URL}/players/deletePlayerFromMatch`, {
        player_id: player_id,
        fromFirstTeam: fromFirstTeam,
        tournament_id: selectedTournament?._id,
        group_number: selectedGroup?.group_number,
        matchNo: match.matchNo,
        roundNo: roundNo,
        gameNo: gameNo
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      });

      if(response.data.message === "Player removed") {
        showSuccess(response.data.message, toast);
      }
      else {
        showError(response.data.message, toast);
      }
    }

    const deleteMatch = async (round_no, match_no) => {
      const response = await axios.delete(`${REACT_APP_URL}/singleElimination/deleteMatch/${selectedTournament?._id}/${selectedGroup?.group_number}/${round_no}/${match_no}`, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      });

      if(response.data.message === "Match Deleted Successfully") {
        showSuccess(response.data.message, toast);
      }
      else {
        showError(response.data.message, toast);
      }
    }

    const addBonusPoints = async (team_id, bonusPoint) => {
      const response = await axios.post(`${REACT_APP_URL}/singleElimination/addBonusPoint`, {
        tournament_id: selectedTournament?._id,
        group_number: selectedGroup?.group_number,
        team_id: team_id,
        bonus_point: parseInt(bonusPoint)
      }, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
        }
      })

      if(response.data.message === "Bonus Point Added") {
        showSuccess(response.data.message, toast);
      }
      else {
        showError(response.data.message, toast);
      }
    }

    useEffect(() => {
      calculateTeamTotalPoints();
      getInitialScores();
    }, []);

    return (
      <>
        <div
          className="matches-grid-item border-1 border-gray-300 border-round p-5 relative"
          key={match.match_no}
        >
          {permissions && (
            <div className="flex justify-content-between align-items-center">
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "1rem",
                  color: "blue",
                }}
                onClick={updateMatchInformation}
                className="hidden md:inline-flex"
              >
                Update
              </button>
              <input
                      type="text"
                      className="p-2 border-none border-bottom-1 border-500"
                      placeholder="Main Description"
                      value={MainDiscription}
                      onChange={(e) => {
                        setMainDiscription(e.target.value);
                        
                      }}
                      style={{ fontFamily: "'Inter', sans-serif" }}
                />

            </div>
          )}
          <div className="flex justify-content-between align-items-center my-3">
            <input
              className="match-inputs hidden md:inline-flex"
              placeholder={match?.date || "Date To be declared"}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              onChange={(e) => setDate(e.target.value)}
              style={{ width: "max-content", textAlign: "left" }}
              type="text"
              readOnly={!permissions}
            />
            {permissions && (
              <p
                className="cursor-pointer hidden md:inline-flex"
                style={{ color: "green" }}
                onClick={() => {
                  setAddGame(true);
                }}
              >
                Add Game
              </p>
            )}
            <p className="md:hidden lg:hidden xl:hidden">
              Head to a bigger screen to see more details
            </p>
            <input
              className="match-inputs hidden md:inline-flex"
              placeholder={match?.time || "Time To be declared"}
              onFocus={(e) => (e.target.type = "time")}
              onBlur={(e) => (e.target.type = "text")}
              onChange={(e) => setTime(e.target.value)}
              style={{ width: "max-content", textAlign: "right" }}
              type="text"
              readOnly={!permissions}
            />
          </div>
          <hr />
          {!selectedTournament?.individual && !selectedTournament?.regular && (
            <>
          <div className="flex justify-content-between align-items-center">
            <div className="flex flex-column gap-3">
              <div className="flex justify-content-start gap-4 align-items-center">
                <p>{match.firstTeam?.TeamName || match.firstTeam?.name}</p>
                {(teamOneAddedPoints > teamTwoAddedPoints) && match.status?.toLowerCase() === "over" && (
                  <Check className="cursor-pointer" color="green" />
                )}
              </div>
              <label>Bonus Points</label>
              <div className="flex justify-content-start align-items-center gap-3">
                  <input
                          className="match-inputs border-none border-bottom-1 border-500"
                          type="text"
                          value={teamOneBonusPoints}
                          onChange={(e) => setTeamOneBonusPoints(e.target.value)}
                        />
                  <Plus className="cursor-pointer" onClick={() => addBonusPoints(match.firstTeam._id, teamOneBonusPoints)} />
              </div>
            </div>
            <div className="flex flex-column justify-content-end align-items-end gap-3">
              <div className="flex justify-content-start gap-4 align-items-center">
                <p>{match.secondTeam?.TeamName || match.secondTeam?.name}</p>
                {(teamOneAddedPoints < teamTwoAddedPoints) && match.status?.toLowerCase() === "over" && (
                  <Check className="cursor-pointer" color="green" />
                  )}
              </div>
              <label>Bonus Points</label>
              <div className="flex justify-content-end align-items-end gap-3">
                  <input
                          className="match-inputs border-none border-bottom-1 border-500"
                          type="text"
                          value={teamTwoBonusPoints}
                          onChange={(e) => setTeamTwoBonusPoints(e.target.value)}
                        />
                  <Plus className="cursor-pointer" onClick={() => addBonusPoints(match.secondTeam._id, teamTwoBonusPoints)} />
              </div>
            </div>
          </div>
          </>
          )}
          {!selectedTournament.individual && !selectedTournament?.regular &&
            match?.firstTeam?.scores?.length > 0 && (
              <>
                <div className="flex justify-content-start align-items-start gap-3 mt-3 mb-3">
                  {match?.firstTeam?.scores?.map((score, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-content-start align-items-center"
                      >
                        <p
                          className="cursor-pointer"
                          onClick={() => displayAddPlayersToRounds(index)}
                        >
                          Match{" "}
                        </p>
                        <p>{"(" + (index + 1) + ")"}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          {!selectedTournament?.individual && !selectedTournament?.regular && (
            <>
          <div className="flex justify-content-between align-items-center">
            <div className="flex flex-column justify-content-start align-items-start">
              {match.firstTeam?.scores?.length === 1 && (
                <div className="flex flex-column justify-content-center align-items-start gap-2">
                  <div className="flex flex-row gap-3 mt-1">
                    <input
                      type="text"
                      className="p-2 border-none border-bottom-1 border-500"
                      placeholder="Add Description"
                      value={Description[0]}
                      onChange={(e) => {
                        let array = Description.slice();
                        array[0] = e.target.value;
                        setDescription(array);
                      }}
                      style={{ fontFamily: "'Inter', sans-serif" }}
                    />
                  </div>
                  <>
                    <div className="flex flex-column justify-content-start align-items-start gap-1">
                      {!selectedTournament.individual && (
                        <>
                          <div>
                          {!selectedTournament.individual &&
                                match.firstTeam?.players[0] && (
                                  Object.keys(match?.firstTeam?.players[0]).map((player, index_) => {
                                    return (
                                      <div className="flex justify-content-start align-items-center gap-3">
                                        <Trash2 className="cursor-pointer" size={24} onClick={() => {
                                          deletePlayerFromMatch(match?.firstTeam?.players[0][player]._id, true, 0);
                                        }} />
                                        <p>
                                        {
                                          match?.firstTeam?.players[0][player]?.name
                                        }
                                        </p>
                                      </div>
                                    )
                                  })
                                )}
                          </div>
                          <input
                            className="match-inputs"
                            type="text"
                            value={teamOneScore}
                            onChange={(e) => setTeamOneScore(e.target.value)}
                            readOnly={!permissions}
                          />
                        </>
                      )}
                      {selectedTournament.individual && (
                        <input
                          className="match-inputs"
                          type="text"
                          value={teamOneScore}
                          onChange={(e) => setTeamOneScore(e.target.value)}
                          readOnly={!permissions}
                        />
                      )}
                    </div>
                  </>
                </div>
              )}
              {match.firstTeam?.scores?.length > 1 && (
                <>
                  {match.firstTeam?.scores?.map((score, index) => {
                    return (
                      <>
                        <div className="flex flex-column justify-content-center align-items-start gap-2">
                          <div className="flex flex-row gap-3 mt-3">
                            <input
                              type="text"
                              className="p-2 border-none border-bottom-1 border-500"
                              placeholder="Add Description"
                              value={Description[index]}
                              onChange={(e) => {
                                let array = Description.slice();
                                array[index] = e.target.value;
                                setDescription(array);
                              }}
                              style={{ fontFamily: "'Inter', sans-serif" }}
                            />
                          </div>
                          {!selectedTournament.individual && (
                            <div
                              style={{ width: "100%" }}
                              className="flex flex-column align-items-start gap-0 border-top-1 border-500 mb-2"
                            >
                              {!selectedTournament.individual &&
                                match.firstTeam?.players[index] && (
                                  Object.keys(match?.firstTeam?.players[index]).map((player, index_) => {
                                    return (
                                      <div className="flex justify-content-start align-items-center gap-2">
                                        <Trash2 className="cursor-pointer" size={24} onClick={() => {
                                          deletePlayerFromMatch(match?.firstTeam?.players[index][player]._id, true, index);
                                        }} />
                                        <p className="mr-2">
                                        {
                                          match?.firstTeam?.players[index][player]?.name
                                        }
                                        </p>
                                      </div>
                                    )
                                  })
                                )}
                              {index !==
                                match.firstTeam?.scores?.length - 1 && (
                                <input
                                  type="text"
                                  className="match-inputs"
                                  value={teamOneInitialScores[index]}
                                  onChange={(e) => {
                                    let array = teamOneInitialScores.slice();
                                    array[index] = e.target.value;
                                    setTeamOneInitialScores(array);
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {selectedTournament.individual && (
                            <>
                              {index !==
                                match.firstTeam?.scores?.length - 1 && (
                                <input
                                  type="text"
                                  className="match-inputs"
                                  value={teamOneInitialScores[index]}
                                  onChange={(e) => {
                                    let array = teamOneInitialScores.slice();
                                    array[index] = e.target.value;
                                    setTeamOneInitialScores(array);
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <input
                    className="match-inputs"
                    type="text"
                    value={teamOneScore}
                    onChange={(e) => setTeamOneScore(e.target.value)}
                    readOnly={!permissions}
                  />
                </>
              )}
            </div>
            <div className="flex flex-column justify-content-end align-items-end">
              {match.secondTeam?.scores?.length === 1 && (
                <>
                  <div className="flex justify-content-end align-items-center gap-5">
                    <div className="flex flex-column justify-content-center align-items-start gap-2">
                      <div
                        className="flex flex-row gap-3 mt-3"
                        style={{ visibility: "hidden" }}
                      >
                        <input
                          type="text"
                          className="p-2 border-none border-bottom-1 border-300"
                        />
                      </div>
                      {!selectedTournament.individual && (
                        <>
                          <div
                            style={{ width: "100%" }}
                            className="flex flex-column align-items-end gap-0 mb-2"
                          >
                            {!selectedTournament.individual &&
                                match.secondTeam?.players[0] && (
                                  Object.keys(match?.secondTeam?.players[0]).map((player, index_) => {
                                    return (
                                      <div className="flex justify-content-start align-items-center gap-3">
                                        <p className="mr-2">
                                        {
                                          match?.secondTeam?.players[0][player]?.name
                                        }
                                        </p>
                                        <Trash2 className="cursor-pointer" size={24} onClick={() => {
                                          deletePlayerFromMatch(match?.secondTeam?.players[0][player]._id, false, 0);
                                        }} />
                                      </div>
                                    )
                                  })
                                )}
                            <input
                              className="match-inputs"
                              type="text"
                              value={teamTwoScore}
                              onChange={(e) => setTeamTwoScore(e.target.value)}
                              readOnly={!permissions}
                            />
                          </div>
                        </>
                      )}
                      {selectedTournament.individual && (
                        <>
                          <div
                            style={{ width: "100%" }}
                            className="flex flex-column align-items-end gap-0 mb-2"
                          >
                            <input
                              className="match-inputs"
                              type="text"
                              value={teamTwoScore}
                              onChange={(e) => setTeamTwoScore(e.target.value)}
                              readOnly={!permissions}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      className="btn-knockout border-round-md p-3"
                      onClick={updateMatchInformation}
                    >
                      Update
                    </button>
                  </div>
                </>
              )}
              {match.secondTeam?.scores?.length > 1 && (
                <>
                  {match.secondTeam?.scores?.map((score, index) => {
                    return (
                      <>
                        <div className="flex flex-column justify-content-center align-items-start gap-2">
                          <div
                            className="flex flex-row gap-3 mt-3"
                            style={{ visibility: "hidden" }}
                          >
                            <input
                              type="text"
                              className="p-2 border-none border-bottom-1 border-500"
                            />
                          </div>
                          <div className="flex justify-content-end align-items-center gap-5">
                            {!selectedTournament.individual && (
                              <div
                                style={{ width: "100%" }}
                                className="flex flex-column align-items-end gap-0 border-top-1 border-500 mb-2"
                              >
                                {!selectedTournament.individual &&
                                match.secondTeam?.players[index] && (
                                  Object.keys(match?.secondTeam?.players[index]).map((player, index_) => {
                                    return (
                                      <div className="flex justify-content-start align-items-center gap-3">
                                        <p className="mr-2">
                                        {
                                          match?.secondTeam?.players[index][player]?.name
                                        }
                                        </p>
                                        <Trash2 className="cursor-pointer" size={24} onClick={() => {
                                          deletePlayerFromMatch(match?.secondTeam?.players[index][player]._id, false, index);
                                        }} />
                                      </div>
                                    )
                                  })
                                )}
                                {index !==
                                  match.secondTeam?.scores?.length - 1 && (
                                  <input
                                    type="text"
                                    className="match-inputs"
                                    value={teamTwoInitialScores[index]}
                                    onChange={(e) => {
                                      let array = teamTwoInitialScores.slice();
                                      array[index] = e.target.value;
                                      setTeamTwoInitialScores(array);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            {selectedTournament.individual && (
                              <>
                                {index !==
                                  match.secondTeam?.scores?.length - 1 && (
                                  <input
                                    type="text"
                                    className="match-inputs"
                                    value={teamTwoInitialScores[index]}
                                    onChange={(e) => {
                                      let array = teamTwoInitialScores.slice();
                                      array[index] = e.target.value;
                                      setTeamTwoInitialScores(array);
                                    }}
                                  />
                                )}
                              </>
                            )}
                            <button
                              className="btn-knockout border-round-md p-3"
                              onClick={updateMatchInformation}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <input
                    className="match-inputs"
                    type="text"
                    value={teamTwoScore}
                    onChange={(e) => setTeamTwoScore(e.target.value)}
                    readOnly={!permissions}
                  />
                </>
              )}
            </div>
          </div>
          </>
          )}
          {(selectedTournament?.individual || selectedTournament?.regular) && (
            <>
              <div className="flex flex-column justify-content-center align-items-start">
                <div className="flex justify-content-between align-items-center w-full">
                  <div className="flex justify-content-start gap-4 align-items-center">
                    {selectedTournament?.doubles && (
                      <div className="">
                        {match?.firstTeam?.Members?.map((member, index) => {
                          return (
                            <p key={index}>{member?.name}</p>
                          )
                        })}
                      </div>
                    )}
                    {!selectedTournament?.doubles && (
                      <p>{match.firstTeam?.TeamName || match.firstTeam?.name}</p>
                    )}
                    {teamOneWon && match.status === "Over" && (
                      <Check className="cursor-pointer" color="green" />
                      )}
                  </div>
                  <div className="flex justify-content-end align-items-center">
              {match.firstTeam?.scores?.length === 1 && (
                  <>
                        <input
                          className="match-inputs"
                          type="text"
                          value={teamOneScore}
                          onChange={(e) => setTeamOneScore(e.target.value)}
                          readOnly={!permissions}
                        />
                  </>
              )}
              {match.firstTeam?.scores?.length > 1 && (
                <>
                    <div className="flex flex justify-content-end align-items-center">
                  {match.firstTeam?.scores?.map((score, index) => {
                    return (
                      <>
                              {index !==
                                match.firstTeam?.scores?.length - 1 && (
                                <input
                                  type="text"
                                  className="match-inputs-2"
                                  value={teamOneInitialScores[index]}
                                  onChange={(e) => {
                                    let array = teamOneInitialScores.slice();
                                    array[index] = e.target.value;
                                    setTeamOneInitialScores(array);
                                  }}
                                />
                              )}
                      </>
                    );
                  })}
                  <input
                    className="match-inputs-2"
                    type="text"
                    value={teamOneScore}
                    onChange={(e) => setTeamOneScore(e.target.value)}
                    readOnly={!permissions}
                    />
                  </div>
                </>
              )}
            </div>
                </div>
                <div className="flex justify-content-between align-items-center w-full">
                  <div className="flex justify-content-start gap-4 align-items-center">
                  {selectedTournament?.doubles && (
                      <div className="">
                        {match?.secondTeam?.Members?.map((member, index) => {
                          return (
                            <p key={index}>{member?.name}</p>
                          )
                        })}
                      </div>
                    )}
                    {!selectedTournament?.doubles && (
                      <p>{match.secondTeam?.TeamName || match.secondTeam?.name}</p>
                    )}
                    {teamTwoWon > 0 && match.status === "Over" && (
                      <Check className="cursor-pointer" color="green" />
                      )}
                  </div>
                  <div className="flex justify-content-end align-items-center">
              {match.secondTeam?.scores?.length === 1 && (
                <>
                            <input
                              className="match-inputs"
                              type="text"
                              value={teamTwoScore}
                              onChange={(e) => setTeamTwoScore(e.target.value)}
                              readOnly={!permissions}
                            />
                </>
              )}
              {match.secondTeam?.scores?.length > 1 && (
                <>
                    <div className="flex justify-content-end align-items-center">
                  {match.secondTeam?.scores?.map((score, index) => {
                    return (
                      <>
                                {index !==
                                  match.secondTeam?.scores?.length - 1 && (
                                  <input
                                    type="text"
                                    className="match-inputs-2"
                                    value={teamTwoInitialScores[index]}
                                    onChange={(e) => {
                                      let array = teamTwoInitialScores.slice();
                                      array[index] = e.target.value;
                                      setTeamTwoInitialScores(array);
                                    }}
                                  />
                                )}
                      </>
                    );
                  })}
                  <input
                    className="match-inputs-2"
                    type="text"
                    value={teamTwoScore}
                    onChange={(e) => setTeamTwoScore(e.target.value)}
                    readOnly={!permissions}
                    />
                  </div>
                </>
              )}
            </div>
                </div>
              </div>
            </>
          )}
          <hr />
          <div className="flex justify-content-between pt-3">
            <Trash2 className='cursor-pointer' onClick={() => deleteMatch(roundNo, match.matchNo)} />
            <input
              className="match-inputs p-2 hidden md:inline-flex"
              type="text"
              placeholder={match?.status || "Not started yet"}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={{ width: "max-content", textAlign: "right" }}
              readOnly={!permissions}
            />
            <input
              className="match-inputs p-2 hidden md:inline-flex"
              style={{ width: "max-content", textAlign: "right" }}
              type="text"
              placeholder={match?.venue || "Venue to be declared"}
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              readOnly={!permissions}
            />
          </div>
        </div>
        <Dialog
          visible={openAddPlayersToRounds}
          onHide={() => setOpenAddPlayersToRounds(false)}
          style={{ width: "50vw" }}
        >
          <AddPlayersToRounds
            roundNum={roundNum}
            teamOne={match.firstTeam}
            teamTwo={match.secondTeam}
            teamOneRoundPlayers={teamOneRoundPlayers}
            setTeamOneRoundPlayers={setTeamOneRoundPlayers}
            teamTwoRoundPlayers={teamTwoRoundPlayers}
            setTeamTwoRoundPlayers={setTeamTwoRoundPlayers}
            selectedTournament={selectedTournament}
          />
        </Dialog>
      </>
    );
  };

  const Matches = () => {
    return (
      <>
        <div className="matches-grid mx-4 mt-1 mb-4">
          {!selectedGroup?.tournament_details && (
            <>
              {selectedGroup?.rounds?.map((round) => {
                return round.matches.map((match, index_) => {
                  if (match.firstTeam !== "bye") {
                    return (
                      <Match
                        match={match}
                        key={index_}
                        roundNo={round.roundNo}
                        type="roundRobin"
                      />
                    );
                  }
                });
              })}
            </>
          )}
          {selectedGroup?.current_round !== 0 && (
            <>
              {selectedGroup?.matchDetails?.map((match, index_) => {
                if (
                  !match.bye &&
                  match?.firstTeam !== "bye" &&
                  match?.secondTeam !== "bye" &&
                  match.firstTeam.TeamName !== "bye" &&
                  match?.secondTeam.TeamName !== "bye"
                ) {
                  return (
                    <Match
                      match={match}
                      key={index_}
                      roundNo={match.roundNo}
                      type="singleElimination"
                    />
                  );
                }
              })}
            </>
          )}
        </div>
      </>
    );
  };

  const app = new Realm.App({ id: "application-0-llvit" });

  useEffect(() => {
    const matchLogin = async () => {
      if (selectedGroup !== null) {
        await app.logIn(Realm.Credentials.anonymous());
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const matchCollection = mongodb
          .db("tournament_manager_database")
          .collection("Internal_Tournaments");

        for await (const change of matchCollection.watch()) {
          setSelectedGroup(change.fullDocument);
          setMatchEventOccured(!matchEventOccured);
        }
      }
    };

    matchLogin();
  }, [matchEventOccured, selectedGroup]);

  useEffect(() => {
    givePermissions(setPermissions);
  }, []);

  useEffect(() => {
    fetchAllInternalTournaments();
    setSelectedTournament(JSON.parse(sessionStorage.getItem("tournament")));
  }, [
    sessionStorage.getItem("tournament"),
    selectedGroup,
    matchEventOccured,
    ageGroupChanged,
  ]);

  return (
    <>
      <div className="container overflow-auto">
        {selectedTournament === null ? (
          <div style={{ color: "#757575" }} className="h-screen relative">
            <div
              style={{ marginTop: "25rem" }}
              className="flex justify-content-center align-items-center"
            >
              <p className="text-white">
                Please Select A Tournament OR Create a new one
              </p>
            </div>
          </div>
        ) : (
          <>
            <div
              className="flex justify-content-center align-items-center py-5 border-bottom-1 border-600"
              style={{ margin: "6rem", marginBottom: "3rem" }}
            >
              <div className="flex justify-content-center align-items-center gap-5">
              <p className="text-lg text-white">Matches</p>
              <div className="flex justify-content-center align-items-center gap-2 px-3 py-1 bg-primary border-round-sm cursor-pointer" onClick={fetchAllInternalTournaments}>
                      <p className="text-white">Refresh</p>
                      <RefreshIcon sx={{ color: "white" }} />
                    </div>
              <div className='flex justify-content-center align-items-center px-3 py-1 bg-primary border-round-sm cursor-pointer' onClick={handleOpenMatchTemplate}>
                <p className='text-white'>Add Template</p>
              </div>
            </div>
            </div>
            <div>
              <div className="flex justify-content-center align-items-center gap-4 mt-2">
                <Dropdown
                  panelStyle={{ fontFamily: "'Inter', sans-serif" }}
                  style={{ borderRadius: "4px" }}
                  className="m-4"
                  value={selectedGroup}
                  onChange={(e) => setSelectedGroup(e.target.value)}
                  options={internalTournaments.sort((group1, group2) => {
                    return (
                      parseInt(
                        group1.group_number[group1.group_number.length - 1]
                      ) -
                      parseInt(
                        group2.group_number[group2.group_number.length - 1]
                      )
                    );
                  })}
                  optionLabel="group_number"
                  placeholder="Select a Group"
                />
                {permissions &&
                  (selectedGroup?.tournament_details ||
                    selectedTournament?.format === "singleElimination") && (
                    <button
                      className="btn-knockout border-round-sm"
                      onClick={() => setSwitchView(!switchView)}
                    >
                      Switch View
                    </button>
                  )}
                <div>
                  {permissions && !selectedTournament?.knockouts && (
                    <button
                      className="btn-knockout border-round-sm"
                      onClick={startKnockoutPhase}
                    >
                      Start Knockout Phase
                    </button>
                  )}
                  {permissions && selectedGroup?.tournament_details && (
                    <div className="flex justify-content-center align-items-center gap-4">
                      {selectedGroup?.current_round !==
                        selectedGroup?.tournament_details.number_of_rounds && (
                        <button
                          className="btn-knockout border-round-sm"
                          onClick={scheduleNextMatches}
                        >
                          Schedule Next Matches
                        </button>
                      )}
                      <p className="current-round border-round-sm">
                        Round {selectedGroup?.current_round} of{" "}
                        {selectedGroup?.tournament_details.n_rounds}
                      </p>
                      {!selectedTournament.winner && (
                        <button
                          className="btn-knockout border-round-sm"
                          onClick={declareWinner}
                        >
                          Declare Winner
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {selectedGroup !== null && switchView === false && (
                <div className="flex justify-content-center align-items-start mb-8">
                  <Matches />
                </div>
              )}
              {selectedGroup !== null && switchView === true && (
                <KnockoutsView internalTournament={selectedGroup} tournament={selectedTournament} permissions={permissions} />
              )}
            </div>
            {/* Dialogs */}
            <Dialog
              visible={visible}
              onHide={() => setVisible(false)}
              style={{ width: "50vw" }}
            >
              <KnockoutPhase />
            </Dialog>
            <Dialog
              visible={matchInstructionModalOpen}
              onHide={() => setMatchInstructionModalOpen(false)}
              style={{ width: "50vw" }}
            >
              <MatchInstructionModal
                scheduleRoundMatches={scheduleRoundMatches}
                setMatchInstructionModalOpen={setMatchInstructionModalOpen}
                internalTournaments={internalTournaments}
              />
            </Dialog>
            <Dialog style={{ width: "50vw" }} visible={openMatchTemplate} onHide={handleCloseMatchTemplate}>
                <MatchTemplate tournament_id={selectedTournament?._id} group_number={selectedGroup?.group_number} toast={toast} />
            </Dialog>
          </>
        )}
        {/* <Footer /> */}
      </div>
    </>
  );
}
