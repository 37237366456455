import { useState,useRef } from "react";
import React from 'react'
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';



import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import axios from 'axios'
import { Toast } from 'primereact/toast';
const { REACT_APP_URL } = process.env;

const Form=({Data})=>
{
    const [tournament,setTournament]=useState({
        id:Data?._id,
        name:Data?.name,
        venue:Data?.venue,
        individual:Data?.individual,
        fromDate: new Date(Data.from),
        toDate:new Date(Data.to),
        winPoint:Data.winPoint,
        lossPoint:Data.lossPoint,
        city:Data.city
    });
 
    const options1 = ['Team', 'Individual'];
    const [Type, setType] = useState(options1[0]);
    const [loading, setLoading] = useState(false);     
    const toast = useRef(null);

    const showSuccess = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }

    const showError = (text) => {
        toast.current.show({severity:'error', summary: 'Error', detail:text, life: 3000});
    }

    const showWarn = (text) => {
        toast.current.show({severity:'warn', summary: 'Warning', detail:text, life: 3000});
    }


     const handleSubmit= async e=>{

            console.log(tournament);
            e.preventDefault()
      
        
            
                setTournament((prevTournament) => ({
                    ...prevTournament,
                    individual: Type === "Individual",
                    
                  
                  }));

                  setLoading(true);
                    let res=await axios.post(`${REACT_APP_URL}/tournaments/update`,tournament,{
                        headers: {
                            Authorization: sessionStorage.getItem("accessToken")
                        }
                    });

                    if(res.data==="Tournament Updated Successfully"){ showSuccess(res.data)}
                    else {showError(res.data)}
                    
                 setLoading(false)
           
            
        } 
    return (

        <div className="flex justify-content-center align-items-center h-full">
             <Toast ref={toast} />
                <form className='flex flex-column gap-5'>
                    <div className='flex gap-7'>
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-column gap-3 w-30rem">
                                <label>Tournament name</label>
                      
                                <InputText value={tournament.name} onChange={(e) => setTournament(prevState => ({ ...prevState, name: e.target.value }))} required />

                            </div>
                            <div className="flex">
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>Select Tournament Type</label>
                                    <SelectButton value={Type} onChange={(e) => setType(e.value)} options={options1} />
                                </div> 
                            </div>
                            <div className="flex flex-column gap-3 w-30rem">
                                <label>Tournament Venue</label>
                                <InputText value={tournament.venue} onChange={(e) => setTournament(prevState => ({ ...prevState, venue: e.target.value }))} required />
                            </div>
                            
                        </div> 
                        <div className="flex flex-column gap-4">
                            {/* <div className="flex flex-column gap-3 w-30rem">
                                    <label>Select Format</label>
                                    <SelectButton value={format}  onChange={(e) => setFormat(e.value)} options={options2} />
                            </div> */}
                            <div className="flex gap-5">
                                <div className="flex flex-column gap-3 w-15rem">
                                   <lable>Enter points for Win</lable> 
                                   <InputNumber value={tournament.winPoint} onValueChange={(e) => setTournament(prevState => ({ ...prevState, winPoint: e.value }))} min={0} max={100} />
                                </div>
                                <div className="flex flex-column gap-3 w-15rem">
                                    <lable>Enter points for Loss</lable> 
                                    <InputNumber value={tournament.lossPoint} onValueChange={(e) => setTournament(prevState => ({ ...prevState, lossPoint: e.value }))} min={0} max={100} />
                                </div>
                            </div>
                            <div className="flex gap-5">
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>From</label>
                                    <Calendar value={tournament.fromDate} onChange={(e) => setTournament(prevState => ({ ...prevState, fromDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />
                                  
                                </div>
                                <div className="flex flex-column gap-3 w-15rem">
                                    <label>To</label>
                                    <Calendar value={tournament.toDate} onChange={(e) => setTournament(prevState => ({ ...prevState, toDate: e.value }))} dateFormat="dd/mm/yy" showIcon required />
                                   
                                </div>
                            </div>
                            <div className="flex flex-column gap-3">
                                <label>City</label>
                                <InputText value={tournament.city} onChange={(e) => setTournament(prevState=>({...prevState,city:e.target.value}))} required />
                            </div>
                        </div>
                    </div>
                        {loading && (
                            <i className="pi pi-spin pi-spinner mt-5" style={{ fontSize: '2rem', color: "#FF8734" }}></i>
                        )}
                        {!loading && (
                            <Button label="Submit" onClick={handleSubmit}/>
                        )}
                </form>
        </div>
    
     )
    
}

function EditTournament({data}) {

  return (      
        <Form Data={data}/>
  )
}

export default EditTournament