import React from 'react'
import Header from './Header'
import Step from './steps/Step'
const ShowAnalytics = () => {
  return (
    <div className='flex flex-column mt-8'>
      <Header
        tab="Analytics"
      />
    </div>
  )
}

export default ShowAnalytics
