import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AutoCompleteComponent from './AutoCompleteComponent';
import { useLocation } from 'react-router-dom';
import Loader from './Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Search, X } from 'lucide-react';
const { REACT_APP_URL } = process.env;

const AddPlayerToTournament = ({ setOpenAddPlayersToTournament, update_players }) => {
    const [selectedPlayers, setSelectedPlayers] = useState(null);
    const [players, setPlayers] = useState([]);
    const [searchPlayers, setSearchPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searched, setSearched] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [player_added,setPLayer_added]=useState(false);
    const toast = useRef(null);
    const location=useLocation();

    const [tournament, setTournament] = useState(JSON.parse(sessionStorage.getItem("tournament")) || null);
    
    const showSuccess = (detail) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: detail,
          life: 3000,
        });
    };

    const showError = (detail) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: detail,
          life: 3000,
        });
    };

    const getAllPlayers = async () => {
        setLoading1(true);
        const response = await axios.get(`${REACT_APP_URL}/players/allPlayers`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
        
        const players_array = [];
        response.data.forEach((player) => {
            if(!player?.tournament_history?.includes(tournament._id)) {
                players_array.push(player);
            }
        })
        
        setPlayers(players_array);
        setSearchPlayers(players_array);
        setLoading1(false);
    }

    const searchThePlayers = () => {
        setSearched(true);
        let filterArray = [];
        filterArray = players.filter((player) => {
            return player.name.toLowerCase().includes(searchQuery.toLowerCase());
        })

        setSearchPlayers(filterArray);
    }

    const addPlayerToTournament = async () => {
        setLoading2(true);
        const response = await axios.post(`${REACT_APP_URL}/players/registerTournamentByName`, {
            tournament_id: tournament._id,
            players: selectedPlayers
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })
        
       
        if(response.data.message === "Players Added") {
           
            showSuccess(response.data.message);
        }
        else {
            showError(response.data.message);
        }
        setLoading2(false);
    }

    useEffect(() => {
        getAllPlayers();
        console.log(tournament?._id);
    },[])

  return (
    <>
        <div className="flex justify-content-center">
            <Toast ref={toast} />
        </div>
        <div className="flex justify-content-center align-items-center pb-3">
          {loading1 && (<Loader />)}
        </div>
        <div className="flex flex-column justify-content-center gap-4">
            {!loading1 && (
                <>
                <div className="flex justify-content-start align-items-center gap-4 w-max mb-4">
                    <input className="border-1 border-round-sm border-400 outline-none" style={{ padding: 10, fontFamily: "'Inter', sans-serif" }} type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}  />
                    {searched && (
                        <X className="cursor-pointer" onClick={() => {
                            setSearchQuery("");
                            setSearchPlayers(players);
                            setSearched(false)
                        }} />
                    )}
                    {!searched && (
                        <Search className="cursor-pointer" onClick={searchThePlayers} />
                    )}
                </div>
                <DataTable value={searchPlayers} style={{ width: "100%" }} selectionMode="checkbox" selection={selectedPlayers} onSelectionChange={(e) => {
                    setSelectedPlayers(e.value);
                }}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem', backgroundColor: "#FF8734" }}></Column>
                <Column field="name" header="Players" headerStyle={{ backgroundColor: "#FF8734", color: "#fff" }}></Column>
              </DataTable>
              </>
            )}
            {loading2 && (<Loader />)}
            {!loading2 && (
                <Button style={{ width: "100%" }} label="Add Players to Tournament" onClick={addPlayerToTournament}/>
            )}
        </div>
    </>
  )
}

export default AddPlayerToTournament
