import React from 'react'
import { useState } from 'react';
// import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import axios from 'axios'
import { showSuccess,showError,showWarning } from '../toasts';
const { REACT_APP_URL } = process.env;

const RemovePlayer = ({team,leader,toast}) => {
  const [selectedPlayers, setSelectedPlayers] = useState(null);
    const members=team.Members;
    const t_id=team._id;
    const Members= members.filter((member)=>
    {
     return  member.id !==leader.id;
    })
    const handleDelete= async()=>
    {
      try{  
        const response= await axios.post(`${REACT_APP_URL}/dashboard/removePlayers`,{selectedPlayers,t_id});
        if(response.data==='Member deleted successfully'){showSuccess(response.data,toast)}
        else showWarning(response.data,toast);
      }catch(err){
        showError("Axios error from frontend",toast);
      }
    
    }
  return (
    <div>
   
          <MultiSelect value={selectedPlayers} onChange={(e) => setSelectedPlayers(e.value)} options={Members} optionLabel="name" 
                placeholder="Select players" maxSelectedLabels={3} className="w-full md:w-20rem" />
          <Button label='submit' onClick={handleDelete}/>
          

    </div>
  )
}

export default RemovePlayer