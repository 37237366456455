import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import AutoCompleteComponent from '../AutoCompleteComponent';
const { REACT_APP_URL } = process.env;

const JoinTeam = ({name,leaderId,showSuccess,showError}) => {
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teams, setTeams] = useState([]);
    const [leaderOrNot, setLeaderOrNot] = useState(false);

    const getAllTeams = async () => {
        const response = await axios.get(`${REACT_APP_URL}/teams/allTeams`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
        let array = [];
        response.data.forEach((team) => {
            if(!team.TeamLeader.name) {
                team["name"] = team.TeamName;
                array.push(team);
            }
            if(team.TeamLeader.id === leaderId) {
                setLeaderOrNot(true);
            }
        })
        setTeams(array);
    }

    const joinTeam = async () => {
        const response = await axios.post(`${REACT_APP_URL}/teams/joinTeamByLeader`, {
            team_name: selectedTeam.TeamName,
            leader: {
                name: name,
                id: leaderId
            }
        })

        if(response.data.message === "Joined The Team") {
            showSuccess(response.data.message);
        }
        else if(response.data.message === "Please Try Again") {
            showError(response.data.message);
        }
    }

    useEffect(() => {
        getAllTeams();
    },[])

    return (
        <>
            <div className="flex flex-column justify-content-center align-items-center gap-4 w-full">
            {leaderOrNot && (
                <p className="text-lg font-semibold">You are already a leader in some team</p>
            )}
            {!leaderOrNot && (
                <>
                    <AutoCompleteComponent field="name" suggestions={teams} value={selectedTeam} handleOnChange={(e) => setSelectedTeam(e.target.value)} placeholder="Select a Team" width="100%" />
                    <Button
                    style={{ fontFamily: "'Inter', sans-serif", width: "100%" }}
                    label="Join Team"
                    onClick={joinTeam}
                    />
                </>
                )}
            </div>
        </>
    )
};

export default JoinTeam