import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import axios from 'axios';
const { REACT_APP_URL } = process.env;

const PDFLeagueKnockout = ({ internalTournament, tournament }) => {
    const [roundWiseMatches, setRoundWiseMatches] = useState([]);
    const [parentTournamentName, setParentTournamentName] = useState("");

    const getRoundWiseMatches = () => {
        let array = [];
        for(let i=0; i<internalTournament?.current_round; i++) {
            array.push({
                roundNo: i+1,
                matches: []
            })
        }

        internalTournament?.matchDetails?.forEach((match, index) => {
            array[match.roundNo - 1]?.matches?.push(match);
        });

        setRoundWiseMatches(array);
    }

    const getParentTournamentName = async () => {
        const response = await axios.get(`${REACT_APP_URL}/tournaments/parentTournamentNameForPdf/${tournament?.ParentTournament}`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });

        setParentTournamentName(response.data);
    }

    const RoundMatches = ({ matches }) => {
        return (
            <View style={{ justifyContent: "center", alignItems: "flex-start", gap: 70 }}>
                {matches.map((match, index) => {
                        return (
                            <>
                                <View style={{ justifyContent: "center", alignItems: "flex-start", borderWidth: 3, borderColor: "black", padding: 20 }}>
                                    <Text style={{ fontSize: 25 }}>{match.firstTeam.TeamName}</Text>
                                    <Text style={{ fontSize: 25 }}>{match.secondTeam.TeamName}</Text>
                                </View>
                            </>
                        )
                })}
            </View>
        )
    }

    useEffect(() => {
        getRoundWiseMatches();
        if(tournament?.isAgeGroup) {
            getParentTournamentName();
        }
    },[])

    return (
        <Document>
            <Page size="2A0">
                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", margin: 40, gap: 60 }}>
                    {tournament?.isAgeGroup && (
                        <Text style={{ fontSize: 25 }}>{parentTournamentName}</Text>
                    )}
                    <Text style={{ fontSize: 25 }}>{tournament?.name}</Text>
                </View>
                <View style={{ width: "100%", borderBottom: 1 }}></View>
                <View style={{ margin: 40, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 30, width: "100%" }}>
                    {roundWiseMatches.map((round) => {
                        return (
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} key={round.roundNo}>
                                <RoundMatches matches={round.matches} />
                            </View>
                        )
                    })}
                </View>
            </Page>
        </Document>
    )
}

export default PDFLeagueKnockout
