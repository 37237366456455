const showSuccess = (detail, toast) => {
    toast.current.show({
        severity: "success",
        summary: "Success",
        detail: detail,
        life: 3000,
    });
};

const showWarning = (detail, toast) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: detail,
      life: 3000,
    });
  };


const showError = (detail, toast) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: detail,
      life: 3000,
    });
};

export { showSuccess, showWarning, showError }