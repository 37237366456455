import axios from 'axios';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState} from 'react'
import { useLocation } from 'react-router-dom';
import AutoCompleteComponent from './AutoCompleteComponent';
import Loader from './Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Search, X } from 'lucide-react';
const { REACT_APP_URL } = process.env;



const AddTeamToTournament = () => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [searchTeams, setSearchTeams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searched, setSearched] = useState(false);
  const toast = useRef(null);
  const location=useLocation();

  const [tournament, setTournament] = useState(JSON.parse(sessionStorage.getItem("tournament")) || null);

  const showSuccess = (detail) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: detail,
          life: 3000,
        });
    };

    const showError = (detail) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: detail,
          life: 3000,
        });
    };

  const getAllTeams = async () => {
    setLoading1(true);
    const response = await axios.get(`${REACT_APP_URL}/teams/allTeams`, {
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
    })

    let array = [];
    response.data.forEach((team) => {
      if(!team.TournamentIDs.includes(tournament._id)) {
        team["name"] = team.TeamName;
        array.push(team);
      }
    })

    setTeams(array);
    setSearchTeams(array);
    setLoading1(false);
  }

  const searchTheTeams = () => {
    setSearched(true);
    let filterArray = [];
    filterArray = searchTeams.filter((player) => {
        return player.name.toLowerCase().includes(searchQuery.toLowerCase());
    })

    setSearchTeams(filterArray);
}
  
  const addTeamToTournament = async () => {
    setLoading2(true);
    const response = await axios.post(`${REACT_APP_URL}/teams/addTeamToTournament`, {
        teams: selectedTeams,
        tournament_id: tournament?._id
    }, {
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
    })

    if(response.data.message === "Team Added Successfully") {
        showSuccess(response.data.message);
    }
    else {
        showError(response.data.message);
    }
    setLoading2(false);
  }

  useEffect(() => {
    getAllTeams();
  },[])

  return (
    <>
        <div className="flex justify-content-center">
            <Toast ref={toast} />
        </div>
        <div className="flex justify-content-center align-items-center pb-3">
          {loading1 && (<Loader />)}
        </div>
        <div className="flex flex-column gap-4">
            {!loading1 && (
              <>
                       <div className="flex justify-content-start align-items-center gap-4 w-max mb-4">
                       <input className="border-1 border-round-sm border-400 outline-none" style={{ padding: 10, fontFamily: "'Inter', sans-serif" }} type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}  />
                       {searched && (
                           <X className="cursor-pointer" onClick={() => {
                               setSearchQuery("");
                               setSearchTeams(teams);
                               setSearched(false)
                           }} />
                       )}
                       {!searched && (
                           <Search className="cursor-pointer" onClick={searchTheTeams} />
                       )}
                   </div>
              <DataTable value={searchTeams} style={{ width: "100%" }} selectionMode="checkbox" selection={selectedTeams} onSelectionChange={(e) => setSelectedTeams(e.value)}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem', backgroundColor: "#FF8734" }}></Column>
                <Column field="TeamName" header="Teams" headerStyle={{ backgroundColor: "#FF8734", color: "#fff" }}></Column>
              </DataTable>
              </>
            )}
            {loading2 && (<Loader />)}
            {!loading2 && (
              <Button label="Add Teams to Tournament" onClick={addTeamToTournament}/>
            )}
        </div>
    </>
  )
}

export default AddTeamToTournament
