import { Checkbox } from '@mui/material';
import axios from 'axios';
import { Search, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { showError, showSuccess } from './toasts';
import { orange } from '@mui/material/colors';
import { Toast } from 'primereact/toast';
const { REACT_APP_URL } = process.env;

const AddPlayersToTeam = ({ tournament, team,  }) => {
    const [players, setPlayers] = useState([]);
    const [searchPlayers, setSearchPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searched, setSearched] = useState(false);
    const [arrayOfPlayers, setArrayOfPlayers] = useState([]);
    const toast = useRef(null);

    const getPlayers = async () => {
        const response = await axios.get(`${REACT_APP_URL}/players/getPlayers`, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        });
        setPlayers(response.data);
        setSearchPlayers(response.data);
    }

    const searchThePlayers = () => {
        setSearched(true);
        let filterArray = [];
        filterArray = players.filter((player) => {
            return player.name.toLowerCase().includes(searchQuery.toLowerCase());
        })

        setSearchPlayers(filterArray);
    }

    const Player = ({ player, index }) => {
        const [checked, setChecked] = useState(false);

        const handleOnChange = (e) => {
            setChecked(e.target.checked);
            if(player.selected) {
                player.selected = false;
            }
            else {
                player.selected = true;
            }
            players.forEach((player_) => {
                if(player_._id === player._id) {
                    player_.selected = player.selected;
                }
            })
        }

        return (
            <div key={index} className="flex justify-content-start align-items-center">
                <Checkbox sx={{
                    '&.Mui-checked': {
                        color: orange[800],
                      }
                }} checked={checked} onChange={handleOnChange} />
                <p>{player.name}</p>
            </div>
        )
    }

    const addPlayersToTeam = async () => {
        let array = [];
        searchPlayers.forEach((player) => {
            if(player.selected) {
                array.push(player);
            }
        })

        const response = await axios.post(`${REACT_APP_URL}/teams/addPlayersToTeam`, {
            tournament_id: tournament?._id,
            players: array,
            team_id: team?._id
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        })

        if(response.data.message === "Players Added") {
           
            showSuccess(response.data.message, toast);
        }
        else {
            showError(response.data.message, toast);
        }
    }

    useEffect(() => {
        getPlayers();
    },[])

  return (
    <>
        <Toast ref={toast} />
        <div className="flex flex-column justify-content-center gap-4">
            <button className="btn-knockout border-round-sm" onClick={addPlayersToTeam}>
                Add Players to Team
            </button>
            <div className="flex justify-content-start align-items-center gap-4 w-max">
            <input className="border-1 border-round-sm border-400 outline-none" style={{ padding: 10, fontFamily: "'Inter', sans-serif" }} type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}  />
                    {searched && (
                        <X className="cursor-pointer" onClick={() => {
                            setSearchQuery("");
                            setSearchPlayers(players);
                            setSearched(false)
                        }} />
                    )}
                    {!searched && (
                        <Search className="cursor-pointer" onClick={searchThePlayers} />
                    )}
            </div>
            <div className="flex flex-column justify-content-center align-items-start gap-5">
                {searchPlayers?.map((player, index) => {
                    return (
                        <Player player={player} index={index} />
                    )
                })}
            </div>
        </div>
    </>
  )
}

export default AddPlayersToTeam
