import { PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import PDFLeagueKnockout from './PDFLeagueKnockout'
import { useLocation } from 'react-router-dom'

const PDFLeagueViewer = () => {
    const location = useLocation();

    return (
      <PDFViewer width="100%" height={800}>
        <PDFLeagueKnockout internalTournament={location.state.internalTournament} tournament={location.state.tournament} />
      </PDFViewer>
    )
}

export default PDFLeagueViewer
