import "./App.css";
import "../node_modules/primeflex/primeflex.css";
import "../node_modules/primeflex/themes/primeone-light.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "./theme.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import Tournaments from "./components/Tournaments";
import ShowPlayer from "./components/ShowPlayer";
import ShowTeams from "./components/ShowTeams";
import ShowMatches from "./components/ShowMatches";
import ShowAnalytics from "./components/ShowAnalytics";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import { Toast } from 'primereact/toast';
import ForgotPassword from "./components/ForgotPassword";
import Header from "./components/Header";
import HowToDelete from "./components/HowToDelete";
import PDFLeagueViewer from "./components/PDFLeagueViewer";
import PDFRegularViewer from "./components/PDFRegularViewer";
const { REACT_APP_URL } = process.env;

function App() {
  const toast = useRef(null);
  const [eventOccured, setEventOccured] = useState(false);
  const [selectedtournament, setSelectedTournament] = useState({});
  const [visibleAddPlayer, setVisibleAddPlayer] = useState(false);
  const [visibleAddTeam, setVisibleAddTeam] = useState(false);
  const [Tournament_visible, setTournament_Visible] = useState(false);
  const [tournamentInstructionModalOpen, setTournamentInstructionModalOpen] = useState(false);
  const [playerInstructionModalOpen, setPlayerInstructionModalOpen] = useState(false);
  const [teamInstructionModalOpen, setTeamInstructionModalOpen] = useState(false);
  const [matchInstructionModalOpen, setMatchInstructionModalOpen] = useState(false);
  const [openAddPlayersToTournament, setOpenAddPlayersToTournament] =
    useState(false);
  const [openAddTeamsToTournament, setOpenAddTeamsToTournament] =
    useState(false);
  const [selectedGroup_, setSelectedGroup_] = useState(null);
  const [ageGroupChanged, setAgeGroupChanged] = useState(false);
  const [ageTournament, setAgeTournament] = useState({});
  const [openTabs,setOpenTabs]=useState(false);

  const AgeTabsOpen=()=>
  {
    setOpenTabs(true);
  }

  return (
    <>
    <Toast ref={toast} />
      <BrowserRouter>
        <Routes>
            <Route path="/how-to-delete-your-account" element={<HowToDelete />} />
            <Route path="/" element={<Login toast={toast} setSelectedTournament={setSelectedTournament}/>}>

              <Route path='ForgotPassword' element={<ForgotPassword toast={toast}/>}/>

            </Route>

            <Route path='/Register' element={<Register toast={toast}/>} />

            <Route path="/" element={
            <Header toast={toast} selectedTournament={selectedtournament} setSelectedTournament={setSelectedTournament}
             setVisibleAddPlayer={setVisibleAddPlayer} setVisibleAddTeam={setVisibleAddTeam} setTournament_Visible={setTournament_Visible}
            setTournamentInstructionModalOpen={setTournamentInstructionModalOpen} setPlayerInstructionModalOpen={setPlayerInstructionModalOpen} setTeamInstructionModalOpen={setTeamInstructionModalOpen} setMatchInstructionModalOpen={setMatchInstructionModalOpen} 
            openAddPlayersToTournament={openAddPlayersToTournament} setOpenAddPlayersToTournament={setOpenAddPlayersToTournament} 
            openAddTeamsToTournament={openAddTeamsToTournament} setOpenAddTeamsToTournament={setOpenAddTeamsToTournament} 
            ageGroupChanged={ageGroupChanged} setAgeGroupChanged={setAgeGroupChanged} ageTournament={ageTournament}
             setAgeTournament={setAgeTournament} openTabs={openTabs} />}>

            <Route path="showTournaments" element={<Tournaments AgeTabsOpen={AgeTabsOpen} toast={toast} Tournament_visible={Tournament_visible} setTournament_Visible={setTournament_Visible} tournamentInstructionModalOpen={tournamentInstructionModalOpen} setTournamentInstructionModalOpen={setTournamentInstructionModalOpen}/>} ageGroupChanged={ageGroupChanged} selectedTournament_={selectedtournament} ageTournament={ageTournament}  />

            <Route path="showPlayers" element={<ShowPlayer toast={toast} eventOccured={eventOccured} visibleAdd={visibleAddPlayer} setVisibleAddPlayer={setVisibleAddPlayer} playerInstructionModalOpen={playerInstructionModalOpen} setPlayerInstructionModalOpen={setPlayerInstructionModalOpen} setOpenAddPlayersToTournament={setOpenAddPlayersToTournament} selectedTournament_={selectedtournament} ageGroupChanged={ageGroupChanged} ageTournament={ageTournament} />} />

            <Route path="showTeams" element={<ShowTeams toast={toast} eventOccured={eventOccured} visibleAdd={visibleAddTeam} setVisibleAdd={setVisibleAddTeam} teamInstructionModalOpen={teamInstructionModalOpen} setTeamInstructionModalOpen={setTeamInstructionModalOpen} setOpenAddTeamsToTournament={setOpenAddTeamsToTournament} ageGroupChanged={ageGroupChanged} selectedTournament_={selectedtournament} ageTournament={ageTournament} />} />

            <Route path="showMatches" element={<ShowMatches toast={toast} matchInstructionModalOpen={matchInstructionModalOpen} setMatchInstructionModalOpen={setMatchInstructionModalOpen} selectedGroup_={selectedGroup_} setSelectedGroup_={setSelectedGroup_} ageGroupChanged={ageGroupChanged} />} />
            
            <Route path="showAnalytics" element={<ShowAnalytics toast={toast}/>} />

          </Route>
            <Route path="leaguePdf" element={<PDFLeagueViewer />} />
            <Route path="regularPdf" element={<PDFRegularViewer />} />
            <Route path="showDashboard" element={<Dashboard toast={toast}/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
