import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

const AutoCompleteComponent = ({ field, value, suggestions, handleOnChange, placeholder, width }) => {
    const [searchSuggestions, setSearchSuggestions] = useState(suggestions);

    const completeMethod = (e) => {
        let filtered_array = [];

        if(!e.query.trim().length) {
            filtered_array = [...suggestions]
        }
        else {
            filtered_array = suggestions?.filter((suggestion) => {
                return suggestion.name.toLowerCase().includes(e.query.toLowerCase());
            })
        }

        setSearchSuggestions(filtered_array);
    }

    return (
        <AutoComplete panelStyle={{ fontFamily: "'Inter', sans-serif" }} style={{ borderRadius: "5px", width: width }} field={field} value={value} suggestions={searchSuggestions} completeMethod={completeMethod} onChange={handleOnChange} placeholder={placeholder} dropdown />
    )
}

export default AutoCompleteComponent
